import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  recap: {
    padding: 24,
    border: `1px solid #E4E4E4`,
    borderRadius: 16,
    minWidth: 700,
    margin: 16,
    gap: 16,
    flex: 1,
  },
  recapTitle: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  priceDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  summaryRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rawFullPrice: {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#2762ea',
  },
  priceWithDiscount: {
    fontWeight: 'bold',
    backgroundColor: '#fdd34c',
  },
  commission: {
    fontWeight: 'bold',
    backgroundColor: '#d4a6bd',
  },
  educationalCost: {
    fontWeight: 'bold',
    backgroundColor: '#6366F1',
    color: 'white',
  },
}));
