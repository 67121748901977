import { ChangeEvent, FC, useState } from 'react';
import { Button } from 'react-admin';
import { getPriceFromCentimes } from '@les-sherpas/sherpas-toolbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import cancelAndRefundProrata from '../utils/cancel-and-refund-prorata';

type props = {
  subscriptionId: string;
  monthlyPriceWithDiscount: number;
  priceCoursesDone: number;
  maxToRefund: number;
  isModalOpened: boolean;
  handleCloseRefundModal: Function;
};

const numberFormat = new Intl.NumberFormat('fr-FR', {
  currency: 'EUR',
  style: 'currency',
});

const SubscriptionRefundModal: FC<props> = function refundModal({
  subscriptionId,
  monthlyPriceWithDiscount,
  maxToRefund,
  priceCoursesDone,
  isModalOpened,
  handleCloseRefundModal,
}) {
  const [errorState, setErrorState] = useState<string>('');

  const [totalRefundValue, setTotalRefundValue] = useState<number>(maxToRefund);

  const handleCancelAndRefund = async () => {
    const result = await cancelAndRefundProrata(
      subscriptionId,
      totalRefundValue
    );
    if (result === 'success') {
      handleCloseRefundModal();
    } else {
      setErrorState(result);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const parsedValue = parseInt(inputValue, 10);

    if (Number.isNaN(parsedValue) || parsedValue > maxToRefund) {
      setTotalRefundValue(maxToRefund);
    } else {
      setTotalRefundValue(parsedValue);
    }
  };

  return (
    <Dialog
      open={isModalOpened}
      onClose={() => handleCloseRefundModal()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Typography textAlign="center" variant="subtitle1">
          Abonnement N° <i>{subscriptionId}</i>
        </Typography>
      </DialogTitle>
      <DialogContent className="refundModalContent">
        <DialogContentText>
          Souhaites-tu annuler et remboursser cet abonnement ?
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table
              size="small"
              sx={{
                minWidth: 500,
                '& .MuiTableCell-root': {
                  borderLeft: '1px solid rgba(224, 224, 224, 1)',
                },
              }}
              aria-label="refound-subscription"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: '#fdd34c' }}
                  >
                    Prix payé par l&apos;étudiant
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: '#fdd34c' }}
                  >
                    Montant des cours effectués
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <Tooltip title="monthlyPriceWithDiscount">
                      <span>
                        {numberFormat.format(
                          getPriceFromCentimes(monthlyPriceWithDiscount)
                        )}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="priceCoursesDone">
                      <span>
                        {numberFormat.format(
                          getPriceFromCentimes(priceCoursesDone)
                        )}
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="numberInput-totalRefundValue"
              type="number"
              defaultValue={
                totalRefundValue > 0
                  ? getPriceFromCentimes(totalRefundValue)
                  : 0
              }
              onChange={handleChange}
              label="Montant à rembourser"
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
                inputProps: {
                  max: getPriceFromCentimes(maxToRefund),
                  min: 0,
                  step: 0.1,
                },
              }}
              margin="dense"
              fullWidth
            />
          </Grid>
        </Grid>
        <Typography color="error">{errorState}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleCloseRefundModal()}
          color="primary"
          label="Cancel"
        />
        <Button
          onClick={() => {
            handleCancelAndRefund();
          }}
          color="primary"
          label="Pay"
        />
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionRefundModal;
