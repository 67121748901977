import { Edit } from 'react-admin';

import CreateEditForm from './CreateEditForm/CreateEditForm';

function MarketingMessageEdit() {
  return (
    <Edit mutationMode="pessimistic">
      <CreateEditForm />
    </Edit>
  );
}

export default MarketingMessageEdit;
