import { MarketingMessageTarget } from '@les-sherpas/sherpas-toolbox';

const marketingMessageTargetTransco: {
  [_key in MarketingMessageTarget]: string;
} = {
  [MarketingMessageTarget.blank]: 'Nouvel onglet',
  [MarketingMessageTarget.self]: 'Même onglet',
};

export default marketingMessageTargetTransco;
