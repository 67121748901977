import { LocalOffer } from '@mui/icons-material';

import TagCreate from './tags-create';
import TagEdit from './tags-edit';
import TagList from './tags-list';

export default {
  list: TagList,
  edit: TagEdit,
  icon: LocalOffer,
  create: TagCreate,
};
