import { FC, PropsWithChildren } from 'react';

import useStyles from './styles';

type Props = {
  className?: string;
};

// eslint-disable-next-line react/function-component-definition
const Row: FC<PropsWithChildren<Props>> = ({ children, className }) => {
  const { classes, cx } = useStyles();

  return <div className={cx(classes.row, className)}>{children}</div>;
};

export default Row;
