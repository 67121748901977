import { FC } from 'react';
import { AdminStudent } from '@les-sherpas/sherpas-toolbox';
import { Avatar } from '@mui/material';

import useStyles from './styles';

type Props = {
  groupUsers: AdminStudent['update']['response']['groupUsers'];
  mainUser: AdminStudent['update']['response']['mainUser'];
};

const StudentGroupUsersList: FC<Props> = function studentGroupUsersList({
  groupUsers,
  mainUser,
}) {
  const { classes } = useStyles();
  return (
    <ul className={classes.container}>
      {mainUser && (
        <a
          href={`#/student/${mainUser.id}`}
          target="_blank"
          rel="noreferrer"
          className={classes.groupUsersItem}
        >
          {mainUser.photoUrl && (
            <Avatar
              variant="circular"
              src={mainUser.photoUrl}
              alt="Photo profile main user"
            />
          )}
          {mainUser.firstName} {mainUser.lastName}
        </a>
      )}
      {groupUsers?.map((user, index) => (
        <a
          href={`#/student/${user.id}`}
          target="_blank"
          rel="noreferrer"
          key={`group-users-item-${index}`}
          className={classes.groupUsersItem}
        >
          {user.photoUrl && (
            <Avatar
              variant="circular"
              src={user.photoUrl}
              alt={`Photo profile item-${index}`}
            />
          )}
          {user.firstName} {user.lastName}
        </a>
      ))}
    </ul>
  );
};

export default StudentGroupUsersList;
