import { AdminChat } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';

export const getConversations = async (
  id: string | undefined,
  page: number
): Promise<AdminChat['getChatRoomConversation']['response']> => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/chat/admin/get-chat-room-conversation/${id}?page=${page}`,
      {
        withCredentials: true,
        validateStatus: (status) => status === 200,
      }
    );
    return data;
  } catch (error) {
    return {
      chatRoomUsers: [],
      conversations: [],
      currentPage: page,
      hasNextPage: false,
    };
  }
};

export const isConversationCourse = (
  conversation: AdminChat['getChatRoomConversation']['response']['conversations'][0]
): conversation is AdminChat['getChatRoomConversation']['response']['conversations'][0] & {
  type: 'Course';
} => conversation.type === 'Course';

export const isConversationDocument = (
  conversation: AdminChat['getChatRoomConversation']['response']['conversations'][0]
): conversation is AdminChat['getChatRoomConversation']['response']['conversations'][0] & {
  type: 'Document';
} => conversation.type === 'Document';

export const isConversationMessage = (
  conversation: AdminChat['getChatRoomConversation']['response']['conversations'][0]
): conversation is AdminChat['getChatRoomConversation']['response']['conversations'][0] & {
  type: 'Message';
} => conversation.type === 'Message';

export const isConversationMessageDmr = (
  conversation: AdminChat['getChatRoomConversation']['response']['conversations'][0]
): conversation is AdminChat['getChatRoomConversation']['response']['conversations'][0] & {
  type: 'MessageDmr';
} => conversation.type === 'MessageDmr';
