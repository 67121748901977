import {
  BooleanInput,
  RadioButtonGroupInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { MarketingMessageTarget } from '@les-sherpas/sherpas-toolbox';

import marketingMessageTargetTransco from '../constants';
import ActionBar from './ActionBar/ActionBar';
import Row from './Row/Row';

import useStyles from './styles';

function CreateEditForm() {
  const { classes } = useStyles();

  return (
    <SimpleForm toolbar={<ActionBar />}>
      <Row className={classes.spaceBetween}>
        <TextInput source="campaign" isRequired label="Nom de campagne" />
        <BooleanInput source="isActive" isRequired label="Actif ?" />
      </Row>
      <Row>
        <TextInput source="emoji" isRequired label="Emoji" />
        <TextInput
          source="description"
          isRequired
          label="Description"
          className={classes.grow}
        />
      </Row>
      <Row>
        <TextInput source="actionLabel" isRequired label="Texte du bouton" />
        <TextInput
          source="redirectUrl"
          isRequired
          label="Lien de redirection"
          className={classes.grow}
        />
        <RadioButtonGroupInput
          source="target"
          choices={Object.keys(marketingMessageTargetTransco).map((key) => ({
            id: key,
            name: marketingMessageTargetTransco[key as MarketingMessageTarget],
          }))}
          label="Ouvrir dans:"
        />
      </Row>
    </SimpleForm>
  );
}

export default CreateEditForm;
