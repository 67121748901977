import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  productOptions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    gap: 32,
  },
  productTabs: {
    flex: 2,
    width: '100%',
  },
  product: {
    width: '50%',
  },
  recapTitle: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
}));
