import React from 'react';
import { ChipField, Show, SimpleShowLayout, useShowContext } from 'react-admin';
import { IDiplomaAdmin } from '@les-sherpas/sherpas-toolbox';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  identityShow: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  diplomaDocument: {
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',
  },
}));

function DiplomaDocument() {
  const { record } = useShowContext<IDiplomaAdmin>();
  const { classes } = useStyles();
  return (
    <div className={classes.diplomaDocument}>
      {!!record &&
        record?.documents.map(({ url, originalName }, i) => (
          <div key={i}>
            <a href={url} title="Presentation">
              {originalName}
            </a>
          </div>
        ))}
    </div>
  );
}

function DiplomaShow() {
  const { classes } = useStyles();

  return (
    <Show>
      <SimpleShowLayout>
        <div className={classes.identityShow}>
          id:
          <ChipField source="id" />
          Diplôme de:
          <ChipField source="diplomaName" />
          Institution:
          <ChipField source="institutionName" />
        </div>
        <DiplomaDocument />
      </SimpleShowLayout>
    </Show>
  );
}

export default DiplomaShow;
