import React, { useMemo } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { SubscriptionOptionsFormProps } from '../utils';

import useStyles from './styles';

function SubscriptionProposalSubjectsAndLevels({
  setSubject,
  subject,
  announce,
  setLevel,
  level,
}: Pick<
  SubscriptionOptionsFormProps,
  'setSubject' | 'subject' | 'level' | 'setLevel' | 'announce'
>) {
  const { classes } = useStyles();

  const levels = useMemo(() => announce?.levels ?? [], [announce]);
  const subjects = useMemo(() => announce?.subjects ?? [], [announce]);

  const handleLevelChange = (e: SelectChangeEvent<string>) => {
    const selectedLevel = levels.find((l) => l.name === e.target.value);
    if (selectedLevel) {
      setLevel(selectedLevel);
    }
  };

  const handleSubjectChange = (e: SelectChangeEvent<string>) => {
    const selectedSubject = subjects.find((s) => s.name === e.target.value);
    if (selectedSubject) {
      setSubject(selectedSubject);
    }
  };

  return (
    <Grid item xs={12}>
      <div className={classes.levelSubjectContainer}>
        <FormControl variant="outlined" className={classes.form}>
          <InputLabel shrink id="select-subject-label">
            Choisir la matière:
          </InputLabel>
          <Select
            labelId="select-subject-label"
            id="select-subject"
            label="Matière"
            value={subject?.name ?? ''}
            onChange={handleSubjectChange}
          >
            {subjects.map(({ name, id }) => (
              <MenuItem key={`subject_${id}`} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" className={classes.form}>
          <InputLabel shrink id="select-level-label">
            Choisir le level:
          </InputLabel>
          <Select
            labelId="select-level-label"
            label="Level"
            id="select-level"
            value={level?.name ?? ''}
            onChange={handleLevelChange}
          >
            {levels.map(({ name, id }) => (
              <MenuItem key={`level_${id}`} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Grid>
  );
}

export default SubscriptionProposalSubjectsAndLevels;
