import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: '16px',
  },
  radioTitle: {
    display: 'flex',
    flexFlow: 'column',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '16px',
  },
  radioContainer: {
    width: '100%',
    marginLeft: '16px',
    padding: '16px',
    border: '1px solid #ccc',
    borderRadius: '8px',
  },
  chip: {
    marginTop: '8px',
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
  },
  fullWidth: {
    width: '100%',
  },
  levelSubjectContainer: {
    marginTop: '16px',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  btn: {
    marginTop: '32px',
    display: 'flex',
    gap: '16px',
    alignItems: 'left',
    justifyContent: 'space-between	',
    width: '100%',
  },
  form: {
    flex: 1,
  },
  swap: {
    marginTop: '16px',
    padding: '8px',
    display: 'flex',
    width: '100%',
  },
  selectorContainers: {
    display: 'flex',
    flexDirection: 'column',
    padding: 32,
    border: `1px solid #E4E4E4`,
    borderRadius: 16,
    maxWidth: 1250,
    margin: 16,
    gap: 16,
    flex: 2,
  },
  panelContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    justifyContent: 'space-around',
  },
  recapTitle: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  toggleGroup: {
    width: '100%',
  },
  oneRow: {
    marginTop: '16px',
  },
  customNumberInput: {
    marginLeft: '16px',
    width: '100px',
  },
}));
