import {
  ResourceIconType,
  ResourceMediaType,
} from '@les-sherpas/sherpas-toolbox';

export const RESOURCES_MEDIA_ANIMATION: {
  id: ResourceMediaType;
  name: string;
}[] = [
  { id: 'video', name: 'video' },
  { id: 'article', name: 'article' },
];

export const RESOURCES_ICONS_LIST: {
  id: ResourceIconType;
  name: string;
}[] = [
  {
    id: 'bookOpen',
    name: 'Article',
  },
  {
    id: 'database',
    name: 'Base de données',
  },
  {
    id: 'level',
    name: 'Bibliothèque',
  },
  {
    id: 'folder',
    name: 'Dossier',
  },
  {
    id: 'star',
    name: 'Étoile',
  },
  {
    id: 'movie',
    name: 'Film',
  },
  {
    id: 'newspaper',
    name: 'Journal',
  },
  {
    id: 'instagram',
    name: 'Instagram',
  },
  {
    id: 'link',
    name: 'Lien',
  },
  {
    id: 'spotify',
    name: 'Spotify',
  },
  {
    id: 'globe',
    name: 'Web',
  },
  {
    id: 'youtube',
    name: 'Youtube',
  },
  {
    id: 'sun',
    name: 'Soleil',
  },
];
