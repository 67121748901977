import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  row: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
  },
}));

export default useStyles;
