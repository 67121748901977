import { Dispatch, SetStateAction } from 'react';
import BoltIcon from '@mui/icons-material/Bolt';
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import { Tab, Tabs, Typography } from '@mui/material';

import SUBSCRIPTIONS_PRODUCTS, { SubscriptionProduct } from './utils';

import useStyles from './styles';

function ProductsTabs({
  handleChange,
  product,
}: {
  product: SubscriptionProduct;
  handleChange: Dispatch<SetStateAction<SubscriptionProduct>>;
}) {
  const { classes } = useStyles();

  return (
    <div className={classes.productOptions}>
      <Typography variant="h6" className={classes.recapTitle}>
        Type de Formule
      </Typography>
      <Tabs
        value={product.id}
        onChange={(_, index: number) =>
          handleChange(SUBSCRIPTIONS_PRODUCTS[index])
        }
        className={classes.productTabs}
      >
        {[
          <FilterHdrIcon
            key="icon-product-1"
            fontSize="large"
            htmlColor="#1a315a"
          />,
          <BoltIcon
            key="icon-product-1"
            fontSize="large"
            htmlColor="#f6b11d"
          />,
        ].map((icon, index) => {
          const { id, label } = SUBSCRIPTIONS_PRODUCTS[index];
          return (
            <Tab
              key={`product-type-${id}`}
              icon={icon}
              label={label}
              value={id}
              className={classes.product}
            />
          );
        })}
      </Tabs>
    </div>
  );
}

export default ProductsTabs;
