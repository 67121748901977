import {
  getPriceFromCentimes,
  ISubscriptionViewAdmin,
  minutesToHours,
} from '@les-sherpas/sherpas-toolbox';

// eslint-disable-next-line no-restricted-imports
import csvExporter from '../../../utils/csvExporter';

export const subscriptionsCsvExporter = (data: ISubscriptionViewAdmin[]) => {
  const formattedData = data.map((record: ISubscriptionViewAdmin) => ({
    ...record,
    volume: minutesToHours(record.volume),
    hourlyRawPrice: getPriceFromCentimes(record.hourlyRawPrice),
    hourlyRawTravelFee: getPriceFromCentimes(record.hourlyRawTravelFee),
    hourlyPriceWithManagementCost: getPriceFromCentimes(
      record.hourlyPriceWithManagementCost
    ),
    hourlyRawFullPrice: getPriceFromCentimes(record.hourlyRawFullPrice),
    hourlyPriceWithDiscount: getPriceFromCentimes(
      record.hourlyPriceWithDiscount
    ),
    monthlyPriceWithDiscount: getPriceFromCentimes(
      record.monthlyPriceWithDiscount
    ),
    coursesVolumePriceWithDiscount: getPriceFromCentimes(
      record.coursesVolumePriceWithDiscount
    ),
    maxToRefund: getPriceFromCentimes(record.maxToRefund),
  }));

  csvExporter<ISubscriptionViewAdmin>({
    data: formattedData,
    fileName: 'subscriptions',
  });
};

export default subscriptionsCsvExporter;
