import { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';

type props = {
  title: string;
  className: string;
  tooltipContent: string | number;
};

const SummaryTableCell: FC<props> = function renderSummaryTableRow({
  title,
  className,
  tooltipContent,
}) {
  return (
    <TableCell className={className} align="center">
      <Tooltip title={title}>
        <span>{tooltipContent}</span>
      </Tooltip>
    </TableCell>
  );
};

export default SummaryTableCell;
