import TVIcon from '@mui/icons-material/Tv';

import TVCampaignCreate from './tvCampaignCreate';
import TVCampaignEdit from './tvCampaignEdit';
import TVCampaignList from './tvCampaignList';

export default {
  list: TVCampaignList,
  edit: TVCampaignEdit,
  create: TVCampaignCreate,
  icon: TVIcon,
  options: { label: 'Campagne TV' },
};
