import { FC } from 'react';
import { Resource } from '@les-sherpas/sherpas-toolbox';

import GenericResourcesContent from '../common/generic-resources-content/generic-resources-content';

type Props = {
  title: string;
  data: Resource[];
};

const PromoResourceBanner: FC<Props> = function promoResourceBanner({
  data,
  title,
}) {
  return (
    <GenericResourcesContent
      data={data}
      title={title}
      fields={[
        {
          type: 'number',
          source: 'id',
          label: 'Ordre',
        },
        {
          type: 'text',
          source: 'title',
          label: 'Titre',
        },
        {
          type: 'image',
          source: 'imageUrl',
          label: "Image d'illustration",
        },
        {
          type: 'boolean',
          source: 'isActive',
          label: 'Actif',
        },
      ]}
    />
  );
};

export default PromoResourceBanner;
