import {
  DatagridConfigurable,
  DateField,
  FunctionField,
  List,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';
import {
  formatCurrency,
  getPriceFromCentimes,
  SponsoringApi,
} from '@les-sherpas/sherpas-toolbox';

import useGlobalStyles from '../global-styles';
import ListActions from '../ListActions';

function SponsoringList() {
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
      filterDefaultValues={{ isActive: true }}
      pagination={
        <Pagination
          sx={{ display: 'flex' }}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      }
      filters={[
        <TextInput
          label="Search"
          source="q"
          alwaysOn
          key="search"
          className={globalClasses.search}
        />,
      ]}
    >
      <DatagridConfigurable>
        <TextField source="id" />
        <TextField source="sponsorUserId" />
        <TextField source="sponsorFirstName" />
        <TextField source="sponsorLastName" />
        <TextField source="sponsoredUserId" />
        <TextField source="sponsoredFirstName" />
        <TextField source="sponsoredLastName" />
        <TextField source="chatRoomId" />
        <TextField source="chatRoomSubscriptionId" />
        <FunctionField
          source="cumulatedOverallCommission"
          render={(
            record: SponsoringApi['admin']['getList']['response']['data'][number]
          ) =>
            formatCurrency({
              amount: getPriceFromCentimes(record.cumulatedOverallCommission),
              showSymbol: true,
            })
          }
        />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </DatagridConfigurable>
    </List>
  );
}

export default SponsoringList;
