import { useEffect, useState } from 'react';
import {
  AnnounceAdminFromTeacher,
  IdName,
  IntensiveTrainingDuration,
  IntensiveTrainingVolume,
  LocationOfCourse,
  locationOfCourse,
  productConfig,
  ProductType,
  RecurringTrainingDuration,
  RecurringTrainingVolume,
} from '@les-sherpas/sherpas-toolbox';

import SUBSCRIPTIONS_PRODUCTS, {
  SubscriptionProduct,
} from '../generate-subscription-sales/Products/utils';

const useSubscriptionOptionsForm = ({
  announces,
}: {
  announces: AnnounceAdminFromTeacher[];
}) => {
  // Product config default values
  const [product, setProduct] = useState<SubscriptionProduct>(
    SUBSCRIPTIONS_PRODUCTS[0]
  );
  const [selectedProductConfig, setSelectedProductConfig] = useState(
    product.productType === ProductType.RecurringTraining
      ? productConfig.sre
      : productConfig.sie
  );

  const [isFixDuration, setIsFixDuration] = useState<boolean>(
    product.productType === ProductType.IntensiveTraining
  );

  useEffect(() => {
    setSelectedProductConfig(
      product.productType === ProductType.RecurringTraining
        ? productConfig.sre
        : productConfig.sie
    );
  }, [product]);

  const [expirationDuration, setExpirationDuration] = useState<number>(
    selectedProductConfig.proposalExpirationDuration.default
  );

  const [commissionRate, setCommissionRate] = useState<number>(
    selectedProductConfig.commissionRate
  );
  const [managementCostRate, setManagementCostRate] = useState<number>(
    selectedProductConfig.managementCostRate
  );
  const [educationalCostRate, setEducationalCostRate] = useState<number>(
    selectedProductConfig.educationalCost.rate
  );

  const [calculatedEducationalCostRate, setCalculatedEducationalCostRate] =
    useState<number>(selectedProductConfig.educationalCost.rate);

  const [location, setLocation] = useState<LocationOfCourse>(
    locationOfCourse.online
  );

  const [volume, setVolume] = useState<number>(
    RecurringTrainingVolume.TwelveHours
  );
  const [duration, setDuration] = useState<number>(
    RecurringTrainingDuration.ThreeMonths
  );

  useEffect(() => {
    if (product.productType === ProductType.IntensiveTraining) {
      setVolume(IntensiveTrainingVolume.FifteenHours);
      setDuration(IntensiveTrainingDuration.OneMonth);
      setManagementCostRate(selectedProductConfig.managementCostRate);
      setIsFixDuration(true);
      return;
    }
    setVolume(RecurringTrainingVolume.TwelveHours);
    setDuration(RecurringTrainingDuration.ThreeMonths);
    setManagementCostRate(selectedProductConfig.managementCostRate);
    setIsFixDuration(false);
  }, [selectedProductConfig]);

  // Announce Config Default values

  const [announce, setAnnounce] = useState<AnnounceAdminFromTeacher>();
  const [level, setLevel] = useState<IdName>();
  const [subject, setSubject] = useState<IdName>();
  const [hourlyRawPrice, setHourlyRawPrice] = useState<number>(0);
  const [hourlyRawTravelFee, setHourlyRawTravelFee] = useState<number>(0);

  useEffect(() => {
    if (announces && announces.length > 0) {
      setAnnounce(announces[0]);
    }
  }, [announces]);

  useEffect(() => {
    if (announce) {
      setLevel(announce.levels.at(0));
      setSubject(announce.subjects.at(0));
      setHourlyRawPrice(announce.tarification.hourlyRawPrice);
      setHourlyRawTravelFee(announce.tarification.hourlyRawTravelFee);
    }
  }, [announce]);

  return {
    announce,
    setAnnounce,
    level,
    setLevel,
    subject,
    setSubject,
    hourlyRawPrice,
    setHourlyRawPrice,
    hourlyRawTravelFee,
    setHourlyRawTravelFee,
    location,
    setLocation,
    volume,
    setVolume,
    duration,
    setDuration,
    product,
    setProduct,
    commissionRate,
    setCommissionRate,
    managementCostRate,
    setManagementCostRate,
    educationalCostRate,
    setEducationalCostRate,
    calculatedEducationalCostRate,
    setCalculatedEducationalCostRate,
    isFixDuration,
    setIsFixDuration,
    productType: product.productType,
    expirationDuration,
    setExpirationDuration,
    selectedProductConfig,
  };
};

export default useSubscriptionOptionsForm;
