import { useState } from 'react';
import { Link } from 'react-admin';
import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { MAX_DISPLAY_NODE } from './SubjectAnnounceLink';

const useStyles = makeStyles()(() => ({
  chip: {
    cursor: 'pointer',
    margin: '2px',
    '&:hover': {
      backgroundColor: 'lightGrey',
    },
  },
  button: {
    border: 0,
    textDecoration: 'underline',
    color: '#2196f3',
    padding: 0,
    backgroundColor: 'transparent',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function LevelChipGroup(props: any) {
  const { classes } = useStyles();
  const [hide, setHide] = useState(false);
  const {
    record: { levels },
  } = props;

  const displayedLevels = hide ? levels : levels.slice(0, MAX_DISPLAY_NODE);

  return (
    <>
      {displayedLevels.map((level: { name: string }, i: number) => (
        <Link key={i} to={{ pathname: '/' }}>
          <Chip label={level.name} className={classes.chip} />
        </Link>
      ))}
      {levels.length > MAX_DISPLAY_NODE && (
        <button
          type="button"
          onClick={() => setHide((prev) => !prev)}
          className={classes.button}
        >
          {!hide ? 'Voir plus' : 'Cacher'}
        </button>
      )}
    </>
  );
}

export default LevelChipGroup;
