import { useRecordContext } from 'react-admin';
import type { SubscriptionPaymentPauseReason } from '@les-sherpas/sherpas-toolbox';

type Props = {
  source: string;
};

function SubscriptionPaymentPauseReasonLabel({ source }: Props) {
  const record = useRecordContext();
  const reason = record[source] as SubscriptionPaymentPauseReason;

  switch (reason) {
    case 'holiday':
      return <span>Mode vacance actif</span>;
    case 'none':
      return <span>Non</span>;
    case 'swap-subscription':
      return <span>En cours de Swap</span>;
    case 'cancellation-period':
      return <span>Demande de résiliation en cours</span>;
    case 'from-stripe':
      return <span>Pause Stripe</span>;
    default:
      return null;
  }
}

export default SubscriptionPaymentPauseReasonLabel;
