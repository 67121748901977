import React, { ChangeEvent } from 'react';
import { productConfig } from '@les-sherpas/sherpas-toolbox';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import hoursToMinutes from 'date-fns/hoursToMinutes';
import minutesToHours from 'date-fns/minutesToHours';

import { SubscriptionOptionsFormProps } from '../utils';

function SubscriptionProposalExpirationDuration({
  setExpirationDuration,
}: Pick<SubscriptionOptionsFormProps, 'setExpirationDuration'>) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const parsedValue = parseInt(inputValue, 10);

    if (
      Number.isNaN(parsedValue) ||
      parsedValue > productConfig.sre.proposalExpirationDuration.max
    ) {
      setExpirationDuration(
        minutesToHours(productConfig.sre.proposalExpirationDuration.default)
      );
    } else {
      setExpirationDuration(hoursToMinutes(parsedValue));
    }
  };

  return (
    <Grid item xs={12}>
      <TextField
        id="numberInput-expirationDuration"
        type="number"
        onChange={handleChange}
        defaultValue={minutesToHours(
          productConfig.sre.proposalExpirationDuration.default
        )}
        label="⌛ Délais d'expiration"
        size="small"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">Heures</InputAdornment>
          ),
          inputProps: {
            max: minutesToHours(
              productConfig.sre.proposalExpirationDuration.max
            ),
            min: minutesToHours(
              productConfig.sre.proposalExpirationDuration.min
            ),
          },
        }}
        fullWidth
      />
    </Grid>
  );
}

export default SubscriptionProposalExpirationDuration;
