import { FC } from 'react';
import { ChatRoomApi } from '@les-sherpas/sherpas-toolbox';
import TableCell from '@mui/material/TableCell';

type Props = {
  subscription: ChatRoomApi['admin']['getChatRoomForProposal']['response']['subscriptionProposals'][number]['subscription'];
  isActive: boolean;
};

const HasPurchasedCell: FC<Props> = function hasPurchasedCell({
  subscription,
  isActive,
}) {
  const getPurchaseStatusEmoji = ({ isActive }: { isActive: boolean }) => {
    if (isActive) {
      return '⏳';
    }

    return '❌';
  };

  return (
    <TableCell>
      {subscription.hasPurchased ? (
        <a
          href={`#/subscription/${subscription.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {subscription.id}
          <span>✔️</span>
        </a>
      ) : (
        getPurchaseStatusEmoji({
          isActive,
        })
      )}
    </TableCell>
  );
};

export default HasPurchasedCell;
