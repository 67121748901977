import React from 'react';
import {
  BooleanField,
  Datagrid,
  ExportButton,
  List,
  NumberField,
  TextField,
} from 'react-admin';
import { SubjectWithAnnounceInfos } from '@les-sherpas/sherpas-toolbox';

import csvExporter from '../utils/csvExporter';

const exporter = (data: SubjectWithAnnounceInfos[]) => {
  csvExporter<SubjectWithAnnounceInfos>({ data, fileName: 'subjects' });
};

function SubjectList() {
  return (
    <List actions={<ExportButton maxResults={10000} />} exporter={exporter}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="code" label="Code" />
        <TextField source="name" label="Nom" />
        <BooleanField source="isMain" label="is Main" />
        <NumberField source="scoreSEO" label="Score SEO" />
        <TextField source="tag" label="Tag" />
        <NumberField source="priority" label="Priorité" />
      </Datagrid>
    </List>
  );
}

export default SubjectList;
