import { Button, SaveButton, Toolbar, ToolbarProps } from 'react-admin';

import useStyles from '../styles';

function ActionBar(props: ToolbarProps) {
  const { classes } = useStyles();

  return (
    <Toolbar {...props} className={classes.spaceBetween}>
      <Button label="Annuler" onClick={() => window.history.back()} />
      <SaveButton label="Enregistrer" alwaysEnable />
    </Toolbar>
  );
}

export default ActionBar;
