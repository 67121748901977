import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  spaceBetween: {
    justifyContent: 'space-between',
  },
  grow: {
    flexGrow: 1,
  },
}));

export default useStyles;
