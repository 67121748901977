import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  toggleGroup: {
    width: '100%',
  },
  optionLocation: {
    flexGrow: 1,
  },
}));
