import React, { useState } from 'react';
import {
  BooleanInput,
  Button,
  ChipField,
  DatagridConfigurable,
  FunctionField,
  List,
  Pagination,
  RaRecord,
  SelectInput,
  TextField,
  TextInput,
  useListContext,
  useRefresh,
} from 'react-admin';
import { formatInParis, PayoutApi } from '@les-sherpas/sherpas-toolbox';
import { format, minutesToHours, subMonths } from 'date-fns';

import CalculetteField from '../commun/CalculetteField/CalculetteField';
import useGlobalStyles, { calculleteColors } from '../global-styles';
import ListActions from '../ListActions';
import formatLongString from '../utils/functions';
import DisputeModal from './payoutModals/disputeModal';
import MoneyTransferModal from './payoutModals/moneyTransferModal';
import payoutSherpasDomTeacher from './payout-sherpas-dom.utils';

function PayoutButton({
  record,
  payoutModal,
}: {
  record: PayoutApi['admin']['getPayoutListSherpasDom']['response'][number];
  payoutModal: Function;
}) {
  const { classes: globalClasses } = useGlobalStyles();
  const { filterValues } = useListContext();
  const { date } = filterValues;
  if (date) {
    return (
      <Button
        label="Virer"
        onClick={() => payoutModal(record, date)}
        disabled={!record.stripeAccountId || record.hasBeenPaid}
        variant="contained"
        className={globalClasses.btnGreen}
      />
    );
  }
  return <div />;
}
function StripeAccountLink({ record }: { record: RaRecord }) {
  return (
    <a
      href={`${process.env.REACT_APP_STRIPE_BASE_URL}/connect/accounts/${record.stripeAccountId}/activity`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {record.stripeAccountId}
    </a>
  );
}
function PayoutSherpasDomList() {
  const { classes: globalClasses } = useGlobalStyles();
  const today = new Date();
  const refresh = useRefresh();

  const choices = Array(6)
    .fill(0)
    .map((_, i) => ({
      id: i,
      value: `${format(subMonths(today, i), 'yyyy-MM')}`,
      fullName: formatInParis(subMonths(today, i), 'LLLL'),
    }));

  const [openPayoutModal, setOpenPayoutModal] = useState(false);
  const [baseValue, setBaseValue] = useState<number>(0);
  const [periodMonth, setPeriodMonth] = useState<string>('');
  const [subscriptionId, setSubscriptionId] = useState<number>(0);
  const [errorState, setErrorState] = useState<string>('');
  const [openDisputeModal, setOpenDisputeModal] = useState(false);
  const [payoutDisputeReason, setPayoutDisputeReason] = useState('');

  const [currentTeacherId, setCurrentTeacherId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const payoutModal = (
    {
      totalCourseVolumeRawFullPrice,
      teacherId,
      id,
    }: PayoutApi['admin']['getPayoutListSherpasDom']['response'][number],
    periodMonth: string
  ) => {
    setBaseValue(totalCourseVolumeRawFullPrice);
    setCurrentTeacherId(teacherId);
    setSubscriptionId(id);
    setPeriodMonth(new Date(periodMonth).toString());
    setOpenPayoutModal(true);
  };

  const handleClosePayoutModal = () => {
    setBaseValue(0);
    setCurrentTeacherId(0);
    setSubscriptionId(0);
    setOpenPayoutModal(false);
    refresh();
    setErrorState('');
  };

  const handlePayTeacher = async () => {
    setIsLoading(true);
    const result = await payoutSherpasDomTeacher({
      teacherId: currentTeacherId,
      amountToPay: baseValue,
      subscriptionId,
      periodMonth: new Date(periodMonth),
    });
    if (result === 'success') {
      handleClosePayoutModal();
    } else {
      setErrorState(result);
    }
    setIsLoading(false);
  };
  const handleOpenDisputeModal = (payoutDisputeReason: string) => {
    setPayoutDisputeReason(payoutDisputeReason);
    setOpenDisputeModal(true);
  };

  const handleCloseDisputeModal = () => {
    setOpenDisputeModal(false);
  };

  return (
    <>
      <MoneyTransferModal
        baseValue={baseValue}
        handleClosePayoutModal={handleClosePayoutModal}
        errorState={errorState}
        isOpen={openPayoutModal}
        setBaseValue={setBaseValue}
        handlePayTeacher={handlePayTeacher}
        isLoading={isLoading}
      />
      <DisputeModal
        isOpen={openDisputeModal}
        handleClose={handleCloseDisputeModal}
        message={payoutDisputeReason}
      />
      <List
        actions={<ListActions />}
        filters={[
          <TextInput
            label="Search"
            source="q"
            alwaysOn
            key="search"
            className={globalClasses.search}
          />,
          <SelectInput
            label="mois"
            source="date"
            key="date"
            choices={choices}
            optionText="value"
            optionValue="value"
            alwaysOn
            emptyText="Choisir un mois"
            emptyValue="Choisir un mois"
          />,
          <BooleanInput
            source="isPaid"
            key="paid"
            label="a été payé"
            defaultValue={false}
          />,
          <BooleanInput
            source="hasError"
            key="hasError"
            label="A au moins une erreur"
            defaultValue={false}
          />,
        ]}
        filterDefaultValues={{
          date: format(today, 'yyyy-MM'),
        }}
        pagination={<Pagination sx={{ display: 'flex' }} />}
      >
        <DatagridConfigurable
          hover
          sx={{
            '& .column-hourlyRawFullPrice, & .column-totalCourseVolumeRawFullPrice':
              {
                textAlign: 'center',
              },
            '& .RaDatagrid-headerCell.column-hourlyRawFullPrice , .RaDatagrid-headerCell.column-totalCourseVolumeRawFullPrice ':
              {
                ...calculleteColors.rawFullPrice,
                whiteSpace: 'nowrap',
                color: 'white',
              },
          }}
        >
          <TextField source="id" label="subscriptionId" />
          <TextField source="chatRoomId" label="chatRoomId" />
          <TextField source="teacherId" label="teacherId" sortable={false} />
          <TextField
            source="teacher"
            label="nom du professeur"
            sortable={false}
          />
          <FunctionField
            label="stripeAccountId"
            sortable={false}
            render={(record: RaRecord) => <StripeAccountLink record={record} />}
          />
          <TextField source="studentId" label="studentId" sortable={false} />
          <TextField source="student" label="nom de l'élève" sortable={false} />
          <FunctionField
            label="nombre d'heure"
            render={(
              record: PayoutApi['admin']['getPayoutListSherpasDom']['response'][number]
            ) => `${minutesToHours(record.volume)}/h`}
          />
          <FunctionField
            label="nombre de mois"
            render={(
              record: PayoutApi['admin']['getPayoutListSherpasDom']['response'][number]
            ) => `${record.duration}/m`}
          />
          <TextField
            source="numberOfMinutesDone"
            label="nombre de minutes réalisé"
            sortable={false}
          />
          <CalculetteField
            source="hourlyRawFullPrice"
            label="prix du cours / h"
            sortable={false}
            formatter={(value: number) => value}
          />
          <CalculetteField
            source="totalCourseVolumeRawFullPrice"
            label="total à payer en €"
            sortable={false}
            formatter={(value: number) => value}
          />
          <ChipField source="status" label="status" />
          <TextField
            source="lastPayoutDate"
            label="date de paiement"
            sortable={false}
          />
          <TextField
            source="paymentStatus"
            label="statut du paiement"
            sortable={false}
          />
          <FunctionField
            source="blockedTransferMessage"
            label="erreur"
            sortable={false}
            render={(record: {
              blockedTransferMessage: {
                payoutDisputeReason: string;
                missingDocumentsMessage: string;
                stripeFailureMessage: string;
              };
            }) => {
              const {
                payoutDisputeReason,
                missingDocumentsMessage,
                stripeFailureMessage,
              } = record.blockedTransferMessage;

              const errorMessage =
                payoutDisputeReason ||
                missingDocumentsMessage ||
                stripeFailureMessage;
              if (!errorMessage) return null;
              const truncatedValue = formatLongString(errorMessage);
              return (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleOpenDisputeModal(errorMessage)}
                  label={truncatedValue}
                />
              );
            }}
          />
          <FunctionField
            label="virer"
            render={(
              record: PayoutApi['admin']['getPayoutListSherpasDom']['response'][number]
            ) => <PayoutButton record={record} payoutModal={payoutModal} />}
          />
        </DatagridConfigurable>
      </List>
    </>
  );
}

export default PayoutSherpasDomList;
