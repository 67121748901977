import {
  ArrayField,
  BooleanField,
  BooleanInput,
  ChipField,
  DatagridConfigurable,
  DateField,
  DateInput,
  EditButton,
  EmailField,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  Pagination,
  RaRecord,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  useGetList,
} from 'react-admin';
import { ITeacherListAdminExport } from '@les-sherpas/sherpas-toolbox';

import useGlobalStyles from '../global-styles';
import LevelChipGroup from '../LevelChipGroup';
import ListActions from '../ListActions';
import { ResetPasswordButton } from '../shared/ResetPasswordButton/ResetPasswordButton';
import SubjectAnnounceLink from '../SubjectAnnounceLink';
import csvExporter from '../utils/csvExporter';
import defaultSearchOrderedByName from '../utils/hookUtils';

const exporter = (data: ITeacherListAdminExport[]) => {
  csvExporter<ITeacherListAdminExport>({ data, fileName: 'teachers' });
};

function TeacherList() {
  const { data } = useGetList('tag', defaultSearchOrderedByName);
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      exporter={exporter}
      actions={<ListActions />}
      filterDefaultValues={{ isActive: true }}
      pagination={
        <Pagination
          sx={{ display: 'flex' }}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      }
      filters={[
        <TextInput
          label="Search"
          source="q"
          alwaysOn
          key="search"
          className={globalClasses.search}
        />,
        <TextInput label="Matière" source="subject" key="subject" />,
        <TextInput label="Niveau" source="level" key="level" />,
        <TextInput label="Code postal" source="zipCode" key="zipCode" />,
        <BooleanInput
          label="Actif ?"
          source="isActive"
          key="isActive"
          alwaysOn
        />,
        <BooleanInput
          label="Inscrit pour la formation"
          source="trainingAppointmentDateCalendly"
          key="trainingAppointmentDateCalendly"
        />,
        <BooleanInput label="Vérifié ?" source="isVerified" key="isVerified" />,
        <BooleanInput label="Formé ?" source="isTrained" key="isTrained" />,

        <SelectInput
          source="status"
          key="status"
          choices={[
            { id: 'success', name: 'Success' },
            { id: 'pending', name: 'Pending' },
            { id: 'fail', name: 'Fail' },
          ]}
        />,
        <SelectArrayInput
          source="tags"
          key="tags"
          choices={data?.filter((tag) => tag.userType === 'teacher')}
        />,
        <DateInput
          label="Date de création min"
          source="minCreatedAt"
          key="minCreatedAt"
        />,
        <DateInput
          label="Date de création max"
          source="maxCreatedAt"
          key="maxCreatedAt"
        />,
        <NumberInput label="Rating >=" key="rating" source="rating" />,
      ]}
    >
      <DatagridConfigurable>
        <TextField source="id" />
        <EditButton label="Edit" resource="teacher" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <TextField source="phoneNumber" sortable={false} />
        <FunctionField
          label="Document d'identité"
          render={(record: RaRecord) => (
            <EditButton
              record={record}
              resource="teacher"
              label={record.identityIds.length.toString()}
            />
          )}
        />
        <BooleanField source="isActive" label="Actif?" />
        <BooleanField source="isVerified" label="Vérifié?" />
        <BooleanField source="isTrained" label="Formé?" />
        <DateField
          label="Date de formation"
          source="trainingAppointmentDateCalendly"
        />
        <TextField source="siret" label="Siret" />
        <TextField source="novaAgreementNumber" label="Numéro agrément Nova" />
        <BooleanField
          source="hasSeenNovaTutorial"
          label="Tutoriel Nova regardé"
        />
        <DateField source="createdDate" />
        <DateField source="lastConnectionDate" />
        <NumberField source="numberOfAnnounces" label="Annonces" />
        <NumberField source="numberOfChatRooms" label="Chats" />
        <NumberField source="numberOfCourses" label="Cours" />
        <ArrayField source="tags" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <TextField
          label="adresse"
          source="address.formattedAddress"
          sortable={false}
        />
        <ChipField source="status" />
        <FunctionField
          label="Diplômes"
          render={(record: RaRecord) => (
            <EditButton
              record={record}
              resource="teacher"
              label={record.diplomaIds.length.toString()}
            />
          )}
        />
        <FunctionField
          label="Subjects"
          render={(record: RaRecord) => <SubjectAnnounceLink record={record} />}
        />
        <FunctionField
          label="Levels"
          sortable={false}
          render={(record: RaRecord) => <LevelChipGroup record={record} />}
        />
        <NumberField source="rating" label="Rating" />
        <TextField source="gender" label="gender" sortable={false} />
        <TextField
          source="currentInstitution"
          label="institution"
          sortable={false}
        />
        <BooleanField label="Email vérifié ?" source="isEmailVerified" />
        <FunctionField
          label="Réinitialiser le mot de passe"
          render={(record: RaRecord) => (
            <ResetPasswordButton email={record.email} />
          )}
        />
      </DatagridConfigurable>
    </List>
  );
}

export default TeacherList;
