/* eslint-disable react/react-in-jsx-scope */
import { Admin, fetchUtils, Options, Resource } from 'react-admin';
import { QueryClient } from 'react-query';
import simpleRestProvider from 'ra-data-simple-rest';

import announce from './components/announce';
import chat from './components/chat';
import chatRoom from './components/chat-room';
import course from './components/course';
import diploma from './components/diploma';
import generateSubscriptionSales from './components/generate-subscription-sales';
import identity from './components/identity';
import CustomLayout from './components/Layout';
import LegalDocument from './components/LegalDocument';
import marketingMessage from './components/MarketingMessage';
import payout from './components/payout';
import payoutSherpasDom from './components/payout-sherpas-dom';
import resources from './components/resources';
import review from './components/review';
import seo from './components/seo';
import seoStudentPages from './components/SeoStudentPages';
import sponsoring from './components/sponsoring';
import students from './components/students';
import subject from './components/subject';
import subscription from './components/subscription';
import swapChatRoom from './components/swap-chat-room';
import swapSubscription from './components/swap-subscription';
import tag from './components/tag';
import teacher from './components/teacher';
import TVCampaign from './components/TVCampaign';
import authProvider from './authProvider';

import './App.css';

const httpClient = (url: string, options: Options = {}) => {
  const clonedOptions = { ...options };
  clonedOptions.credentials = 'include';
  if (!clonedOptions.headers) {
    clonedOptions.headers = new Headers({ Accept: 'application/json' });
  }

  const typedUrl = new URL(url);
  const pathnames = typedUrl.pathname.split('/');

  if (pathnames.length > 2) {
    pathnames.splice(2, 0, 'admin');
    return fetchUtils.fetchJson(
      `${typedUrl.origin}${pathnames.join('/')}`,
      clonedOptions
    );
  }
  return fetchUtils.fetchJson(
    `${typedUrl.origin}${typedUrl.pathname}/admin?${typedUrl.searchParams}`,
    clonedOptions
  );
};

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL ?? '',
  httpClient,
  'X-Total-Count'
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={CustomLayout}
      queryClient={queryClient}
    >
      <Resource name="teacher" {...teacher} />
      <Resource name="announce" {...announce} />
      <Resource name="student" {...students} />
      <Resource name="chat-room" {...chatRoom} />
      <Resource name="course" {...course} />
      <Resource name="subscription" {...subscription} />
      <Resource
        name="payout"
        options={{ label: 'Payout en ligne' }}
        {...payout}
      />
      <Resource
        name="payout-sherpas-dom"
        options={{ label: 'Payout domicile' }}
        {...payoutSherpasDom}
      />
      <Resource name="review" {...review} />
      <Resource name="tag" {...tag} />
      <Resource name="subject" {...subject} />
      <Resource name="chat" {...chat} />
      <Resource name="identity" {...identity} />
      <Resource name="diploma" {...diploma} />
      <Resource name="swap-chat-room" {...swapChatRoom} />
      <Resource
        name="generate-subscription-sales"
        {...generateSubscriptionSales}
      />
      <Resource name="swap-subscription" {...swapSubscription} />
      <Resource name="content" {...seo} />
      <Resource name="marketing-message" {...marketingMessage} />
      <Resource name="tv-campaign" {...TVCampaign} />
      <Resource name="resources" {...resources} />
      <Resource name="seo-student-pages-template" {...seoStudentPages} />
      <Resource name="sponsoring" {...sponsoring} />
      <Resource name="legal-document" {...LegalDocument} />
    </Admin>
  );
}

export default App;
