import { FC, ReactElement } from 'react';
import Typography from '@mui/material/Typography';

import useStyles from './styles';

type props = {
  title: string;
  value?: number | string | ReactElement;
};

const SummaryRow: FC<props> = function renderSummaryRow({ title, value }) {
  const { classes } = useStyles();
  return (
    <div className={classes.summaryRow}>
      <Typography variant="h6">{title}</Typography>
      {value && <Typography>{value}</Typography>}
    </div>
  );
};

export default SummaryRow;
