import React from 'react';
import {
  BulkDeleteButton,
  BulkExportButton,
  DatagridConfigurable,
  DateField,
  List,
  NumberField,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';

import useGlobalStyles from '../global-styles';
import ListActions from '../ListActions';

function PostBulkActionButtons() {
  return (
    <>
      <BulkExportButton />
      <BulkDeleteButton />
    </>
  );
}

function ReviewList() {
  const { classes: globalClasses } = useGlobalStyles();
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
      filters={[
        <TextInput
          label="Search"
          source="q"
          alwaysOn
          key="search"
          className={globalClasses.search}
        />,
        <TextInput label="Note min" source="rating" key="rating" />,
      ]}
      pagination={<Pagination sx={{ display: 'flex' }} />}
    >
      <DatagridConfigurable
        rowClick="edit"
        bulkActionButtons={<PostBulkActionButtons />}
      >
        <TextField source="id" />
        <DateField source="createdDate" />
        <NumberField source="rating" />
        <TextField source="comment" sortable={false} />
        <TextField source="teacherFullName" sortable={false} />
        <TextField source="studentFullName" sortable={false} />
      </DatagridConfigurable>
    </List>
  );
}

export default ReviewList;
