import React from 'react';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { format } from 'date-fns';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  typo: {
    color: grey[600],
  },
  date: {
    display: 'flex',
    margin: '4px',
    justifyContent: 'center',
  },
}));

function ChatDate({ date }: { date: Date }) {
  const { classes } = useStyles();

  return (
    <div className={classes.date}>
      <Typography variant="body1" className={classes.typo}>
        {format(new Date(date), 'd LLL yyyy - HH:mm')}
      </Typography>
    </div>
  );
}

export default ChatDate;
