import { makeStyles } from 'tss-react/mui';

type Props = {
  isFullWidth: boolean;
};

export default makeStyles<Props>()((_, { isFullWidth }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: isFullWidth ? '100%' : 'auto',
  },
  autoComplete: {
    padding: '8px',
    border: '1px solid #0069c2',
    borderRadius: '0.25em',
  },
}));
