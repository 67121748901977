/* eslint-disable no-restricted-imports */
import { FC } from 'react';
import {
  ChatRoomApi,
  formatPriceInEuros,
  priceCalculator,
  ProductType,
} from '@les-sherpas/sherpas-toolbox';
import { Avatar, TableCell, TableRow } from '@mui/material';
import { format, isFuture, minutesToHours } from 'date-fns';

import ExpirationCell from './ExpirationCell/ExpirationCell';
import HasPurchasedCell from './HasPurchasedCell/HasPurchasedCell';
import ProductTypeCell from './ProductTypeCell/ProductTypeCell';

import useStyles from './styles';

type Props = {
  proposal: ChatRoomApi['admin']['getChatRoomForProposal']['response']['subscriptionProposals'][number];
  index: number;
};

const ProposalRow: FC<Props> = function proposalRow({ proposal, index }) {
  const { classes } = useStyles();
  const {
    createdBy,
    createdDate,
    productType,
    location,
    commissionRate,
    managementCostRate,
    educationalCostRate,
    duration,
    volume,
    expirationDate,
    subscription,
    hourlyRawPrice,
    hourlyRawTravelFee,
  } = proposal;

  const isActive = index === 0 && isFuture(new Date(expirationDate));
  const creatorFullName = createdBy.fullName;
  const isRecurringTraining = productType === ProductType.RecurringTraining;

  const { monthlyEducationalCost } = priceCalculator.sreDebitAdminSimulation({
    hourlyRawPrice,
    hourlyRawTravelFee,
    volume,
    duration,
    location,
    commissionRate,
    managementCostRate,
    educationalCostRate,
  });

  return (
    <TableRow className={isActive ? classes.activeRow : classes.inactiveRow}>
      <TableCell>
        {format(new Date(createdDate), 'yyyy-MM-dd HH:mm:ss')}
      </TableCell>
      <ProductTypeCell productType={productType} />
      <TableCell className={classes.createdByCell}>
        <Avatar src={createdBy.photoUrl} alt={creatorFullName} />
        <a
          href={`#/${createdBy.userType}/${createdBy.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {creatorFullName}
        </a>
      </TableCell>
      <TableCell>{duration} mois</TableCell>
      <TableCell>{minutesToHours(volume)}/h</TableCell>
      <TableCell>{location}</TableCell>
      <TableCell>{`${commissionRate} %`}</TableCell>
      <TableCell>
        {!isRecurringTraining ? `${managementCostRate}%` : '-'}
      </TableCell>
      <TableCell>
        {isRecurringTraining ? formatPriceInEuros(monthlyEducationalCost) : '-'}
      </TableCell>
      <ExpirationCell expirationDate={new Date(expirationDate)} />
      <HasPurchasedCell subscription={subscription} isActive={isActive} />
    </TableRow>
  );
};

export default ProposalRow;
