import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

type CsvExporterType<T> = {
  data: T[];
  fileName: string;
};

const csvExporter = <T>({ data, fileName }: CsvExporterType<T>) => {
  jsonExport(data, { textDelimiter: ';', rowDelimiter: ';' }, (err, csv) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.error('exporter : ', err);
      return;
    }
    downloadCSV(csv, fileName);
  });
};

export default csvExporter;
