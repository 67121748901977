import { Dispatch, SetStateAction, useEffect } from 'react';
import { Link } from 'react-admin';
import {
  AnnounceAdminFromTeacher,
  formatInParis,
  getPriceFromCentimes,
  IntensiveTrainingDuration,
  IntensiveTrainingVolume,
  LocationOfCourse,
  priceCalculator,
  ProductType,
  RecurringTrainingDuration,
  RecurringTrainingVolume,
} from '@les-sherpas/sherpas-toolbox';
import { Info } from '@mui/icons-material';
import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { minutesToHours } from 'date-fns';
import addMinutes from 'date-fns/addMinutes';

import SummaryRow from './SummaryDetailsRow';
import SummaryTableCell from './SummaryTableCell';

import useStyles from './styles';

type Props = {
  level?: string;
  subject?: string;
  location: LocationOfCourse;
  volume?: RecurringTrainingVolume | IntensiveTrainingVolume;
  duration: RecurringTrainingDuration | IntensiveTrainingDuration;
  announce?: AnnounceAdminFromTeacher;
  teacherFullName?: string;
  studentFullName?: string;
  parentFullName?: string;
  hourlyRawPrice: number;
  hourlyRawTravelFee: number;
  commissionRate: number;
  managementCostRate: number;
  educationalCostRate: number;
  setCalculatedEducationalCostRate: Dispatch<SetStateAction<number>>;
  productType: ProductType;
  expirationDuration: number;
};

const numberFormat = new Intl.NumberFormat('fr-FR', {
  currency: 'EUR',
  style: 'currency',
});

function SummaryPanel({
  duration,
  level,
  location,
  subject,
  volume,
  announce,
  teacherFullName,
  studentFullName,
  parentFullName,
  hourlyRawPrice: price,
  hourlyRawTravelFee: travelFee,
  commissionRate,
  managementCostRate,
  educationalCostRate,
  setCalculatedEducationalCostRate,
  productType,
  expirationDuration,
}: Props) {
  const { classes } = useStyles();

  const isRecurringTraining = productType === ProductType.RecurringTraining;

  const debitAdminSimulationCalculator = isRecurringTraining
    ? priceCalculator.sreDebitAdminSimulation
    : priceCalculator.sieDebitAdminSimulation;

  const {
    hourlyPriceWithDiscount,
    hourlyEducationalCost,
    hourlyOverallCommissionRate,
    hourlyOverallCommission,
    hourlyRawFullPrice,

    firstMonthHourlyPriceWithDiscount,
    firstMonthHourlyEducationalCost,
    firstMonthHourlyOverallCommissionRate,
    firstMonthHourlyOverallCommission,
    firstMonthHourlyRawFullPrice,

    firstMonthMonthlyPriceWithDiscount,
    firstMonthMonthlyEducationalCost,
    firstMonthMonthlyOverallCommissionRate,
    firstMonthMonthlyOverallCommission,
    firstMonthMonthlyRawFullPrice,

    monthlyPriceWithDiscount,
    monthlyEducationalCost,
    monthlyOverallCommissionRate,
    monthlyOverallCommission,
    monthlyRawFullPrice,

    globalPriceWithDiscount,
    globalEducationalCost,
    globalOverallCommissionRate,
    globalOverallCommission,
    globalRawFullPrice,

    globalManagementCost,
    educationalCostRate: calculatedEducationalCostRate,
  } = debitAdminSimulationCalculator({
    hourlyRawPrice: price,
    hourlyRawTravelFee: travelFee,
    volume: volume ?? 0,
    duration,
    location,
    commissionRate,
    managementCostRate,
    educationalCostRate,
  });

  useEffect(
    () => setCalculatedEducationalCostRate(calculatedEducationalCostRate),
    [calculatedEducationalCostRate, setCalculatedEducationalCostRate]
  );

  return (
    <div className={classes.recap}>
      <Typography variant="h4" className={classes.recapTitle}>
        <Info fontSize="large" color="primary" />
        Récapitulatif
      </Typography>
      <Typography variant="h6">Professeur</Typography>
      <Divider />
      <Typography>{teacherFullName}</Typography>
      <Typography variant="h6">Élève</Typography>
      <Divider />
      <Typography>{studentFullName}</Typography>
      {parentFullName ? (
        <>
          <Typography variant="h6">Parent</Typography>
          <Divider />
          <Typography>{parentFullName}</Typography>
        </>
      ) : null}
      <Typography variant="h6">Annonce</Typography>
      <Divider />
      <Typography>
        <Link
          to={{
            pathname: '/announce',
            search: `filter=${JSON.stringify({ q: announce?.title })}`,
          }}
          target="_blank"
          rel="noreferrer"
        >
          {announce?.title}
        </Link>
      </Typography>
      <Divider />
      <div className={classes.summaryContainer}>
        <SummaryRow title="Matière" value={subject} />
        <SummaryRow title="Niveau" value={level} />
        <SummaryRow
          title="Volume"
          value={`${minutesToHours(volume ?? 0)} heures`}
        />
        <SummaryRow title="Période" value={`${duration} mois`} />
        <SummaryRow title="Location" value={location} />
        {productType === ProductType.IntensiveTraining && (
          <SummaryRow
            title="Frais de dossier"
            value={
              <Tooltip title="globalManagementCost">
                <>
                  {numberFormat.format(
                    getPriceFromCentimes(globalManagementCost)
                  )}
                </>
              </Tooltip>
            }
          />
        )}
      </div>
      <TableContainer sx={{ marginTop: '25px' }} component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="Calculette">
          <caption>
            <Typography>
              ⌛ Date d&apos;expiration :
              <b style={{ textTransform: 'capitalize' }}>
                {formatInParis(
                  addMinutes(
                    new Date(),
                    Number.isNaN(expirationDuration) ? 1 : expirationDuration
                  ),
                  'EEEE dd MMMM yyyy HH:mm'
                )}
              </b>
            </Typography>
          </caption>
          <TableHead>
            <TableRow>
              <TableCell padding="none" />
              <TableCell colSpan={2} align="center" padding="none">
                Premier mois
              </TableCell>
              {isRecurringTraining && (
                <>
                  <TableCell colSpan={2} align="center" padding="none">
                    Mois suivants
                  </TableCell>
                  <TableCell align="center" padding="none">
                    Sur la durée
                  </TableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell align="center" padding="none">
                (horaire)
              </TableCell>
              <TableCell align="center" padding="none">
                (mensuel)
              </TableCell>
              {isRecurringTraining && (
                <>
                  <TableCell align="center" padding="none">
                    (horaire)
                  </TableCell>
                  <TableCell align="center" padding="none">
                    (mensuel)
                  </TableCell>
                  <TableCell align="center" padding="none">
                    (global)
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="student-prices">
              <TableCell component="th" scope="row">
                Payé par l&apos;étudiant
              </TableCell>
              <SummaryTableCell
                key="firstMonthHourlyPriceWithDiscount"
                title="firstMonthHourlyPriceWithDiscount"
                className={classes.priceWithDiscount}
                tooltipContent={numberFormat.format(
                  getPriceFromCentimes(firstMonthHourlyPriceWithDiscount)
                )}
              />
              <SummaryTableCell
                key="firstMonthMonthlyPriceWithDiscount"
                title="firstMonthMonthlyPriceWithDiscount"
                className={classes.priceWithDiscount}
                tooltipContent={numberFormat.format(
                  getPriceFromCentimes(firstMonthMonthlyPriceWithDiscount)
                )}
              />
              {isRecurringTraining && (
                <>
                  <SummaryTableCell
                    key="hourlyPriceWithDiscount"
                    title="hourlyPriceWithDiscount"
                    className={classes.priceWithDiscount}
                    tooltipContent={numberFormat.format(
                      getPriceFromCentimes(hourlyPriceWithDiscount)
                    )}
                  />
                  <SummaryTableCell
                    key="monthlyPriceWithDiscount"
                    title="monthlyPriceWithDiscount"
                    className={classes.priceWithDiscount}
                    tooltipContent={numberFormat.format(
                      getPriceFromCentimes(monthlyPriceWithDiscount)
                    )}
                  />
                  <SummaryTableCell
                    key="globalPriceWithDiscount"
                    title="globalPriceWithDiscount"
                    className={classes.priceWithDiscount}
                    tooltipContent={numberFormat.format(
                      getPriceFromCentimes(globalPriceWithDiscount)
                    )}
                  />
                </>
              )}
            </TableRow>
            <TableRow key="sherpas-prices">
              <TableCell component="th" scope="row">
                Taux de marge Sherpas
              </TableCell>
              <SummaryTableCell
                key="firstMonthHourlyOverallCommissionRate"
                title="firstMonthHourlyOverallCommissionRate"
                className={classes.commission}
                tooltipContent={`${firstMonthHourlyOverallCommissionRate} %`}
              />
              <SummaryTableCell
                key="firstMonthMonthlyOverallCommissionRate"
                title="firstMonthMonthlyOverallCommissionRate"
                className={classes.commission}
                tooltipContent={`${firstMonthMonthlyOverallCommissionRate} %`}
              />
              {isRecurringTraining && (
                <>
                  <SummaryTableCell
                    key="hourlyOverallCommissionRate"
                    title="hourlyOverallCommissionRate"
                    className={classes.commission}
                    tooltipContent={`${hourlyOverallCommissionRate} %`}
                  />
                  <SummaryTableCell
                    key="monthlyOverallCommissionRate"
                    title="monthlyOverallCommissionRate"
                    className={classes.commission}
                    tooltipContent={`${monthlyOverallCommissionRate} %`}
                  />
                  <SummaryTableCell
                    key="globalOverallCommissionRate"
                    title="globalOverallCommissionRate"
                    className={classes.commission}
                    tooltipContent={`${globalOverallCommissionRate} %`}
                  />
                </>
              )}
            </TableRow>
            <TableRow key="sherpas-margin-prices">
              <TableCell component="th" scope="row">
                Marge Sherpas
              </TableCell>
              <SummaryTableCell
                key="firstMonthHourlyOverallCommission"
                title="firstMonthHourlyOverallCommission"
                className={classes.commission}
                tooltipContent={numberFormat.format(
                  getPriceFromCentimes(firstMonthHourlyOverallCommission)
                )}
              />
              <SummaryTableCell
                key="firstMonthMonthlyOverallCommission"
                title="firstMonthMonthlyOverallCommission"
                className={classes.commission}
                tooltipContent={numberFormat.format(
                  getPriceFromCentimes(firstMonthMonthlyOverallCommission)
                )}
              />
              {isRecurringTraining && (
                <>
                  <SummaryTableCell
                    key="hourlyOverallCommission"
                    title="hourlyOverallCommission"
                    className={classes.commission}
                    tooltipContent={numberFormat.format(
                      getPriceFromCentimes(hourlyOverallCommission)
                    )}
                  />
                  <SummaryTableCell
                    key="monthlyOverallCommission"
                    title="monthlyOverallCommission"
                    className={classes.commission}
                    tooltipContent={numberFormat.format(
                      getPriceFromCentimes(monthlyOverallCommission)
                    )}
                  />
                  <SummaryTableCell
                    key="globalOverallCommission"
                    title="globalOverallCommission"
                    className={classes.commission}
                    tooltipContent={numberFormat.format(
                      getPriceFromCentimes(globalOverallCommission)
                    )}
                  />
                </>
              )}
            </TableRow>
            {isRecurringTraining && (
              <TableRow key="educational-prices">
                <TableCell component="th" scope="row">
                  Frais pédagogiques
                </TableCell>
                <SummaryTableCell
                  key="firstMonthHourlyEducationalCost"
                  title="firstMonthHourlyEducationalCost"
                  className={classes.educationalCost}
                  tooltipContent={numberFormat.format(
                    getPriceFromCentimes(firstMonthHourlyEducationalCost)
                  )}
                />
                <SummaryTableCell
                  key="firstMonthMonthlyEducationalCost"
                  title="firstMonthMonthlyEducationalCost"
                  className={classes.educationalCost}
                  tooltipContent={numberFormat.format(
                    getPriceFromCentimes(firstMonthMonthlyEducationalCost)
                  )}
                />
                {isRecurringTraining && (
                  <>
                    <SummaryTableCell
                      key="hourlyEducationalCost"
                      title="hourlyEducationalCost"
                      className={classes.educationalCost}
                      tooltipContent={numberFormat.format(
                        getPriceFromCentimes(hourlyEducationalCost)
                      )}
                    />
                    <SummaryTableCell
                      key="monthlyEducationalCost"
                      title="monthlyEducationalCost"
                      className={classes.educationalCost}
                      tooltipContent={numberFormat.format(
                        getPriceFromCentimes(monthlyEducationalCost)
                      )}
                    />
                    <SummaryTableCell
                      key="globalEducationalCost"
                      title="globalEducationalCost"
                      className={classes.educationalCost}
                      tooltipContent={numberFormat.format(
                        getPriceFromCentimes(globalEducationalCost)
                      )}
                    />
                  </>
                )}
              </TableRow>
            )}
            <TableRow key="teacher-prices">
              <TableCell component="th" scope="row">
                Payé au professeur
              </TableCell>
              <SummaryTableCell
                key="firstMonthHourlyRawFullPrice"
                title="firstMonthHourlyRawFullPrice"
                className={classes.rawFullPrice}
                tooltipContent={numberFormat.format(
                  getPriceFromCentimes(firstMonthHourlyRawFullPrice)
                )}
              />
              <SummaryTableCell
                key="firstMonthMonthlyRawFullPrice"
                title="firstMonthMonthlyRawFullPrice"
                className={classes.rawFullPrice}
                tooltipContent={numberFormat.format(
                  getPriceFromCentimes(firstMonthMonthlyRawFullPrice)
                )}
              />
              {isRecurringTraining && (
                <>
                  <SummaryTableCell
                    key="hourlyRawFullPrice"
                    title="hourlyRawFullPrice"
                    className={classes.rawFullPrice}
                    tooltipContent={numberFormat.format(
                      getPriceFromCentimes(hourlyRawFullPrice)
                    )}
                  />
                  <SummaryTableCell
                    key="monthlyRawFullPrice"
                    title="monthlyRawFullPrice"
                    className={classes.rawFullPrice}
                    tooltipContent={numberFormat.format(
                      getPriceFromCentimes(monthlyRawFullPrice)
                    )}
                  />
                  <SummaryTableCell
                    key="globalRawFullPrice"
                    title="globalRawFullPrice"
                    className={classes.rawFullPrice}
                    tooltipContent={numberFormat.format(
                      getPriceFromCentimes(globalRawFullPrice)
                    )}
                  />
                </>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SummaryPanel;
