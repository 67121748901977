import React from 'react';
import {
  BooleanField,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import SeoUrlField from '../SeoUrlField';

function CourseShow() {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="teacherName" label="Sherpas" />
        <TextField source="studentName" label="Etudiant" />
        <DateField source="createdDate" label="Date de Création" showTime />
        <DateField source="date" label="Programmée pour" showTime />
        <TextField source="status" label="Status" />
        <DateField source="updatedDate" label="Updated Date" showTime />
        <TextField source="subject" label="Matière" />
        <TextField source="level" label="Niveau" />
        <TextField source="location" label="Location" />
        <BooleanField
          source="isFirstCourse"
          sortable={false}
          label="Cours d'essai"
        />
        <SeoUrlField label="Annonce" source="seoId" target="_blank" />
      </SimpleShowLayout>
    </Show>
  );
}

export default CourseShow;
