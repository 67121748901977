/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { memo } from 'react';
import { AppBar, InspectorButton, Layout } from 'react-admin';
import { Typography } from '@mui/material';

const MyAppBar = memo((props) => (
  <AppBar {...props}>
    <Typography variant="h6" id="react-admin-title" style={{ flex: 1 }} />
    <InspectorButton />
  </AppBar>
));

function CustomLayout(props: any) {
  return <Layout {...props} appBar={MyAppBar} />;
}

export default CustomLayout;
