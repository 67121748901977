import { FC } from 'react';
import { TableCell } from '@mui/material';

type Props = {
  columns: string[];
};

const ProposalColumns: FC<Props> = function ProposalColumns({ columns }) {
  return (
    <>
      {columns.map((column, index) => (
        <TableCell key={`table-proposal-history-cell-column-item${index}`}>
          {column}
        </TableCell>
      ))}
    </>
  );
};

export default ProposalColumns;
