import { FC } from 'react';
import { ChipField, useRecordContext } from 'react-admin';
import CheckCircle from '@mui/icons-material/CheckCircle';
import PauseCircle from '@mui/icons-material/PauseCircle';
import WarningAmber from '@mui/icons-material/WarningAmber';

type props = {
  source: string;
  label: string;
};

const SubscriptionStatusChip: FC<props> = function subscriptionStatus({
  source,
  label,
}) {
  const record = useRecordContext();
  let icon = <CheckCircle color="success" />;

  switch (record[source]) {
    case 'paused':
      icon = <PauseCircle color="info" />;
      break;
    case 'incomplete':
    case 'canceled':
      icon = <WarningAmber color="error" />;
      break;

    default:
      break;
  }

  return (
    <ChipField
      textAlign="center"
      source={source}
      label={label}
      icon={icon}
      sortable={false}
      title='Statut de l"abonnement'
    />
  );
};

export default SubscriptionStatusChip;
