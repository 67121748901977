/* eslint-disable no-restricted-imports */
import React, { useState } from 'react';
import {
  Button,
  DatagridConfigurable,
  DateField,
  EditButton,
  ExportButton,
  FilterButton,
  FunctionField,
  List,
  Pagination,
  SelectColumnsButton,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
} from 'react-admin';
import {
  formatDurationInMinutes,
  getPriceFromCentimes,
  ISubscriptionViewAdmin,
  SubscriptionStatus,
} from '@les-sherpas/sherpas-toolbox';
import { SwapHorizontalCircleRounded } from '@mui/icons-material';
import minutesToHours from 'date-fns/minutesToHours';

import CalculetteField, {
  CalculetteFieldProps,
} from '../../commun/CalculetteField/CalculetteField';
import useGlobalStyles, { calculleteColors } from '../../global-styles';
import SubscriptionPaymentPauseReasonLabel from '../shared/SubscriptionPaymentPauseReasonLabel/SubscriptionPaymentPauseReasonLabel';
import SubscriptionRefundModal from '../shared/SubscriptionRefund/SubscriptionRefundModal';
import SubscriptionStatusChip from '../shared/SubscriptionStatus/SubscriptionStatus';
import { subscriptionsCsvExporter } from '../shared/utils/subscriptions-csv-exporter';

function SubscriptionList() {
  const { classes: globalClasses } = useGlobalStyles();
  const [selectedSubscriptionToRefund, setSelectedSubscriptionRefund] =
    useState<number>(-1);

  const calculetteFields: CalculetteFieldProps[] = [
    {
      source: 'hourlyRawFullPrice',
      label: 'Prix Prof (h)',
      formatter: (value) => getPriceFromCentimes(value),
    },

    {
      source: 'firstMonthHourlyPriceWithDiscount',
      label: '1er mois Prix (h)',
      formatter: (value) => getPriceFromCentimes(value),
    },
    {
      source: 'firstMonthMonthlyPriceWithDiscount',
      label: '1er mois Prix',
      formatter: (value) => getPriceFromCentimes(value),
    },
    {
      source: 'firstMonthMonthlyOverallCommissionRate',
      label: '1er mois Tx Marge',
      formatter: (value) => value,
      suffix: '%',
    },

    {
      source: 'monthlyEducationalCost',
      label: 'Frais pédagogiques',
      formatter: (value: number) => getPriceFromCentimes(value),
    },

    {
      source: 'hourlyPriceWithDiscount',
      label: 'Prix (h)',
      formatter: (value) => getPriceFromCentimes(value),
    },
    {
      source: 'monthlyPriceWithDiscount',
      label: 'Prix',
      formatter: (value) => getPriceFromCentimes(value),
    },
    {
      source: 'monthlyOverallCommissionRate',
      label: 'Tx Marge',
      formatter: (value) => value,
      suffix: '%',
    },
  ];

  return (
    <List
      sort={{ field: 'createdDate', order: 'DESC' }}
      actions={
        <TopToolbar>
          <SelectColumnsButton />
          <FilterButton />
          <ExportButton
            maxResults={10000}
            exporter={subscriptionsCsvExporter}
          />
        </TopToolbar>
      }
      filters={[
        <TextInput
          label="Search"
          source="q"
          alwaysOn
          key="search"
          className={globalClasses.search}
        />,
        <SelectInput
          source="status"
          key="status"
          choices={[
            {
              id: SubscriptionStatus.ACTIVE,
              name: SubscriptionStatus.ACTIVE,
            },
            {
              id: SubscriptionStatus.CANCELED,
              name: SubscriptionStatus.CANCELED,
            },
            {
              id: SubscriptionStatus.INCOMPLETE,
              name: SubscriptionStatus.INCOMPLETE,
            },
          ]}
        />,
      ]}
      pagination={<Pagination sx={{ display: 'flex' }} />}
    >
      <DatagridConfigurable
        hover
        sx={{
          '& .RaDatagrid-headerCell.column-hourlyRawFullPrice, .RaDatagrid-headerCell.column-hourlyPriceWithDiscount, .RaDatagrid-headerCell.column-globalOverallCommissionRate':
            {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          '& .column-hourlyRawFullPrice, & .column-hourlyPriceWithDiscount, & .column-globalOverallCommissionRate':
            {
              textAlign: 'center',
            },
          '& .RaDatagrid-headerCell.column-hourlyRawFullPrice': {
            ...calculleteColors.rawFullPrice,
          },

          '& .RaDatagrid-headerCell.column-firstMonthHourlyPriceWithDiscount': {
            ...calculleteColors.priceWithDiscount,
          },
          '& .RaDatagrid-headerCell.column-firstMonthMonthlyPriceWithDiscount':
            {
              ...calculleteColors.priceWithDiscount,
            },
          '& .RaDatagrid-headerCell.column-firstMonthMonthlyOverallCommissionRate':
            {
              ...calculleteColors.overallCommissionRate,
            },
          '& .RaDatagrid-headerCell.column-monthlyEducationalCost': {
            ...calculleteColors.educationalCost,
          },
          '& .RaDatagrid-headerCell.column-hourlyPriceWithDiscount': {
            ...calculleteColors.priceWithDiscount,
          },
          '& .RaDatagrid-headerCell.column-monthlyPriceWithDiscount': {
            ...calculleteColors.priceWithDiscount,
          },
          '& .RaDatagrid-headerCell.column-monthlyOverallCommissionRate': {
            ...calculleteColors.overallCommissionRate,
          },
        }}
      >
        <TextField source="id" label="Id sherpas" sortable />
        <TextField source="student.fullName" label="Student" sortable={false} />
        <TextField source="mainUser.fullName" label="Parent" sortable={false} />
        <TextField source="teacher.fullName" label="Teacher" sortable={false} />
        <TextField
          source="student.email"
          label="Student email"
          sortable={false}
        />
        <TextField
          source="teacher.email"
          label="Teacher email"
          sortable={false}
        />
        <DateField source="createdDate" label="Date de Création" showTime />
        <DateField source="endDate" label="Date de fin" sortable={false} />
        <DateField
          source="extensionPeriodEndDate"
          label="Prolongation jusqu'au"
          sortable={false}
        />
        <DateField
          source="currentHolidayEndDate"
          label="Vacances jusqu'au"
          sortable={false}
        />
        <TextField source="productName" label="Produit" sortable={false} />
        <FunctionField
          sortable={false}
          label="Volume"
          render={(record: ISubscriptionViewAdmin) =>
            minutesToHours(record.volume)
          }
        />
        <TextField source="duration" label="Durée" sortable={false} />

        {calculetteFields.map((field: CalculetteFieldProps, index) => (
          <CalculetteField key={index} sortable={false} {...field} />
        ))}

        <FunctionField
          sortable={false}
          source="remainingMinutes"
          label="Temps restant"
          render={(record: ISubscriptionViewAdmin) =>
            formatDurationInMinutes(record.remainingMinutes)
          }
        />
        <TextField source="location" label="Lieu" sortable={false} />
        <SubscriptionStatusChip source="status" label="Status" />
        <WrapperField label="En pause">
          <SubscriptionPaymentPauseReasonLabel source="paymentPauseReason" />
        </WrapperField>

        <ShowButton
          label="Swap"
          resource="swap-subscription"
          icon={<SwapHorizontalCircleRounded />}
        />
        <EditButton />
        <FunctionField
          label="cancel & refund"
          render={(record: ISubscriptionViewAdmin) => (
            <>
              <Button
                label="Annuler & Refund"
                onClick={() => {
                  if (
                    record.subscriptionId !== '' &&
                    record.status === SubscriptionStatus.ACTIVE
                  ) {
                    setSelectedSubscriptionRefund(record.id);
                  }
                }}
                disabled={record.status !== SubscriptionStatus.ACTIVE}
                variant="contained"
                className={globalClasses.btnGreen}
              />
              <SubscriptionRefundModal
                subscriptionId={record.subscriptionId}
                maxToRefund={record.maxToRefund}
                priceCoursesDone={record.coursesVolumePriceWithDiscount}
                monthlyPriceWithDiscount={record.monthlyPriceWithDiscount}
                isModalOpened={
                  selectedSubscriptionToRefund === record.id && record.id > 0
                }
                handleCloseRefundModal={() => setSelectedSubscriptionRefund(0)}
              />
            </>
          )}
        />
        <DateField
          source="cancelationDate"
          label="Date d'annulation"
          sortable
        />
        <TextField source="subscriptionId" label="Id Stripe" sortable />
        <TextField
          source="stripeAccountSource"
          label="Compte Stripe"
          sortable={false}
        />
      </DatagridConfigurable>
    </List>
  );
}

export default SubscriptionList;
