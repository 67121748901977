import React from 'react';
import { getPriceFromCentimes } from '@les-sherpas/sherpas-toolbox';
import { Grid, TextField } from '@mui/material';

import { SubscriptionOptionsFormProps } from '../utils';

function SubscriptionProposalAnnouncePrice({
  hourlyRawPrice,
  hourlyRawTravelFee,
  setHourlyRawPrice,
  setHourlyRawTravelFee: setTravelFee,
}: Pick<
  SubscriptionOptionsFormProps,
  | 'hourlyRawPrice'
  | 'setHourlyRawPrice'
  | 'hourlyRawTravelFee'
  | 'setHourlyRawTravelFee'
>) {
  return (
    <>
      <Grid item xs={6}>
        <TextField
          id="hourlyPrice"
          type="number"
          label="Taux Horaire"
          variant="outlined"
          value={getPriceFromCentimes(hourlyRawPrice)}
          onChange={(e) => setHourlyRawPrice(parseFloat(e.target.value) * 100)}
          InputProps={{
            inputProps: {
              max: 100,
              min: 0,
            },
          }}
          fullWidth
          error={hourlyRawPrice < 0}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="travelFee"
          type="number"
          label="Travel Fee"
          variant="outlined"
          value={getPriceFromCentimes(hourlyRawTravelFee)}
          onChange={(e) => setTravelFee(parseFloat(e.target.value) * 100)}
          InputProps={{
            inputProps: {
              max: 100,
              min: 0,
            },
          }}
          fullWidth
          error={hourlyRawTravelFee < 0}
        />
      </Grid>
    </>
  );
}

export default SubscriptionProposalAnnouncePrice;
