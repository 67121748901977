/* eslint-disable no-restricted-imports */
import { useState } from 'react';
import {
  BooleanInput,
  DeleteWithConfirmButton,
  Edit,
  FormDataConsumer,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useRecordContext,
} from 'react-admin';
import { AdminStudent, IAddress } from '@les-sherpas/sherpas-toolbox';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import AddressAutoComplete from '../../commun/Autocomplete/addressAutocomplete';
import StudentGroupUsersList from './groupUsersList/student-group-users';
import StudentChip from './studentChip/student-chip';
import {
  canDeleteStudent,
  getActionName,
  getConfirmContent,
  getStudentFullName,
} from './student-edit.config';

const useStyles = makeStyles()(() => ({
  deleteButton: {
    marginLeft: 'auto',
  },
}));

function EditToolbar(props: ToolbarProps) {
  const { classes } = useStyles();
  const student =
    useRecordContext<AdminStudent['getList']['response']['one']>();

  return (
    <Toolbar {...props}>
      <SaveButton alwaysEnable />

      <DeleteWithConfirmButton
        className={classes.deleteButton}
        confirmTitle={`${getActionName(student)} #${getStudentFullName(
          student
        )}`}
        confirmContent={getConfirmContent(student)}
        label={`${getActionName(student)}`}
        icon={student.isActive ? <DeleteIcon /> : <RestoreIcon />}
        disabled={!canDeleteStudent(student)}
      />
    </Toolbar>
  );
}

function StudentEdit() {
  const [address, setAddress] = useState<IAddress>();

  return (
    <Edit
      transform={(data: AdminStudent['update']['response']) => {
        const transformedData = { ...data };
        if (address !== undefined) {
          transformedData.address = address;
        }
        return transformedData;
      }}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <FormDataConsumer<AdminStudent['update']['response']>>
          {({ formData }) => (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextInput
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  label="Id"
                  disabled
                  source="id"
                />
              </Grid>
              <Grid item xs={4}>
                <StudentChip
                  isParent={formData?.groupUsers.length > 0}
                  isChild={!!formData?.mainUser}
                />
              </Grid>
              <Grid item xs={4}>
                <BooleanInput
                  type="checkbox"
                  source="mainUser"
                  format={(mainUser) => !mainUser}
                  label="Main account ?"
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  source="firstName"
                  variant="outlined"
                  isRequired
                  label="Prénom"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  source="lastName"
                  variant="outlined"
                  isRequired
                  label="Nom"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  type="email"
                  source="email"
                  variant="outlined"
                  isRequired
                  label="Email"
                  placeholder="Email"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  type="tel"
                  source="phoneNumber"
                  lang="Téléphone"
                  variant="outlined"
                  label="Téléphone"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <AddressAutoComplete
                  address={formData.address?.formattedAddress ?? ''}
                  setAddress={setAddress}
                />
              </Grid>
              <Grid item xs={12}>
                {(formData.groupUsers?.length > 0 || formData.mainUser) && (
                  <>
                    <h1>
                      {formData.mainUser
                        ? 'Profil  du parent'
                        : "Profil de l'enfant"}
                    </h1>

                    <StudentGroupUsersList
                      groupUsers={formData.groupUsers}
                      mainUser={formData.mainUser}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}

export default StudentEdit;
