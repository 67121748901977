import Hourglass from '@mui/icons-material/HourglassFullTwoTone';

import SubscriptionEdit from './edit/subscription-edit';
import SubscriptionList from './list/subscription-list';

export default {
  list: SubscriptionList,
  icon: Hourglass,
  edit: SubscriptionEdit,
};
