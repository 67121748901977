import { AuthProvider, UserIdentity } from 'react-admin';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ?? '';

const getIdentity = async () => {
  const { data } = await axios.get<UserIdentity>(`${BASE_URL}/auth/me/admin`, {
    withCredentials: true,
    validateStatus: (status) => status === 200,
  });

  return {
    ...data,
    avatar: data.photoUrl,
    fullName: `${data.firstName} ${data.lastName}`,
  };
};

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    await axios.post(
      `${BASE_URL}/auth/login`,
      {
        username,
        password,
      },
      { withCredentials: true }
    );
  },
  logout: async () => {
    axios.get(`${BASE_URL}/auth/logout`, { withCredentials: true });
  },
  checkAuth: async () => {
    await getIdentity();
  },
  getPermissions: async () => {
    await getIdentity();
  },
  checkError: (_error) => Promise.resolve(),
  getIdentity,
};

export default authProvider;
