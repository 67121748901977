import React from 'react';
import {
  ChipField,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { makeStyles } from 'tss-react/mui';

import IdentityField from '../IdentityField';

const useStyles = makeStyles()(() => ({
  identityShow: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
}));

function IdentityShow() {
  const { classes } = useStyles();
  return (
    <Show>
      <SimpleShowLayout>
        <div className={classes.identityShow}>
          id:
          <TextField source="id" />
          Document Type:
          <ChipField source="documentType" color="primary" />
          Supprimé le:
          <DateField source="deletedAt" />
        </div>
        <IdentityField
          source="url"
          target="_blank"
          originalName="originalName"
        />
      </SimpleShowLayout>
    </Show>
  );
}

export default IdentityShow;
