import React, { useEffect, useState } from 'react';
import { Loading, Title } from 'react-admin';
import { useParams } from 'react-router-dom';
import { AdminChat, UserType } from '@les-sherpas/sherpas-toolbox';
import { Button, List } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import ChatMessageCourse from './chat-message-course/chat-message-course';
import ChatMessageDmr from './chat-message-dmr/chat-message-dmr';
import ChatMessageDocument from './chat-message-document/chat-message-document';
import ChatMessageUser from './chat-message-user/chat-message-user';
import {
  getConversations,
  isConversationCourse,
  isConversationDocument,
  isConversationMessage,
  isConversationMessageDmr,
} from './chat-show.utils';

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingTop: '16px',
    width: '100%',
    maxWidth: '500ch',
    backgroundColor: theme.palette.background.paper,
  },
}));

function Chat() {
  const { classes } = useStyles();
  const { id: chatRoomId } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [chatRoomUsers, setChatRoomUsers] = useState<
    AdminChat['getChatRoomConversation']['response']['chatRoomUsers']
  >([]);
  const [conversations, setConversations] = useState<
    AdminChat['getChatRoomConversation']['response']['conversations']
  >([]);

  useEffect(() => {
    (async () => {
      const { chatRoomUsers, conversations, hasNextPage } =
        await getConversations(chatRoomId, currentPage);
      setChatRoomUsers(chatRoomUsers);
      setConversations(conversations);
      setIsDisabled(!hasNextPage);
    })();
  }, []);

  if (conversations.length === 0) {
    return <Loading />;
  }

  const loadPreviousConversation = async () => {
    const { conversations, hasNextPage } = await getConversations(
      chatRoomId,
      currentPage + 1
    );
    setIsDisabled(!hasNextPage);
    setConversations((prev) => [...prev, ...conversations]);
    if (hasNextPage) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <>
      <Title title="Chat" />
      {!isDisabled && (
        <Button onClick={loadPreviousConversation}>
          PREVIOUS CONVERSATION
        </Button>
      )}
      <List className={classes.root}>
        {conversations
          ?.map(
            (
              conversation: AdminChat['getChatRoomConversation']['response']['conversations'][0],
              index: number
            ) => {
              const chatRoomUser = chatRoomUsers.find(
                (user) => user.id === conversation.createdById
              ) ?? {
                id: 0,
                userType: UserType.Student,
                firstName: '',
                lastName: '',
                photoUrl: '',
              };

              if (isConversationCourse(conversation)) {
                return (
                  <ChatMessageCourse
                    key={index}
                    createdByUser={chatRoomUser}
                    date={conversation.date}
                    courseDate={conversation.courseDate}
                    subjectName={conversation.subjectName}
                    status={conversation.status}
                  />
                );
              }
              if (isConversationMessageDmr(conversation)) {
                return (
                  <ChatMessageDmr
                    key={index}
                    createdByUser={chatRoomUser}
                    date={conversation.date}
                    message={conversation.message}
                    status={conversation.status}
                  />
                );
              }
              if (isConversationDocument(conversation)) {
                return (
                  <ChatMessageDocument
                    key={index}
                    createdByUser={chatRoomUser}
                    date={conversation.date}
                    originalName={conversation.originalName}
                    url={conversation.url}
                  />
                );
              }
              if (isConversationMessage(conversation)) {
                return (
                  <ChatMessageUser
                    key={index}
                    createdByUser={chatRoomUser}
                    date={conversation.date}
                    message={conversation.message}
                  />
                );
              }
              return <div key={index} />;
            }
          )
          .reverse()}
      </List>
    </>
  );
}

export default Chat;
