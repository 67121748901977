import React from 'react';
import {
  DateInput,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  minValue,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  formatInParis,
  getPriceFromCentimes,
  ProductType,
  SubscriptionApi,
} from '@les-sherpas/sherpas-toolbox';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { hoursToMinutes, minutesToHours } from 'date-fns';

import SubscriptionStatusChip from '../shared/SubscriptionStatus/SubscriptionStatus';
import PriceCalculatorPanel from './components/PriceCalculatorPanel/PriceCalculatorPanel';
import SubscriptionExtensionDays from './components/SubscriptionExtensionDays/SubscriptionExtensionDays';

import useStyles from './styles';

const transformData = (
  formData: SubscriptionApi['admin']['getSubscription']['response']
) => {
  const adminUpdateSubscriptionRequest: SubscriptionApi['admin']['updateSubscription']['request'] =
    {
      id: formData.id,
      newDuration: formData.duration,
      newVolume: formData.volume,
      newCommissionRate: formData.commissionRate,
      newQuantityDiscountRate: formData.quantityDiscountRate,
      newManagementCostRate: formData.managementCostRate,
      newEducationalCostRate: formData.educationalCostRate,
      newExtensionDays: formData.extensionDays,
      newRemainingMinutes: formData.remainingMinutes,
    };

  return adminUpdateSubscriptionRequest;
};

const getMinimalDurationByProductType = (productType: ProductType) => {
  switch (productType) {
    case ProductType.RecurringTraining:
      return 2;
    case ProductType.IntensiveTraining:
    default:
      return 1;
  }
};

function EditToolbar(props: ToolbarProps) {
  const { updatedByAdminAt, updatedByAdminName } =
    useRecordContext<SubscriptionApi['admin']['getSubscription']['response']>();

  return (
    <Toolbar {...props}>
      <SaveButton label="Modifier" />
      {updatedByAdminAt && (
        <Typography variant="body1">
          Modifié pour la dernière fois par {updatedByAdminName} le
          {formatInParis(new Date(updatedByAdminAt), 'dd/MM/yyyy à HH:mm')}
        </Typography>
      )}
    </Toolbar>
  );
}

function SubscriptionEdit() {
  const { classes } = useStyles();
  const { id } = useParams();

  const { data, isLoading } = useGetOne<
    SubscriptionApi['admin']['getSubscription']['response']
  >('subscription', {
    id: parseInt(id ?? '0', 10),
  });

  if (!data || isLoading) {
    return <p>Loading</p>;
  }

  return (
    <Edit redirect="false" mutationMode="pessimistic" transform={transformData}>
      <SimpleForm
        toolbar={<EditToolbar className={classes.toolbar} />}
        className={classes.form}
      >
        <FormDataConsumer<
          SubscriptionApi['admin']['getSubscription']['response']
        >>
          {({ formData }) => (
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.header}>
                <Typography>
                  Abonnement stripe n°: <i>{data.subscriptionId}</i>
                </Typography>
                <SubscriptionStatusChip source="status" label="status" />
              </Grid>

              <Grid container item xs={6} spacing={1}>
                <Grid item xs={12} className={classes.header}>
                  <TextField
                    label="Type d'abonnement"
                    value={data.productType}
                    fullWidth
                    inputProps={{ readOnly: true }}
                  />
                </Grid>

                {data.student.id !== data.mainUser.id ? (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        label="Parent"
                        value={data.mainUser.fullName}
                        fullWidth
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Étudiant"
                        value={data.student.fullName}
                        fullWidth
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Professeur"
                        value={data.teacher.fullName}
                        fullWidth
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        label="Étudiant"
                        value={data.student.fullName}
                        fullWidth
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Professeur"
                        value={data.teacher.fullName}
                        fullWidth
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={4}>
                  <DateTimeInput
                    label="Date de création"
                    source="createdDate"
                    fullWidth
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateInput
                    label="Date de fin"
                    source="endDate"
                    fullWidth
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Lieu du cours"
                    value={data.location}
                    fullWidth
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={data.stripeAccountSource}
                    label="Compte Stripe"
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Taux Horaires (hourlyRawPrice)"
                    value={getPriceFromCentimes(data.hourlyRawPrice)}
                    variant="filled"
                    fullWidth
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Frais de déplacement (hourlyRawTravelFee)"
                    value={getPriceFromCentimes(data.hourlyRawTravelFee)}
                    variant="filled"
                    fullWidth
                    inputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <NumberInput
                    label="Durée (Nombre de mois)"
                    source="duration"
                    validate={[required(), minValue(1)]}
                    min={getMinimalDurationByProductType(data.productType)}
                    variant={
                      formData.productType === ProductType.IntensiveTraining
                        ? 'filled'
                        : 'outlined'
                    }
                    fullWidth
                    helperText={`Valeur initiale: ${data.duration}`}
                    inputProps={{
                      readOnly:
                        data.productType === ProductType.IntensiveTraining,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInput
                    label="Volume (Nombre d'heures)"
                    source="volume"
                    validate={[required(), minValue(1)]}
                    min={1}
                    variant="outlined"
                    format={(volumeMinutes) => minutesToHours(volumeMinutes)}
                    parse={(volumeHours) => hoursToMinutes(volumeHours)}
                    fullWidth
                    helperText={`Valeur initiale: ${minutesToHours(
                      data.volume
                    )}`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <NumberInput
                    label="Pourcentage commission Sherpas (commissionRate)"
                    source="commissionRate"
                    validate={[required(), minValue(0)]}
                    min={0}
                    variant="outlined"
                    fullWidth
                    helperText={`Valeur initiale: ${data.commissionRate}`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <NumberInput
                    label="Pourcentage de réduction (quantityDiscountRate)"
                    source="quantityDiscountRate"
                    validate={[required(), minValue(0)]}
                    min={0}
                    variant="outlined"
                    fullWidth
                    helperText={`Valeur initiale: ${data.quantityDiscountRate}`}
                  />
                </Grid>

                <Grid item xs={6}>
                  <NumberInput
                    label="Pourcentage Frais de gestion (managementCostRate)"
                    source="managementCostRate"
                    validate={[required(), minValue(0)]}
                    min={0}
                    variant={
                      formData.productType !== ProductType.IntensiveTraining
                        ? 'filled'
                        : 'outlined'
                    }
                    fullWidth
                    inputProps={{
                      readOnly:
                        formData.productType !== ProductType.IntensiveTraining,
                    }}
                    helperText={`Valeur initiale: ${data.managementCostRate}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInput
                    label="Pourcentage Frais pédagogiques (educationalCostRate)"
                    source="educationalCostRate"
                    validate={[required(), minValue(0)]}
                    min={0}
                    variant={
                      formData.productType !== ProductType.RecurringTraining
                        ? 'filled'
                        : 'outlined'
                    }
                    fullWidth
                    inputProps={{
                      readOnly:
                        formData.productType !== ProductType.RecurringTraining,
                    }}
                    helperText={`Valeur initiale: ${data.educationalCostRate}`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <NumberInput
                    label="Temps restant (remainingMinutes)"
                    source="remainingMinutes"
                    validate={[required(), minValue(0)]}
                    min={0}
                    variant="outlined"
                    fullWidth
                    helperText={`Valeur initiale: ${data.remainingMinutes}`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SubscriptionExtensionDays
                    cancelationDate={formData.cancelationDate}
                    defaultExtensionDays={formData.extensionDays}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={6}>
                <Grid item xs={12}>
                  <PriceCalculatorPanel
                    title="Simulation des changements"
                    productType={data.productType}
                    location={data.location}
                    duration={data.duration}
                    volume={data.volume}
                    hourlyRawPrice={data.hourlyRawPrice}
                    hourlyRawTravelFee={data.hourlyRawTravelFee}
                    commissionRate={data.commissionRate}
                    quantityDiscountRate={data.quantityDiscountRate}
                    managementCostRate={data.managementCostRate}
                    educationalCostRate={data.educationalCostRate}
                    newDuration={formData.duration}
                    newVolume={formData.volume}
                    newCommissionRate={formData.commissionRate}
                    newQuantityDiscountRate={formData.quantityDiscountRate}
                    newManagementCostRate={formData.managementCostRate}
                    newEducationalCostRate={formData.educationalCostRate}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}

export default SubscriptionEdit;
