import LanguageIcon from '@mui/icons-material/Language';

import SeoEdit from './seo-edit';
import SeoList from './seo-list';

export default {
  list: SeoList,
  edit: SeoEdit,
  icon: LanguageIcon,
  options: { label: 'SEO' },
};
