import { ChatBubbleRounded } from '@mui/icons-material';

import ChatRoomEdit from './chat-room-edit';
import ChatRoom from './chat-room-list';

export default {
  list: ChatRoom,
  edit: ChatRoomEdit,
  icon: ChatBubbleRounded,
};
