import { LegalDocumentApi } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';

import { LoadFileParams } from '../config/types';

const BASE_URL = process.env.REACT_APP_API_URL ?? '';

export async function uploadFile({
  data,
  uri,
}: LoadFileParams): Promise<
  LegalDocumentApi['admin']['uploadFile']['response']
> {
  const formData = new FormData();
  if (!data.attachments) {
    return;
  }
  const file = data.attachments;
  if (file.rawFile) formData.append('file', file.rawFile);
  if (file.title) formData.append('title', file.title);
  if (file.content) formData.append('content', file.content);
  await axios.post<string>(
    `${BASE_URL}/legal-document/admin/${uri}`,
    formData,
    {
      withCredentials: true,
      validateStatus: (status) => [200, 201].includes(status),
    }
  );
}

export async function getUploadedFiles({
  uri,
}: {
  uri: 'uploaded-terms-and-conditions-pdf';
}): Promise<LegalDocumentApi['admin']['getFile']['response']> {
  const { data } = await axios.get<
    LegalDocumentApi['admin']['getFile']['response']
  >(`${BASE_URL}/legal-document/admin/${uri}`, {
    withCredentials: true,
    validateStatus: (status) => [200].includes(status),
  });
  return data;
}
