import { Edit } from 'react-admin';

import CreateEditForm from './CreateEditForm/CreateEditForm';

function TVCampaignEdit() {
  return (
    <Edit mutationMode="pessimistic">
      <CreateEditForm />
    </Edit>
  );
}

export default TVCampaignEdit;
