import { List, WithListContext } from 'react-admin';
import { Resource } from '@les-sherpas/sherpas-toolbox';

import PromoResourceBanner from './promo-banner/promo-banner';
import ResourcesTrends from './trends/trends';

function ResourcesList() {
  return (
    <List resource="resources" perPage={50} exporter={false}>
      <WithListContext
        render={({ data }) => {
          if (data) {
            const { promoBanner, trends } = {
              promoBanner: data.filter(
                ({ contentType }) => contentType === 'promo-banner'
              ) as Resource[],
              trends: data.filter(
                ({ contentType }) => contentType === 'trend'
              ) as Resource[],
            };

            return (
              <>
                <PromoResourceBanner
                  title="Ressources Banner"
                  data={promoBanner}
                />
                <ResourcesTrends data={trends} title="Tendances 🔥" />
              </>
            );
          }
          return null;
        }}
      />
    </List>
  );
}

export default ResourcesList;
