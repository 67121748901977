import React, { useEffect, useState } from 'react';
import { DownloadApi, UserType } from '@les-sherpas/sherpas-toolbox';
import { Chip, Divider, Link, ListItem, Typography } from '@mui/material';
import { amber } from '@mui/material/colors';
import axios from 'axios';
import { makeStyles } from 'tss-react/mui';

import AvatarMessage from '../avatar/avatar';
import ChatDate from '../chat-date/chat-date';

const useStyles = makeStyles()((theme) => ({
  amber: {
    color: theme.palette.getContrastText(amber[500]),
    backgroundColor: amber[500],
    marginRight: '8px',
  },
}));

const generatePresignedUrl = async (
  query: DownloadApi['admin']['getDownloadUrlForDocument']['request']['query']
): Promise<DownloadApi['admin']['getDownloadUrlForDocument']['response']> => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/download/private?url=${query.url}`,
    {
      withCredentials: true,
      validateStatus: (status) => status === 200,
    }
  );

  return data;
};

function ChatMessageDocument({
  createdByUser,
  date,
  originalName,
  url,
}: {
  createdByUser: {
    id: number;
    userType: UserType;
    firstName: string;
    lastName: string;
    photoUrl: string | null;
  };
  date: Date;
  originalName: string;
  url: string;
}) {
  const { classes } = useStyles();
  const [downloadURl, setDownloadUrl] = useState<string>('');
  useEffect(() => {
    (async () => {
      const presignedUrl = (await generatePresignedUrl({ url })).url;

      setDownloadUrl(presignedUrl);
    })();
  }, []);
  return (
    <>
      <ChatDate date={new Date(date)} />
      <ListItem>
        <AvatarMessage
          userId={createdByUser.id}
          userType={createdByUser.userType}
          firstName={createdByUser.firstName}
          lastName={createdByUser.lastName}
          photoUrl={createdByUser.photoUrl}
        />
        <Chip className={classes.amber} label="Document" />
        <Link href={downloadURl} onClick={(e) => e.preventDefault} download>
          <Typography>{originalName}</Typography>
        </Link>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}

export default ChatMessageDocument;
