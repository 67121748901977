import React from 'react';
import {
  DateTimeInput,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';

import { DISABLED_FIELDS, ORDERED_EDITABLE_FIELDS } from './constants';

function SeoEdit() {
  return (
    <Edit redirect="false">
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton label="Enregistre tes modifications" />
          </Toolbar>
        }
      >
        {ORDERED_EDITABLE_FIELDS.map((field, i) =>
          ['updatedAt', 'createdAt'].includes(field) ? (
            <DateTimeInput
              key={`${field}-dateTime-input-${i}`}
              source={field}
              fullWidth
              disabled
            />
          ) : (
            <TextInput
              key={`${field}-input-${i}`}
              source={field}
              fullWidth
              multiline
              variant="outlined"
              disabled={DISABLED_FIELDS.includes(field)}
            />
          )
        )}
      </SimpleForm>
    </Edit>
  );
}

export default SeoEdit;
