import React from 'react';
import {
  BooleanInput,
  ChipField,
  DeleteWithConfirmButton,
  Edit,
  FormDataConsumer,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetOne,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { ChatRoomApi } from '@les-sherpas/sherpas-toolbox';
import { Lock } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  block: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: '6px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function ChatRoomEdit() {
  const { classes } = useStyles();

  const { id } = useParams();
  const { data, isLoading } = useGetOne<
    ChatRoomApi['admin']['getChatRoom']['response']
  >('chat-room', {
    id: parseInt(id ?? '0', 10),
  });

  if (!data || isLoading) {
    return <p>Loading</p>;
  }

  const hasPossibleStudents =
    data && data.possibleStudents && data.possibleStudents.length > 1;

  const hasMainUserAsStudent = data && !data.parentFullName;

  const canEditStudent = hasPossibleStudents && hasMainUserAsStudent;

  let canEditTeacherHelperText = '';
  if (!canEditStudent) {
    if (!hasPossibleStudents) {
      canEditTeacherHelperText = 'No child user linked to main user';
    } else if (!hasMainUserAsStudent) {
      canEditTeacherHelperText = 'ChatRoom already attached to a child';
    }
  }

  return (
    <Edit
      mutationMode="pessimistic"
      transform={({
        hasIncidentNotResolved,
        studentId,
      }: ChatRoomApi['admin']['getChatRoom']['response']) =>
        ({
          hasIncidentNotResolved,
          studentId,
        }) as ChatRoomApi['admin']['updateChatRoom']['request']['body']
      }
    >
      <SimpleForm
        toolbar={
          <FormDataConsumer>
            {({ formData: { disallowArchiveChatroom } }) => (
              <Toolbar className={classes.toolbar}>
                <SaveButton />
                <DeleteWithConfirmButton
                  disabled={disallowArchiveChatroom}
                  confirmTitle="Supprimer la chatroom"
                  confirmContent="Êtes-vous sûr(e) de vouloir supprimer cette chatroom ?"
                />
              </Toolbar>
            )}
          </FormDataConsumer>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextInput
              label="Id"
              source="id"
              isRequired
              disabled
              variant="outlined"
              inputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} textAlign="end">
            <Typography variant="body1" component="span">
              Status:
            </Typography>
            <ChipField disabled source="status" color="primary" />
          </Grid>
        </Grid>
        {data.parentFullName ? (
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextInput
                label="Parent / MainUser"
                source="parentFullName"
                isRequired
                disabled
                variant="outlined"
                inputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextInput
                label="MainUser"
                source="studentFullName"
                isRequired
                disabled
                variant="outlined"
                inputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <SelectInput
              source="studentId"
              key="studentId"
              variant="outlined"
              isRequired
              choices={data?.possibleStudents ?? []}
              optionText="fullName"
              fullWidth
              inputProps={{
                readOnly: !canEditStudent,
                disabled: !canEditStudent,
              }}
              helperText={canEditTeacherHelperText}
            />
          </Grid>
          <Grid item xs={4} textAlign="end">
            <TextInput
              label="Teacher"
              source="teacherFullName"
              isRequired
              disabled
              variant="outlined"
              inputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <BooleanInput
              label="locked 🔒️"
              source="hasIncidentNotResolved"
              options={{ checkedIcon: <Lock /> }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
}

export default ChatRoomEdit;
