import { BooleanInput, SimpleForm, TextInput } from 'react-admin';

import ActionBar from './ActionBar/ActionBar';
import Row from './Row/Row';

import useStyles from './styles';

function CreateEditForm() {
  const { classes } = useStyles();

  return (
    <SimpleForm toolbar={<ActionBar />}>
      <Row className={classes.spaceBetween}>
        <TextInput source="campaign" isRequired label="Nom de campagne" />
        <BooleanInput source="isActive" isRequired label="Actif ?" />
      </Row>
      <Row>
        <TextInput
          source="description"
          isRequired
          label="Texte Marketing"
          className={classes.grow}
        />
      </Row>
      <Row>
        <TextInput source="buttonLabel" isRequired label="Texte du CTA" />
      </Row>
    </SimpleForm>
  );
}

export default CreateEditForm;
