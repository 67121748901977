import { FC } from 'react';
import {
  BooleanField,
  ChipField,
  Datagrid,
  EditButton,
  ImageField,
  NumberField,
  TextField,
} from 'react-admin';
import { Resource } from '@les-sherpas/sherpas-toolbox';

type Props<T> = {
  title: string;
  data: T[];
  fields: {
    type: string;
    source: string;
    label: string;
  }[];
};

const GenericResourcesContent: FC<Props<Resource>> =
  function genericResourcesContent({ data, title, fields }) {
    return (
      <div style={{ padding: '15px' }}>
        <h1>{title}</h1>
        <Datagrid bulkActionButtons={false} data={data} rowClick="edit">
          {fields.map((field) => {
            const { type, source, label } = field;

            switch (type) {
              case 'text':
                return (
                  <TextField sortable={false} source={source} label={label} />
                );
              case 'number':
                return (
                  <NumberField sortable={false} source={source} label={label} />
                );
              case 'image':
                return (
                  <ImageField sortable={false} source={source} label={label} />
                );
              case 'boolean':
                return (
                  <BooleanField
                    sortable={false}
                    source={source}
                    label={label}
                  />
                );
              case 'tag':
                return (
                  <ChipField
                    emptyText={String('\u0020')}
                    sortable={false}
                    source={source}
                    label={label}
                  />
                );
              default:
                return null;
            }
          })}
          <EditButton />
        </Datagrid>
      </div>
    );
  };

export default GenericResourcesContent;
