import { Announcement } from '@mui/icons-material';

import AnnounceEdit from './announce-edit';
import AnnounceList from './announce-list';

export default {
  list: AnnounceList,
  icon: Announcement,
  edit: AnnounceEdit,
};
