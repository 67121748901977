import { Button, NumberField } from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

type Props = {
  isOpen: boolean;
  baseValue: number;
  errorState: string;
  isLoading: boolean;
  handleClosePayoutModal: () => void;
  handlePayTeacher: () => void;
  setBaseValue: (_value: number) => void;
};

function MoneyTransferModal({
  isOpen,
  baseValue,
  errorState,
  isLoading,
  handleClosePayoutModal,
  handlePayTeacher,
  setBaseValue,
}: Props) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClosePayoutModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Combien souhaites-tu virer à ce professeur ?
        </DialogContentText>
        <NumberField
          margin="dense"
          id="montant"
          label="Montant à virer"
          fullWidth
        />
        <input
          type="number"
          value={baseValue}
          onChange={(e) => {
            setBaseValue(parseFloat(e.target.value));
          }}
        />
        <Typography color="error">{errorState}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClosePayoutModal}
          color="primary"
          label="Cancel"
        />
        <Button
          onClick={() => {
            handlePayTeacher();
          }}
          color="primary"
          label="Pay"
          disabled={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
}

export default MoneyTransferModal;
