import React from 'react';
import {
  BooleanInput,
  ChipField,
  DeleteWithConfirmButton,
  Edit,
  FormDataConsumer,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetOne,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { ChatRoomApi, IChatRoomAdmin } from '@les-sherpas/sherpas-toolbox';
import { Lock } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  block: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: '6px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function ChatRoomEdit() {
  const { classes } = useStyles();

  const { id } = useParams();
  const { data, isLoading } = useGetOne<IChatRoomAdmin>('chat-room', {
    id: parseInt(id ?? '0', 10),
  });

  if (!data || isLoading) {
    return <p>Loading</p>;
  }

  return (
    <Edit
      transform={(
        /* TODO 
        - transform IChatRoomAdmin to AdminChatRoom REQUEST
        - kill old type IChatRoomAdmin & EditChatRoomDto
        */
        {
          id,
          studentFullName,
          parentFullName,
          teacherFullName,
          status,
          hasIncidentNotResolved,
          disallowArchiveChatroom,
          canSuggestSubscription,
          teacherId,
        }: ChatRoomApi['admin']['getChatRoom']['response']
      ) =>
        ({
          id,
          studentFullName,
          parentFullName,
          teacherFullName,
          status,
          hasIncidentNotResolved,
          disallowArchiveChatroom,
          canSuggestSubscription,
          teacherId,
        }) as IChatRoomAdmin
      }
    >
      <SimpleForm
        toolbar={
          <FormDataConsumer>
            {({ formData: { disallowArchiveChatroom } }) => (
              <Toolbar className={classes.toolbar}>
                <SaveButton />
                <DeleteWithConfirmButton
                  disabled={disallowArchiveChatroom}
                  confirmTitle="Supprimer la chatroom"
                  confirmContent="Êtes-vous sûr(e) de vouloir supprimer cette chatroom ?"
                />
              </Toolbar>
            )}
          </FormDataConsumer>
        }
      >
        <div className={classes.block}>
          <TextInput disabled label="Id" source="id" />
          {!!data?.parentFullName && (
            <TextInput disabled source="parentFullName" />
          )}
          <TextInput disabled source="studentFullName" />
          <TextInput disabled source="teacherFullName" />
          <div>
            <Typography variant="body1" component="span">
              Status:
            </Typography>
            <ChipField disabled source="status" color="primary" />
          </div>
        </div>
        <BooleanInput
          label="locked 🔒️"
          source="hasIncidentNotResolved"
          options={{ checkedIcon: <Lock /> }}
        />
      </SimpleForm>
    </Edit>
  );
}

export default ChatRoomEdit;
