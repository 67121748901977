import { FC } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { IAddress } from '@les-sherpas/sherpas-toolbox';

import useStyles from './styles';

type Props = {
  setAddress: Function;
  address: string | IAddress;
  isFullWidth?: boolean;
};

const AddressAutoComplete: FC<Props> = function addressAutoComplete({
  setAddress,
  address,
  isFullWidth = false,
}) {
  const { classes } = useStyles({ isFullWidth });
  return (
    <div className={classes.container}>
      <h1>Information d&apos;adresse</h1>
      <Autocomplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        className={classes.autoComplete}
        onPlaceSelected={(place) =>
          setAddress({
            formattedAddress: place.formatted_address,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            streetNumber: place.address_components[0].long_name,
            streetName: place.address_components[1].long_name,
            city: place.address_components[2].long_name,
            zipCode: place.address_components[6].long_name,
          })
        }
        options={{
          types: ['address'],
          componentRestrictions: { country: 'fr' },
        }}
        defaultValue={
          typeof address === 'string' ? address : address?.formattedAddress
        }
      />
    </div>
  );
};

export default AddressAutoComplete;
