import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    padding: '16px',
  },
  containerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: '16px',
  },
  actualTeacher: {
    marginBottom: '16px',
  },
  radioTitle: {
    display: 'flex',
    flexFlow: 'column',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '16px',
  },
  radioContainer: {
    width: '100%',
    marginLeft: '16px',
    padding: '16px',
    border: '1px solid #ccc',
    borderRadius: '8px',
  },
  chip: {
    marginTop: '8px',
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
  },
  fullWidth: {
    width: '100%',
  },
  levelSubjectContainer: {
    marginTop: '16px',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  form: {
    flex: 1,
  },
  swap: {
    marginTop: '16px',
    padding: '8px',
    display: 'flex',
    width: '100%',
  },
}));
