import * as React from 'react';
import {
  CreateBase,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Title,
} from 'react-admin';
import { Card } from '@mui/material';

function TagCreate() {
  const [userType, setUserType] = React.useState('');

  return (
    <CreateBase>
      <div>
        <Title title="Creation du Tag" />
        <Card>
          <SimpleForm>
            <TextInput source="name" />
            <SelectInput
              source="userType"
              label="Type"
              choices={[
                { id: 'teacher', name: 'teacher' },
                { id: 'student', name: 'student' },
              ]}
              onChange={(e) => setUserType(e.target.value)}
            />
            <NumberInput
              source="score"
              label="Search score"
              min={-200}
              max={200}
              step={1}
              defaultValue={0}
              fullWidth
              disabled={userType === 'student'}
            />
          </SimpleForm>
        </Card>
      </div>
    </CreateBase>
  );
}

export default TagCreate;
