import {
  Edit,
  FormDataConsumer,
  SaveButton,
  SimpleForm,
  Toolbar,
} from 'react-admin';
import { Grid } from '@mui/material';

import ResourceHeaderInfo from './edit-header-info/resource-header-info';
import EditPromoBanner from './edit-promo-banner/edit-promo-banner';
import EditTrend from './edit-trend/edit-trend';

function ResourcesContentEdit() {
  return (
    <Edit>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton label="éditer" />
          </Toolbar>
        }
      >
        <Grid container alignContent="center" justifyContent="center">
          <ResourceHeaderInfo
            idSource="id"
            isActiveSource="isActive"
            contentTypeSource="contentType"
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.contentType === 'promo-banner' ? (
                <EditPromoBanner currentImgLink={formData.imageUrl} />
              ) : (
                <EditTrend
                  currentIconTag={formData.tagIcon}
                  currentImgLink={formData.imageUrl}
                />
              )
            }
          </FormDataConsumer>
        </Grid>
      </SimpleForm>
    </Edit>
  );
}

export default ResourcesContentEdit;
