import React from 'react';
import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  comment: {
    width: '100%',
  },
}));

function ReviewEdit() {
  const { classes } = useStyles();
  return (
    <Edit>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="comment" multiline className={classes.comment} />
        <NumberInput source="rating" min={1} max={5} step={1} />
      </SimpleForm>
    </Edit>
  );
}

export default ReviewEdit;
