import React from 'react';
import { UserType } from '@les-sherpas/sherpas-toolbox';
import { Chip, Divider, ListItem, ListItemText } from '@mui/material';
import { teal } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

import AvatarMessage from '../avatar/avatar';
import ChatDate from '../chat-date/chat-date';

const useStyles = makeStyles()((theme) => ({
  teal: {
    color: theme.palette.getContrastText(teal[500]),
    backgroundColor: teal[500],
    marginRight: '8px',
  },
}));

function ChatMessageUser({
  createdByUser,
  date,
  message,
}: {
  createdByUser: {
    id: number;
    userType: UserType;
    firstName: string;
    lastName: string;
    photoUrl: string | null;
  };
  date: Date;
  message: string;
}) {
  const { classes } = useStyles();
  return (
    <>
      <ChatDate date={new Date(date)} />
      <ListItem>
        <AvatarMessage
          userId={createdByUser.id}
          userType={createdByUser.userType}
          firstName={createdByUser.firstName}
          lastName={createdByUser.lastName}
          photoUrl={createdByUser.photoUrl}
        />
        <ListItemText>
          <Chip className={classes.teal} label="Message" />
          {message}
        </ListItemText>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}

export default ChatMessageUser;
