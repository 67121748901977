import React from 'react';
import {
  CourseStatus,
  CourseStatusType,
  UserType,
} from '@les-sherpas/sherpas-toolbox';
import { Chip, Divider, ListItem, ListItemText } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import { format } from 'date-fns';
import { makeStyles } from 'tss-react/mui';

import AvatarMessage from '../avatar/avatar';
import ChatDate from '../chat-date/chat-date';

const useStyles = makeStyles()((theme) => ({
  badges: {
    display: 'flex',
    gap: '8px',
  },
  lightGreen: {
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
  },
}));

function ChatMessageCourse({
  createdByUser,
  date,
  courseDate,
  subjectName,
  status,
}: {
  createdByUser: {
    id: number;
    userType: UserType;
    firstName: string;
    lastName: string;
    photoUrl: string | null;
  };
  date: Date;
  courseDate: Date;
  subjectName: string;
  status: CourseStatusType;
}) {
  const { classes } = useStyles();
  return (
    <>
      <ChatDate date={new Date(date)} />
      <ListItem>
        <AvatarMessage
          userId={createdByUser.id}
          userType={createdByUser.userType}
          firstName={createdByUser.firstName}
          lastName={createdByUser.lastName}
          photoUrl={createdByUser.photoUrl}
        />
        <ListItemText>
          <div className={classes.badges}>
            <Chip
              label={createdByUser.id === 0 ? 'Rappel de cours' : 'Cours'}
              className={classes.lightGreen}
            />
            <Chip label={format(new Date(courseDate), 'dd/LL/y - HH:mm')} />
          </div>
        </ListItemText>
        <ListItemText>sujet du cours: {subjectName}</ListItemText>
        <Chip
          label={status}
          color={status === CourseStatus.PENDING ? 'secondary' : 'default'}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}

export default ChatMessageCourse;
