import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    padding: 24,
    border: `1px solid #E4E4E4`,
    borderRadius: 16,
    margin: 16,
    gap: 16,
    flex: 1,
  },
  table: {
    margin: 'auto',
    marginTop: '20px',
    borderRadius: 16,
    '& th , td ': {
      textAlign: 'center',
    },
  },
  activeRow: {
    backgroundColor: '#ffffff',
  },
  inactiveRow: {
    backgroundColor: '#f0f0f0',
    color: '#b0b0b0',
  },
  createdByCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));
