import { GenerateSubscriptionProposalFromAdminDto } from '@les-sherpas/sherpas-toolbox';
import axios, { AxiosResponse } from 'axios';

const generateSubscriptionProposal = async (
  params: GenerateSubscriptionProposalFromAdminDto
): Promise<AxiosResponse<boolean>> =>
  axios.post<boolean>(
    `${process.env.REACT_APP_API_URL}/subscription/admin/subscription-generator`,
    params,
    { withCredentials: true, validateStatus: (status) => status === 201 }
  );

export default generateSubscriptionProposal;
