import { useEffect, useState } from 'react';
import { useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  AnnounceAdminFromTeacher,
  ChatRoomApi,
  ProductType,
} from '@les-sherpas/sherpas-toolbox';
import { Alert, Button, Divider, Typography } from '@mui/material';
import { AxiosError } from 'axios';

import useSubscriptionOptionsForm from '../subscription-options-form/hook';
import SubscriptionProposalOptionsForm from '../subscription-options-form/SubscriptionProposalOptionsForm';
import { getAnnounceFromTeacher } from '../swap-chat-room/swap-chat-room.utils';
import ProductsTabs from './Products/ProductsTabs';
import ProposalsHistory from './ProposalHistory/ProposalHistory';
import SummaryPanel from './SummaryPanel';
import generateSubscriptionProposal from './utils';

import useStyles from './styles';

function GenerateSubscription() {
  const { classes } = useStyles();
  const { id } = useParams();

  const [announces, setAnnounces] = useState<AnnounceAdminFromTeacher[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>();

  const subscriptionOptionsForm = useSubscriptionOptionsForm({
    announces,
  });

  const {
    announce,
    hourlyRawPrice,
    hourlyRawTravelFee,
    duration,
    volume,
    subject,
    level,
    location,
    product,
    commissionRate,
    managementCostRate,
    educationalCostRate,
    calculatedEducationalCostRate,
    setCalculatedEducationalCostRate,
    setProduct,
    productType,
    expirationDuration,
  } = subscriptionOptionsForm;
  const { data, isLoading, isLoadingError, refetch } = useGetOne<
    ChatRoomApi['admin']['getChatRoom']['response']
  >('chat-room', {
    id: parseInt(id ?? '0', 10),
  });

  const createSubscriptionProposal = async () => {
    if (!duration || !volume || !location || !subject || !level) {
      // eslint-disable-next-line no-alert
      alert('Veuillez remplir tous les champs');
      return;
    }
    if (duration !== 1 && productType === ProductType.IntensiveTraining) {
      // eslint-disable-next-line no-alert
      alert("La durée d'un stage intensif doit être de 1 mois.");
      return;
    }
    if (duration < 2 && productType === ProductType.RecurringTraining) {
      // eslint-disable-next-line no-alert
      alert("La durée d'un suivi régulier doit être de 2 mois minimum.");
      return;
    }
    if (volume < 1) {
      // eslint-disable-next-line no-alert
      alert("Le volume d'heures proposé ne peut pas être inférieur à 1.");
      return;
    }
    if (
      hourlyRawPrice < 0 ||
      hourlyRawTravelFee < 0 ||
      duration < 0 ||
      volume < 0 ||
      commissionRate < 0 ||
      managementCostRate < 0 ||
      educationalCostRate < 0 ||
      expirationDuration < 0
    ) {
      // eslint-disable-next-line no-alert
      alert(
        "Vous ne pouvez pas mettre de valeur négatives dans les propriétés de l'abonnement."
      );
      return;
    }
    try {
      const { data } = await generateSubscriptionProposal({
        productType: product.productType,
        announceId: announce?.announceId ?? 0,
        chatRoomId: parseInt(id ?? '0', 10),
        duration,
        hourlyRawPrice,
        levelName: level?.name ?? '',
        location,
        subjectName: subject?.name ?? '',
        hourlyRawTravelFee,
        volume,
        commissionRate,
        managementCostRate,
        expirationDuration,
        quantityDiscountRate: 0,
        educationalCostRate: calculatedEducationalCostRate,
      });
      setIsSuccess(data);
      // eslint-disable-next-line no-alert
      alert(
        `La proposition d'${product.label} a bien été envoyé sur cette Chatroom`
      );
      refetch();
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(e instanceof AxiosError ? e.response?.data?.message : e);
    }
  };

  useEffect(() => {
    (async () => {
      if (!isLoading && data?.teacherId)
        setAnnounces(
          await getAnnounceFromTeacher({
            id: data.teacherId,
            fullName: data.teacherFullName,
          })
        );
    })();
  }, [data, isLoading]);

  if (isLoading || !data) {
    return <span>Chargement...</span>;
  }

  return (
    <div>
      <Typography variant="h3">
        Générer {product.label} pour la chatroom: {id}
      </Typography>
      <Divider />
      {!data.canSuggestSubscription && (
        <Alert severity="warning">
          Dans le cas où le button est grisé
          <ol>
            <li> La ChatRoom possède {product.label} actif; ou</li>
            <li> La ChatRoom est fermée.</li>
          </ol>
        </Alert>
      )}
      {announces?.length === 0 && !isLoading && (
        <Typography>0 annonce trouvée pour ce prof</Typography>
      )}
      {isLoadingError && <Typography>Une erreur est survenu.</Typography>}
      <div className={classes.panelContainer}>
        <SummaryPanel
          announce={announce}
          duration={duration}
          level={level?.name}
          location={location}
          studentFullName={data?.studentFullName}
          parentFullName={data?.parentFullName}
          teacherFullName={data?.teacherFullName}
          subject={subject?.name}
          volume={volume}
          hourlyRawPrice={hourlyRawPrice}
          hourlyRawTravelFee={hourlyRawTravelFee}
          commissionRate={commissionRate}
          managementCostRate={managementCostRate}
          educationalCostRate={educationalCostRate}
          setCalculatedEducationalCostRate={setCalculatedEducationalCostRate}
          productType={productType}
          expirationDuration={expirationDuration}
        />
        <div className={classes.selectorContainers}>
          <ProductsTabs product={product} handleChange={setProduct} />
          <SubscriptionProposalOptionsForm
            announces={announces}
            handleAnnounceChange={() => {}}
            {...subscriptionOptionsForm}
          />
          {isSuccess && (
            <Alert severity="success">
              La Proposition {product.label} a bien été envoyée sur la Chatroom
              N°
              {id}
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={createSubscriptionProposal}
            disabled={!data.canSuggestSubscription}
          >
            {`Envoyer la proposition ${product.label}`}
          </Button>
        </div>
      </div>
      {data.subscriptionProposals?.length > 0 && (
        <ProposalsHistory proposals={data.subscriptionProposals} />
      )}
    </div>
  );
}

export default GenerateSubscription;
