import { PayoutApi } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ?? '';

const payoutSherpasDomTeacher = async ({
  teacherId,
  amountToPay,
  subscriptionId,
  periodMonth,
}: PayoutApi['admin']['manuelPayout']['request']): Promise<
  PayoutApi['admin']['manuelPayout']['response']
> => {
  try {
    const result = await axios.post(
      `${BASE_URL}/payout/admin`,
      {
        teacherId,
        amountToPay,
        subscriptionId,
        periodMonth,
      },
      {
        withCredentials: true,
        validateStatus: (status) => status === 201,
      }
    );

    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    }
  }
  return 'error';
};
export default payoutSherpasDomTeacher;
