import { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';

import useStyles from '../styles';

type props = {
  title: string;
  className: string;
  newValue: string | number;
  referenceValue: string | number;
};

const PriceCalculatorTableCell: FC<props> = function renderSummaryTableRow({
  title,
  className,
  newValue,
  referenceValue,
}) {
  const { classes } = useStyles();

  return (
    <TableCell className={className} align="center">
      <Tooltip title={title}>
        <div>
          <div>{newValue}</div>
          <div className={classes.referenceValue}>{referenceValue}</div>
        </div>
      </Tooltip>
    </TableCell>
  );
};

export default PriceCalculatorTableCell;
