import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  levelSubjectContainer: {
    marginTop: '16px',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  form: {
    flex: 1,
  },
}));
