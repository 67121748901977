/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BooleanField,
  BooleanInput,
  ChipField,
  DatagridConfigurable,
  DateField,
  EditButton,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  Pagination,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import { getPriceFromCentimes } from '@les-sherpas/sherpas-toolbox';

import CalculetteField from '../commun/CalculetteField/CalculetteField';
import useGlobalStyles, { calculleteColors } from '../global-styles';
import ListActions from '../ListActions';
import SeoUrlField from '../SeoUrlField';
import TeacherNameLink from '../TeacherNameLink';
import formatLongString from '../utils/functions';

function AnnounceList(props: any) {
  const { classes: globalClasses } = useGlobalStyles();
  return (
    <List
      sort={{ field: 'updatedDate', order: 'DESC' }}
      {...props}
      actions={<ListActions />}
      filters={[
        <TextInput
          label="Search"
          source="q"
          alwaysOn
          key="search"
          className={globalClasses.search}
        />,
        <SelectInput
          source="isHidden"
          key="isHidden"
          choices={[
            { id: '1', name: 'Caché' },
            { id: '0', name: 'Pas caché' },
          ]}
        />,
        <SelectInput
          source="status"
          key="status"
          choices={[
            { id: 'success', name: 'Success' },
            { id: 'pending', name: 'Pending' },
            { id: 'fail', name: 'Fail' },
          ]}
        />,
        <TextInput label="Matière" source="subject" key="subject" />,
        <NumberInput
          label="Nb chatrooms >="
          source="minChatroomNumber"
          key="minChatroomNumber"
        />,
        <NumberInput
          label="Nb chatrooms =<"
          source="maxChatroomNumber"
          key="maxChatroomNumber"
        />,
        <BooleanInput
          label="Certifié"
          source="isVerifiedAndTrained"
          key="isVerifiedAndTrained"
        />,
      ]}
      pagination={<Pagination sx={{ display: 'flex' }} />}
    >
      <DatagridConfigurable
        hover
        sx={{
          '& .RaDatagrid-headerCell.column-hourlyPriceWithCommission, .RaDatagrid-headerCell.column-hourlyRawTravelFee, .RaDatagrid-headerCell.column-hourlyRawPrice':
            {
              color: 'white',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          '& .column-hourlyRawPrice, & .column-hourlyRawTravelFee, & .column-hourlyPriceWithCommission':
            {
              textAlign: 'center',
            },
          '& .RaDatagrid-headerCell.column-hourlyRawPrice ,  .RaDatagrid-headerCell.column-hourlyRawTravelFee':
            {
              ...calculleteColors.rawPriceAndTravelFee,
            },
          '& .RaDatagrid-headerCell.column-hourlyPriceWithCommission': {
            ...calculleteColors.priceWithCommission,
          },
        }}
      >
        <TextField source="id" />
        <EditButton label="Edit" resource="announce" />
        <TeacherNameLink label="Sherpas" source="teacherName" />
        <TextField label="Téléphone" source="phoneNumber" />
        <FunctionField
          source="Title"
          sortable
          label="Title"
          render={({ title }: { title: string }) => formatLongString(title)}
        />
        <FunctionField
          source="introduction"
          sortable
          label="Intro"
          render={({ introduction }: { introduction: string }) =>
            formatLongString(introduction)
          }
        />
        <FunctionField
          source="methodology"
          sortable
          label="Méthodo"
          render={({ methodology }: any) => formatLongString(methodology)}
        />
        <SeoUrlField
          label="Seo ID"
          source="seoId"
          target="_blank" // New window
        />
        <ChipField source="status" />
        <BooleanField source="isHidden" />
        <TextField source="validationReason" label="Raison d'Invalidation" />
        <DateField source="updatedDate" />
        <TextField label="Matières" source="subjects" />
        <NumberField source="acceptedCourseCount" />
        <NumberField source="score" />
        <NumberField source="randomBooster" />
        <NumberField source="scoreBoosted" />
        <CalculetteField
          source="hourlyRawPrice"
          label="Prix"
          formatter={(value) => getPriceFromCentimes(value)}
          className="calculetteCell"
          sortable
        />
        <CalculetteField
          source="hourlyPriceWithCommission"
          label="Prix Mkt"
          className="calculetteCell"
          formatter={(value) => value}
        />
        <CalculetteField
          source="hourlyRawTravelFee"
          label="Frais de déplacement"
          className="calculetteCell"
          formatter={(value) => getPriceFromCentimes(value)}
          sortable
        />
        <BooleanField
          source="isVerifiedAndTrained"
          label="Prof certifié"
          sortable={false}
        />
        <NumberField source="totalChatrooms" label="Nb chatrooms" />
        <NumberField source="totalAnnounces" label="Nb d'announce du prof" />
      </DatagridConfigurable>
    </List>
  );
}

export default AnnounceList;
