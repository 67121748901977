import React from 'react';
import { useRecordContext } from 'react-admin';
import { Link } from '@mui/material';

const ACCEPTED_IMAGE_FORMAT = ['jpeg', 'png', 'jpg'];

function IdentityField(props: {
  source: string;
  originalName: string;
  target: string;
}) {
  const { source, target, originalName } = props;
  const record = useRecordContext(props);
  const value = record && record[source];
  const fileTitle = record && record[originalName];

  if (value == null) {
    return null;
  }

  const extension = fileTitle.split('.').pop();

  if (ACCEPTED_IMAGE_FORMAT.includes(extension)) {
    return <img src={value} alt="document" />;
  }

  return (
    <Link href={value} target={target}>
      {fileTitle}
    </Link>
  );
}

export default IdentityField;
