import React from 'react';
import { getInitials, UserType } from '@les-sherpas/sherpas-toolbox';
import { Avatar, Chip, ListItemAvatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  padding: { paddingRight: '8px' },
  avatar: {
    display: 'flex',
    flexFlow: 'column',
    gap: '4px',
    alignItems: 'center',
    width: '76px',
  },
  sherpasBot: {
    color: theme.palette.getContrastText('#3E9A67'),
    backgroundColor: '#3E9A67',
  },
}));

function AvatarMessage({
  userId,
  photoUrl,
  userType,
  firstName,
  lastName,
}: {
  userId: number;
  userType: UserType;
  photoUrl: string | null;
  firstName: string;
  lastName: string;
}) {
  const { classes } = useStyles();

  if (userId === 0) {
    return (
      <ListItemAvatar className={classes.padding}>
        <div className={classes.avatar}>
          <Avatar className={classes.sherpasBot}>Sherpas</Avatar>
          <Chip label="Bot" />
        </div>
      </ListItemAvatar>
    );
  }

  const initials = getInitials({
    firstName,
    lastName,
  });

  return (
    <ListItemAvatar className={classes.padding}>
      <div className={classes.avatar}>
        <Avatar src={photoUrl ?? ''} alt={initials}>
          {initials}
        </Avatar>
        <Chip
          label={userType === UserType.Teacher ? 'Teacher' : 'Student'}
          color={userType === UserType.Teacher ? 'primary' : 'secondary'}
        />
      </div>
    </ListItemAvatar>
  );
}

export default AvatarMessage;
