import React from 'react';
import { createRoot } from 'react-dom/client';

import 'reflect-metadata';

import App from './App';

import './index.css';

const domNode = document.getElementById('root');
// @ts-ignore
const root = createRoot(domNode);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
