import { GolfCourse } from '@mui/icons-material';

import CourseEdit from './course-edit';
import CourseList from './course-list';
import CourseShow from './course-show';

export default {
  list: CourseList,
  show: CourseShow,
  edit: CourseEdit,
  icon: GolfCourse,
};
