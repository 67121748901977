import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  recap: {
    padding: 4,
    border: `1px solid #E4E4E4`,
  },
  recapTitle: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  table: {
    minWidth: 600,
  },
  rawFullPrice: {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#2762ea',
  },
  priceWithDiscount: {
    fontWeight: 'bold',
    backgroundColor: '#fdd34c',
  },
  commission: {
    fontWeight: 'bold',
    backgroundColor: '#d4a6bd',
  },
  educationalCost: {
    fontWeight: 'bold',
    backgroundColor: '#6366F1',
    color: 'white',
  },
  referenceValue: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'italic',
  },
}));
