import React from 'react';
import {
  ChipField,
  DateTimeInput,
  Edit,
  NumberInput,
  RadioButtonGroupInput,
  SimpleForm,
  TextField,
} from 'react-admin';
import { CourseStatus } from '@les-sherpas/sherpas-toolbox';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  courseEdit: {
    display: 'flex',
    flexFlow: 'column',
    gap: '16px',
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-evenly',
    marginBottom: '32px',
  },
  title: {
    display: 'flex',
    gap: '8px',
  },
}));

function CourseEdit() {
  const { classes } = useStyles();

  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm>
        <div className={classes.courseEdit}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>
              Cours id:
              <TextField source="id" />
            </div>
            <div className={classes.title}>
              Professeur:
              <TextField source="teacherName" label="Sherpas" />
            </div>
            <div className={classes.title}>
              Étudiant:
              <TextField source="studentName" label="Étudiant" />
            </div>
          </div>

          <DateTimeInput source="date" label="Programmé Pour" />
          <NumberInput source="duration" max={240} min={30} step={15} />
          <div>
            Status en cours:
            <ChipField source="status" color="primary" />
          </div>
          <RadioButtonGroupInput
            source="status"
            choices={[
              { id: CourseStatus.PENDING, name: CourseStatus.PENDING },
              { id: CourseStatus.ACCEPTED, name: CourseStatus.ACCEPTED },
              { id: CourseStatus.CANCELED, name: CourseStatus.CANCELED },
            ]}
            label="Nouveau status:"
          />
        </div>
      </SimpleForm>
    </Edit>
  );
}

export default CourseEdit;
