import { ChatRoomSwapSubscriptionDto } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_URL ?? '';

export const swapSubscription = async ({
  swapSubscriptionDto,
}: {
  swapSubscriptionDto: ChatRoomSwapSubscriptionDto;
}) => {
  try {
    await axios.post(
      `${BASE_URL}/chat-room/admin/swapSubscription`,
      swapSubscriptionDto,
      {
        withCredentials: true,
        validateStatus: (status) => [200, 201].includes(status),
      }
    );
    return { status: true };
  } catch (error) {
    return { status: false, error };
  }
};
