import { makeStyles } from 'tss-react/mui';

const useGlobalStyles = makeStyles()(() => ({
  search: {
    width: '400px',
  },
  btnGreen: {
    backgroundColor: '#50C686',
    color: '#fff',
  },
}));

export const calculleteColors = {
  rawPriceAndTravelFee: {
    backgroundColor: '#37bef8',
  },
  rawFullPrice: {
    color: 'white',
    backgroundColor: '#2762ea',
  },
  overallCommissionRate: {
    backgroundColor: '#d4a6bd',
  },
  priceWithCommission: {
    backgroundColor: '#18a24a',
  },
  quantityDiscount: {
    backgroundColor: '#f49e0c',
  },
  priceWithManagementCost: {
    backgroundColor: '#e01d49',
  },
  educationalCost: {
    color: 'white',
    backgroundColor: '#6366F1',
  },
  priceWithDiscount: {
    backgroundColor: '#fdd34c',
  },
};

export default useGlobalStyles;
