import {
  ExportButton,
  FilterButton,
  SelectColumnsButton,
  TopToolbar,
} from 'react-admin';

function ListActions() {
  return (
    <TopToolbar>
      <SelectColumnsButton />
      <FilterButton />
      <ExportButton maxResults={10000} />
    </TopToolbar>
  );
}

export default ListActions;
