import { ProductType } from '@les-sherpas/sherpas-toolbox';

export type SubscriptionProduct = {
  id: number;
  productType: ProductType;
  label: string;
};

const SUBSCRIPTIONS_PRODUCTS: SubscriptionProduct[] = [
  {
    id: 0,
    productType: ProductType.RecurringTraining,
    label: 'un Suivi Régulier',
  },
  {
    id: 1,
    productType: ProductType.IntensiveTraining,
    label: 'un Stage intensif',
  },
];

export default SUBSCRIPTIONS_PRODUCTS;
