import React from 'react';
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import {
  getPriceFromCentimes,
  getPriceInCentimes,
  MAX_PRICE_ANNOUNCE,
  MAX_TRAVEL_FEE_ANNOUNCE,
  MIN_PRICE_ANNOUNCE,
  MIN_TRAVEL_FEE_ANNOUNCE,
  StatusEnum,
} from '@les-sherpas/sherpas-toolbox';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  fullWidth: {
    width: '100%',
  },
  block: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: '1rem',
  },
}));

function AnnounceEdit() {
  const { classes } = useStyles();
  return (
    <Edit>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput
          source="title"
          isRequired
          multiline
          className={classes.fullWidth}
        />
        <TextInput
          source="introduction"
          isRequired
          multiline
          className={classes.fullWidth}
        />
        <TextInput
          source="methodology"
          isRequired
          multiline
          className={classes.fullWidth}
        />
        <div className={classes.block}>
          <RadioButtonGroupInput
            // className={classes.block}
            source="status"
            choices={[
              { id: StatusEnum.SUCCESS, name: StatusEnum.SUCCESS },
              { id: StatusEnum.PENDING, name: StatusEnum.PENDING },
              { id: StatusEnum.FAIL, name: StatusEnum.FAIL },
            ]}
            label="nouveau status:"
          />
        </div>
        <div className={classes.block}>
          <BooleanInput
            type="checkbox"
            source="isHidden"
            label="l'annonce est cachée"
          />
          <BooleanInput
            type="checkbox"
            source="isForceHidden"
            label="Masqué admin"
          />
        </div>
        <div className={classes.block}>
          <Tooltip title="hourlyRawPrice">
            <NumberInput
              source="hourlyRawPrice"
              label="Prix du professeur"
              fullWidth
              format={(hourlyRawPrice) => getPriceFromCentimes(hourlyRawPrice)}
              parse={(hourlyRawPrice) => getPriceInCentimes(hourlyRawPrice)}
              min={getPriceFromCentimes(MIN_PRICE_ANNOUNCE)}
              max={getPriceFromCentimes(MAX_PRICE_ANNOUNCE)}
            />
          </Tooltip>
          <Tooltip title="hourlyRawTravelFee">
            <NumberInput
              source="hourlyRawTravelFee"
              label="Frais de déplacement"
              fullWidth
              format={(hourlyRawPrice) => getPriceFromCentimes(hourlyRawPrice)}
              parse={(hourlyRawPrice) => getPriceInCentimes(hourlyRawPrice)}
              min={getPriceFromCentimes(MIN_TRAVEL_FEE_ANNOUNCE)}
              max={getPriceFromCentimes(MAX_TRAVEL_FEE_ANNOUNCE)}
            />
          </Tooltip>
        </div>

        <FormDataConsumer>
          {({ formData }) =>
            formData.validationReason.length > 2 && (
              <>
                <h1> ⚠️ L&apos;annonce contient</h1>
                <div className={classes.block}>
                  <ul>
                    {formData.validationReason
                      .split(',')
                      .map((reason: string, i: number) => (
                        <li key={i}>{reason}</li>
                      ))}
                  </ul>
                </div>
              </>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}

export default AnnounceEdit;
