import {
  BooleanField,
  BooleanInput,
  Button,
  DatagridConfigurable,
  DateField,
  DateInput,
  EditButton,
  Form,
  FunctionField,
  List,
  NumberField,
  Pagination,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import {
  formatDurationInMinutes,
  getPriceFromCentimes,
} from '@les-sherpas/sherpas-toolbox';

import useGlobalStyles from '../global-styles';
import ListActions from '../ListActions';
import SeoUrlField from '../SeoUrlField';
import expireCourses from './utils/expire-courses';

function CourseList() {
  const { classes: globalClasses } = useGlobalStyles();
  return (
    <>
      <List
        sort={{ field: 'date', order: 'DESC' }}
        actions={<ListActions />}
        filters={[
          <TextInput
            label="Search"
            source="q"
            alwaysOn
            key="search"
            className={globalClasses.search}
          />,
          <DateInput
            key="createdDate"
            source="createdDate"
            label="Date de création"
          />,
          <SelectInput
            source="status"
            key="status"
            label="Status"
            choices={[
              { id: 'accepted', name: 'Accepté' },
              { id: 'pending', name: 'En cours' },
              { id: 'expired', name: 'Expiré' },
              { id: 'canceled', name: 'Annulé' },
            ]}
          />,
          <SelectInput
            source="location"
            key="location"
            label="Location"
            choices={[
              { id: 'online', name: 'En ligne' },
              { id: 'atHome', name: 'À domicile' },
            ]}
          />,
          <DateInput
            source="updatedDate"
            key="updatedDate"
            label="Date de dernière modification"
          />,
          <DateInput source="date" key="date" label="Date programmée" />,
          <BooleanInput
            label="Cours d'essai"
            source="isFirstCourse"
            key="isFirstCourse"
          />,
        ]}
        pagination={<Pagination sx={{ display: 'flex' }} />}
      >
        <DatagridConfigurable>
          <TextField source="id" />
          <TextField source="teacherName" label="Sherpas" />
          <TextField source="studentName" label="Étudiant" />
          <TextField source="parentName" label="Parent" />
          <DateField source="createdDate" label="Date de Création" showTime />
          <DateField source="date" label="Programmée pour" showTime />
          <TextField source="status" label="Status" />
          <TextField source="toBeAcceptedBy" label="À accepter par" />
          <DateField source="updatedDate" label="Updated Date" showTime />
          <TextField source="subject" label="Matière" />
          <TextField source="level" label="Niveau" />
          <TextField source="location" label="Location" />
          <FunctionField
            label="Durée"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render={(record: any) => formatDurationInMinutes(record.duration)}
          />
          <BooleanField
            source="isFirstCourse"
            sortable={false}
            label="Cours d'essai"
          />
          <SeoUrlField label="Annonce" source="seoId" target="_blank" />
          <TextField
            source="subscriptionId"
            label="SubId Sherpas"
            sortable={false}
          />
          <TextField
            source="stripeSubscriptionId"
            label="SubId Stripe"
            sortable={false}
          />
          <TextField source="subscriptionStatus" sortable={false} />
          <NumberField
            source="hourlyRawPrice"
            label="hourlyRawPrice"
            format={(hourlyRawPrice: number) =>
              `${getPriceFromCentimes(hourlyRawPrice)} €`
            }
          />
          <NumberField
            source="hourlyRawTravelFee"
            label="hourlyRawTravelFee"
            format={(hourlyRawTravelFee: number) =>
              `${getPriceFromCentimes(hourlyRawTravelFee)} €`
            }
          />
          <EditButton label="Edit" resource="course" />
        </DatagridConfigurable>
      </List>
      <Form>
        <Button
          label="Expire pending courses"
          variant="contained"
          onClick={() => expireCourses()}
        />
      </Form>
    </>
  );
}

export default CourseList;
