import React, { useEffect, useState } from 'react';
import {
  Create,
  FileField,
  FileInput,
  FormOwnProps,
  SimpleForm,
  useNotify,
} from 'react-admin';
import { LegalDocumentApi, wait } from '@les-sherpas/sherpas-toolbox';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';

import { getUploadedFiles, uploadFile } from './api/uploadAndUploadedPdfApi';
import { FormDataType } from './config/types';

function LegalDocument() {
  const [currentCgvFileLink, setCurrentCgvFileLink] =
    useState<LegalDocumentApi['admin']['getFile']['response']>();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [formData, setFormData] = useState<FormDataType | null>(null);
  const notify = useNotify();

  const handleUploadedPdf = async () => {
    try {
      const data = await getUploadedFiles({
        uri: 'uploaded-terms-and-conditions-pdf',
      });
      setCurrentCgvFileLink(data);
    } catch (_) {
      notify('Erreur lors de la récupération des documents téléchargés', {
        type: 'error',
      });
    }
  };

  const handleResetForm = async () => {
    if (window) {
      await wait(1);
      window.location.reload();
    }
  };

  const handleUploadPdf = async () => {
    if (!formData) return;
    try {
      await uploadFile({
        data: formData,
        uri: 'upload-terms-and-conditions-pdf',
      });
      notify('Le document a été mis à jour avec succès !', { type: 'success' });
      handleResetForm();
    } catch (error) {
      if (error instanceof AxiosError)
        notify(error?.response?.data.message, { type: 'error' });
    }
    setOpenConfirmation(false);
  };

  const handleFormSubmit: FormOwnProps['onSubmit'] = async (
    data: FormDataType
  ) => {
    setFormData(data);
    setOpenConfirmation(true);
  };

  useEffect(() => {
    handleUploadedPdf();
  }, []);

  return (
    <section>
      <Typography variant="h4" component="header" gutterBottom>
        Documents déjà téléchargés
      </Typography>
      <Paper style={{ padding: '1em', marginBottom: '2em' }}>
        <ul>
          <li key="CGV-file-link">
            {currentCgvFileLink?.url !== '' ? currentCgvFileLink?.url : 'aucun'}
          </li>
        </ul>
      </Paper>
      <Typography variant="h4" component="header" gutterBottom>
        Mettre à jour les mentions légales de la marketplace
      </Typography>
      <Create title="Mentions légales & CGU">
        <SimpleForm id="templates" onSubmit={handleFormSubmit}>
          <Typography variant="h6" component="h2" gutterBottom>
            Mentions Légales
          </Typography>
          <FileInput
            source="attachments"
            placeholder="Déposer le document ici"
            accept="application/pdf"
            label="Choisissez un fichier PDF"
            fullWidth
          >
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Create>

      <Dialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
      >
        <DialogTitle>Confirmation de soumission</DialogTitle>
        <DialogContent>
          <DialogContentText>
            La mise à jour du document aura un impact direct sur le PDF des CGV
            en production. Veuillez vérifier attentivement le fichier avant de
            confirmer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CancelIcon />}
            onClick={() => setOpenConfirmation(false)}
            color="primary"
          >
            Annuler
          </Button>
          <Button
            startIcon={<CheckCircleIcon />}
            onClick={handleUploadPdf}
            color="secondary"
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
}

export default LegalDocument;
