import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    display: 'flex',
    gap: '10px',
  },
}));
