import * as React from 'react';
import { LinkProps, useRecordContext } from 'react-admin';
import { Link } from '@mui/material';

import formatLongString from './utils/functions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SeoUrlField(props: LinkProps & any) {
  const { source, target } = props;
  const record = useRecordContext(props);
  const value = record && record[source];

  if (value == null) {
    return null;
  }

  return (
    <Link
      href={`${process.env.REACT_APP_FRONT_URL}/t/${value}`}
      target={target}
    >
      {formatLongString(value)}
    </Link>
  );
}

export default SeoUrlField;
