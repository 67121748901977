import { AdminStudent } from '@les-sherpas/sherpas-toolbox';

export const canDeleteStudent = (
  student: AdminStudent['getList']['response']['one']
): boolean => !student.mainUser && student.groupUsers.length === 0;

export const getStudentFullName = (
  student: AdminStudent['getList']['response']['one']
): string => `${student.firstName} ${student.lastName}`;

export const getActionName = (
  student: AdminStudent['getList']['response']['one']
): string => (student.isActive ? 'Supprimer' : 'Restaurer');

export const getConfirmContent = (
  student: AdminStudent['getList']['response']['one']
): string => {
  if (student.isActive) {
    return "Cela va empêcher l'utilisateur de se connecter à l'application, archiver ses chats ouverts et désactiver ses notifications. Voulez-vous continuer ?";
  }
  return "Cela va restaurer l'utilisateur et lui permettre de se connecter à l'application, restaurer ses chats ouverts et activer ses notifications. Voulez-vous continuer ?";
};
