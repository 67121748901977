import React from 'react';
import { locationOfCourse } from '@les-sherpas/sherpas-toolbox';
import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import { SubscriptionOptionsFormProps } from '../utils';

import useStyles from './styles';

function SubscriptionProposalCoursesLocation({
  setLocation,
  location,
}: Pick<SubscriptionOptionsFormProps, 'setLocation' | 'location'>) {
  const { classes } = useStyles();
  return (
    <Grid item xs={12}>
      <Typography>Localisation des cours 📍</Typography>
      <ToggleButtonGroup
        aria-label="contained primary button group"
        exclusive
        value={location}
        onChange={(_, newValue) => setLocation(newValue)}
        className={classes.toggleGroup}
      >
        <ToggleButton
          className={classes.optionLocation}
          value={locationOfCourse.online}
        >
          En ligne
        </ToggleButton>
        <ToggleButton
          className={classes.optionLocation}
          value={locationOfCourse.atHome}
        >
          À domicile
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
}

export default SubscriptionProposalCoursesLocation;
