import React from 'react';
import {
  ChipField,
  Datagrid,
  EditButton,
  List,
  NumberField,
  Pagination,
  SelectInput,
  TextField,
} from 'react-admin';

function TagList() {
  return (
    <List
      pagination={<Pagination sx={{ display: 'flex' }} />}
      filters={[
        <SelectInput
          source="userType"
          key="userType"
          choices={[
            { id: 'student', name: 'student' },
            { id: 'teacher', name: 'teacher' },
          ]}
        />,
      ]}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" label="Nom" />
        <ChipField source="userType" label="Type" />
        <NumberField source="score" label="Search score" />
        <EditButton />
      </Datagrid>
    </List>
  );
}

export default TagList;
