import React, { ChangeEvent } from 'react';
import { AnnounceAdminFromTeacher } from '@les-sherpas/sherpas-toolbox';
import {
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { SubscriptionOptionsFormProps } from '../utils';

import useStyles from './styles';

function SubscriptionProposalAnnouncesList({
  announce,
  setAnnounce,
  setHourlyRawPrice,
  setHourlyRawTravelFee,
  handleAnnounceChange,
  announces,
}: {
  announces: AnnounceAdminFromTeacher[];
} & Pick<
  SubscriptionOptionsFormProps,
  | 'announce'
  | 'setAnnounce'
  | 'setHourlyRawPrice'
  | 'setHourlyRawTravelFee'
  | 'handleAnnounceChange'
>) {
  const { classes } = useStyles();

  const selectAnnounce = (announceId: number): void => {
    const selectedAnnounce = announces.find((a) => a.announceId === announceId);
    if (selectedAnnounce) {
      setAnnounce(selectedAnnounce);
      setHourlyRawPrice(selectedAnnounce.tarification.hourlyRawPrice);
      setHourlyRawTravelFee(selectedAnnounce.tarification.hourlyRawTravelFee);
    }
  };

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleAnnounceChange();
    selectAnnounce(parseInt(e.target.value, 10));
  };

  return (
    <Grid item xs={12}>
      <FormControl>
        <Typography variant="body1" component="h2">
          Choisir une annonce:
        </Typography>
        <RadioGroup
          aria-label="announce"
          name="announce1"
          value={announce?.announceId ?? '0'}
          onChange={handleRadioChange}
        >
          <div className={classes.radioTitle}>
            {announces.map(({ announceId, title, subjects }) => (
              <div
                className={classes.radioContainer}
                key={`announce_${announceId}`}
              >
                <FormControlLabel
                  value={announceId.toString()} // Convert to string
                  label={title}
                  control={<Radio />}
                />
                <div className={classes.chip}>
                  {subjects.map(({ name: subjectName, id: subjectId }) => (
                    <Chip
                      key={`subject_${subjectId}`}
                      label={subjectName}
                      color="primary"
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

export default SubscriptionProposalAnnouncesList;
