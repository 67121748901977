/* eslint-disable no-restricted-imports */
import React from 'react';
import {
  BooleanField,
  BooleanInput,
  DatagridConfigurable,
  DateField,
  EditButton,
  FunctionField,
  List,
  Pagination,
  RaRecord,
  SelectArrayInput,
  TextField,
  TextInput,
  useGetList,
} from 'react-admin';
import { AdminStudent } from '@les-sherpas/sherpas-toolbox';
import CheckIcon from '@mui/icons-material/Check';

import useGlobalStyles from '../../global-styles';
import ListActions from '../../ListActions';
import { ResetPasswordButton } from '../../shared/ResetPasswordButton/ResetPasswordButton';
import csvExporter from '../../utils/csvExporter';
import defaultSearchOrderedByName from '../../utils/hookUtils';

const exporter = (data: AdminStudent['getList']['response']['list']) => {
  csvExporter<AdminStudent['getList']['response']['list'][0]>({
    data,
    fileName: 'students',
  });
};

function StudentList() {
  const { classes: globalClasses } = useGlobalStyles();
  const { data } = useGetList('tag', defaultSearchOrderedByName);
  const studentTags = data?.filter((tag) => tag.userType === 'student');

  return (
    <List
      actions={<ListActions />}
      exporter={exporter}
      filterDefaultValues={{ isActive: true }}
      filters={[
        <TextInput
          label="Recherche par Nom , Prénom ou émail "
          source="q"
          alwaysOn
          key="search"
          fullWidth
          className={globalClasses.search}
        />,
        <SelectArrayInput source="tags" key="tags" choices={studentTags} />,

        <BooleanInput label="Actif ?" source="isActive" key="isActive" />,
      ]}
      pagination={<Pagination sx={{ display: 'flex' }} />}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <DatagridConfigurable>
        <EditButton />
        <TextField source="id" />
        <TextField source="firstName" label="Prénom" sortable={false} />
        <TextField source="lastName" label="Nom" sortable={false} />
        <TextField source="email" label="Email" sortable={false} />
        <TextField source="phoneNumber" label="Tel" sortable={false} />
        <BooleanField source="isActive" label="Actif ?" sortable={false} />
        <DateField source="createdDate" label="Compte crée le" />
        <DateField source="lastConnectionDate" label="Dernière connexion le" />
        <TextField
          label="Adresse"
          source="address.formattedAddress"
          sortable={false}
        />

        <FunctionField
          source="mainUser"
          label="Compte Principal"
          render={(student: AdminStudent['getList']['response']['one']) => {
            if (student?.mainUser) {
              return (
                <a
                  href={`#/student/${student.mainUser.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Voir Parent
                </a>
              );
            }
            return <CheckIcon />;
          }}
          sortable={false}
        />

        <TextField
          label="Nom du Parent"
          source="parentFullName"
          sortable={false}
        />
        <FunctionField
          label="Réinitialiser le mot de passe"
          render={(record: RaRecord) => (
            <ResetPasswordButton email={record.email} />
          )}
        />
      </DatagridConfigurable>
    </List>
  );
}

export default StudentList;
