import { SHARED_COLUMNS_BETWEEN_BACK_BO } from '@les-sherpas/sherpas-toolbox';

export const DISABLED_FIELDS = ['seoURL', 'updatedAt', 'canonical'];
export const DISPLAYED_BY_DEFAULT_FIELDS = [
  'id',
  'template',
  'canonical',
  'h1Content',
  'metaTitle',
  'metaDescription',
  'updatedAt',
];

export const HIDDEN_BY_DEFAULT_FIELDS = [
  'seoURL',
  'updatedAt',
  'canonical',
  'h1Content',
  'txtContent9',
  'txtContent10',
  'h2Content1',
  'h2Content2',
  'h2Content3',
  'h2Content4',
  'h2Content5',
  'h2Content6',
  'h2Content7',
  'h2Content8',
  'h2Content9',
  'h2Content11',
  'h3Content7',
  'h3Content9',
  'h3Content10',
  'createdAt',
  'contentKey',
  ...SHARED_COLUMNS_BETWEEN_BACK_BO,
];

export const CONTENT_TEMPLATES = [
  'donner-des-cours',
  'donner-des-cours_matiere',
  'donner-des-cours_ville',
  'matiere',
  'matiere_online',
  'matiere_ville',
  'niveau',
  'ville',
];

export const ORDERED_EDITABLE_FIELDS = [
  'seoURL',
  'updatedAt',
  'metaTitle',
  'metaDescription',
  'canonical',
  'h1Content',
  'h2Content10',
  'txtAccroche',
  'h3Content1',
  'txtContent1',
  'h3Content2',
  'txtContent2',
  'h3Content3',
  'txtContent3',
  'h3Content4',
  'txtContent4',
  'h3Content5',
  'txtContent5',
  'h3Content6',
  'txtContent6',
  'h3Content7',
  'txtContent7',
  'h3Content8',
  'txtContent8',
];
