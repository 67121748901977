import { useState } from 'react';
import {
  BooleanField,
  BooleanInput,
  ChipField,
  DeleteWithConfirmButton,
  Edit,
  FormDataConsumer,
  minLength,
  RadioButtonGroupInput,
  ReferenceArrayField,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextInput,
  Toolbar,
  ToolbarProps,
  useEditController,
  useGetList,
  useRecordContext,
} from 'react-admin';
import {
  IAddress,
  NOVA_AGREEMENT_CHARACTER_LIMIT,
  StatusEnum,
  TeacherApi,
  URSSAF_SIRET_CHARACTER_LIMIT,
} from '@les-sherpas/sherpas-toolbox';
import { makeStyles } from 'tss-react/mui';

import AddressAutoComplete from '../commun/Autocomplete/addressAutocomplete';
import InstitutionAutoComplete from '../InstitutionAutoComplete';
import defaultSearchOrderedByName from '../utils/hookUtils';

const useStyles = makeStyles()(() => ({
  teacherEdit: {
    display: 'flex',
    gap: '16px',
    width: '100%',
    alignItems: 'center',
  },
  id: {
    flex: 0.5,
  },
  block: {
    flex: 1,
  },
  title: {
    display: 'flex',
    gap: '8px',
  },
  deleteButton: {
    marginLeft: 'auto',
  },
}));

function EditToolbar(props: ToolbarProps) {
  const { classes } = useStyles();
  const user =
    useRecordContext<TeacherApi['admin']['updateTeacher']['request']>();
  return (
    <Toolbar {...props}>
      <SaveButton alwaysEnable />
      {user.isActive && (
        <DeleteWithConfirmButton
          className={classes.deleteButton}
          confirmContent="Cela va empêcher l'utilisateur de se connecter à l'application, masquer ses annonces, archiver ses chats ouverts et désactiver ses notifications. Voulez-vous continuer ?"
          translateOptions={{ name: `${user.firstName} ${user.lastName}` }}
        />
      )}
    </Toolbar>
  );
}

function TeacherEdit() {
  const { classes } = useStyles();
  const { data } = useGetList('tag', defaultSearchOrderedByName);
  const [address, setAddress] = useState<IAddress>();
  const [currentInstitution, setCurrentInstitution] = useState('');
  const { record } = useEditController();

  return (
    <Edit
      transform={(data: { address: unknown; institution: unknown }) => ({
        ...data,
        address: address ?? data.address,
        currentInstitution: currentInstitution ?? data.institution,
      })}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <div className={classes.teacherEdit}>
          <TextInput disabled label="Id" source="id" className={classes.id} />
          <TextInput source="firstName" className={classes.block} />
          <TextInput source="lastName" className={classes.block} />
        </div>
        <div className={classes.teacherEdit}>
          <TextInput source="phoneNumber" className={classes.block} />
          <TextInput source="email" className={classes.block} />
          <SelectInput
            choices={[
              { id: 'male', name: 'Male' },
              { id: 'female', name: 'Female' },
              { id: 'other', name: 'Other' },
            ]}
            source="gender"
            key="gender"
          />
        </div>
        <div className={classes.teacherEdit}>
          <TextInput
            label="Siret"
            source="siret"
            inputProps={{ maxLength: URSSAF_SIRET_CHARACTER_LIMIT }}
            validate={minLength(
              URSSAF_SIRET_CHARACTER_LIMIT,
              'Veuillez renseigner un numéro SIRET valide'
            )}
          />

          <TextInput
            label="Numéro agrément Nova"
            source="novaAgreementNumber"
            inputProps={{ maxLength: NOVA_AGREEMENT_CHARACTER_LIMIT }}
            validate={minLength(
              NOVA_AGREEMENT_CHARACTER_LIMIT,
              "Veuillez renseigner un numéro d'agrément valide"
            )}
          />
        </div>

        <div>
          <h1>Compte utilisateur</h1>
          <div className={classes.teacherEdit}>
            <div className={classes.block}>
              Email vérifié :
              {record?.isEmailVerified ? (
                <div>
                  <BooleanField defaultChecked source="isEmailVerified" />
                </div>
              ) : (
                <BooleanInput label="Vérifier" source="isEmailVerified" />
              )}
            </div>
          </div>
        </div>

        <FormDataConsumer>
          {({ formData }) => (
            <InstitutionAutoComplete
              institution={formData.currentInstitution}
              setInstitution={setCurrentInstitution}
            />
          )}
        </FormDataConsumer>
        <div className={classes.teacherEdit}>
          <FormDataConsumer>
            {({ formData }) => (
              <AddressAutoComplete
                address={formData.address}
                setAddress={setAddress}
                isFullWidth
              />
            )}
          </FormDataConsumer>
        </div>
        <div className={classes.teacherEdit}>
          <RadioButtonGroupInput
            className={classes.block}
            source="status"
            choices={[
              { id: StatusEnum.SUCCESS, name: StatusEnum.SUCCESS },
              { id: StatusEnum.PENDING, name: StatusEnum.PENDING },
              { id: StatusEnum.FAIL, name: StatusEnum.FAIL },
            ]}
            label="nouveau status:"
          />
          <div className={classes.block}>
            Activer :
            <BooleanInput label="Active" source="isActive" />
          </div>
          <div className={classes.block}>
            Vérifier :
            <BooleanInput label="Vérifié" source="isVerified" />
          </div>
          <div className={classes.block}>
            Formé :
            <BooleanInput label="Formé" source="isTrained" />
          </div>
        </div>

        <div>
          <h1>Documents d&apos;identité</h1>
          <ReferenceArrayField
            label="Document d'identité"
            reference="identity"
            source="identityIds"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="originalName" />
            </SingleFieldList>
          </ReferenceArrayField>

          <h1>Diplômes</h1>
          <ReferenceArrayField
            label="Diplomes"
            reference="diploma"
            source="diplomaIds"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="diplomaName" />
            </SingleFieldList>
          </ReferenceArrayField>
        </div>

        <div className={classes.teacherEdit}>
          <SelectArrayInput
            source="tags"
            choices={data?.filter((tag) => tag.userType === 'teacher')}
            optionText={(choice) => `${choice.name}`}
            options={{ autoWidth: true }}
          />
        </div>
      </SimpleForm>
    </Edit>
  );
}

export default TeacherEdit;
