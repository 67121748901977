import React from 'react';
import {
  Edit,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

function TagEdit() {
  return (
    <Edit>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="name" isRequired label="Nom" />
        <SelectInput
          source="userType"
          label="Type"
          choices={[
            { id: 'teacher', name: 'teacher' },
            { id: 'student', name: 'student' },
          ]}
          disabled
        />
        <FormDataConsumer>
          {({ formData }) => (
            <NumberInput
              source="score"
              label="Search score"
              min={-200}
              max={200}
              step={1}
              defaultValue={0}
              disabled={formData.userType === 'student'}
              fullWidth
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}

export default TagEdit;
