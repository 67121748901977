import { FC } from 'react';
import { BooleanInput, TextField } from 'react-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

type props = {
  idSource: string;
  contentTypeSource: string;
  isActiveSource: string;
};

const ResourceHeaderInfo: FC<props> = function resourceHeaderInfo({
  idSource,
  contentTypeSource,
  isActiveSource,
}) {
  return (
    <Table sx={{ minWidth: 650 }} aria-label="caption table">
      <TableHead>
        <TableRow>
          <TableCell>Type de ressource</TableCell>
          <TableCell align="left">Active</TableCell>
          <TableCell align="right">Ordre</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row">
            <TextField source={contentTypeSource} />
          </TableCell>
          <TableCell align="right">
            <BooleanInput source={isActiveSource} fullWidth label="Actif" />
          </TableCell>
          <TableCell align="right">
            #<TextField source={idSource} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ResourceHeaderInfo;
