import { School } from '@mui/icons-material';

import TeacherEdit from './teacher-edit';
import TeacherList from './teacher-list';

export default {
  edit: TeacherEdit,
  list: TeacherList,
  icon: School,
};
