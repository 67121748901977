import { RateReview } from '@mui/icons-material';

import ReviewEdit from './review-edit';
import ReviewList from './review-list';

export default {
  edit: ReviewEdit,
  list: ReviewList,
  icon: RateReview,
};
