import React from 'react';
import { Grid } from '@mui/material';

import SubscriptionProposalAnnouncePrice from './announcePrice/AnnouncePrice';
import SubscriptionProposalAnnouncesList from './announces/AnnouncesList';
import SubscriptionProposalCoursesLocation from './coursesLocation/CoursesLocation';
import SubscriptionProposalExpirationDuration from './expirationDuration/ExpirationDuration';
import SubscriptionProposalCommissionRateAndManagementCost from './sherpasCommissions/CommissionRateAndManagementCost';
import SubscriptionProposalSubjectsAndLevels from './subjetcsAndLevels/SubjectsAndLevels';
import SubscriptionProposalVolumeAndDurations from './volumesAndDurations/VolumeAndDurations';
import { SubscriptionOptionsFormProps } from './utils';

function SubscriptionProposalOptionsForm({
  announces,
  handleAnnounceChange,
  announce,
  setAnnounce,
  level,
  setLevel,
  subject,
  setSubject,
  productType,
  hourlyRawPrice,
  setHourlyRawPrice,
  hourlyRawTravelFee,
  setHourlyRawTravelFee,
  location,
  setLocation,
  volume,
  setVolume,
  duration,
  setDuration,
  managementCostRate,
  setManagementCostRate,
  commissionRate,
  setCommissionRate,
  educationalCostRate,
  setEducationalCostRate,
  isFixDuration,
  setExpirationDuration,
}: SubscriptionOptionsFormProps) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {announces && announces.length > 0 && (
        <Grid container spacing={2}>
          <SubscriptionProposalAnnouncesList
            announce={announce}
            announces={announces}
            setAnnounce={setAnnounce}
            setHourlyRawPrice={setHourlyRawPrice}
            setHourlyRawTravelFee={setHourlyRawTravelFee}
            handleAnnounceChange={handleAnnounceChange}
          />
          <SubscriptionProposalSubjectsAndLevels
            announce={announce}
            setLevel={setLevel}
            level={level}
            setSubject={setSubject}
            subject={subject}
          />
          <SubscriptionProposalVolumeAndDurations
            volume={volume}
            duration={duration}
            setDuration={setDuration}
            setVolume={setVolume}
            isFixDuration={isFixDuration}
            productType={productType}
          />
          <SubscriptionProposalAnnouncePrice
            hourlyRawPrice={hourlyRawPrice}
            hourlyRawTravelFee={hourlyRawTravelFee}
            setHourlyRawPrice={setHourlyRawPrice}
            setHourlyRawTravelFee={setHourlyRawTravelFee}
          />
          <SubscriptionProposalCoursesLocation
            location={location}
            setLocation={setLocation}
          />
          <SubscriptionProposalCommissionRateAndManagementCost
            commissionRate={commissionRate}
            setCommissionRate={setCommissionRate}
            managementCostRate={managementCostRate}
            setManagementCostRate={setManagementCostRate}
            educationalCostRate={educationalCostRate}
            setEducationalCostRate={setEducationalCostRate}
            productType={productType}
          />
          <SubscriptionProposalExpirationDuration
            setExpirationDuration={setExpirationDuration}
          />
        </Grid>
      )}
    </>
  );
}

export default SubscriptionProposalOptionsForm;
