import React, { FC } from 'react';
import { ChatRoomApi } from '@les-sherpas/sherpas-toolbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ProposalColumns from './components/ProposalColumn/ProposalColumn';
import ProposalRow from './components/ProposalRow/ProposalRow';

import useStyles from './styles';

type ProposalsHistoryProps = {
  proposals: ChatRoomApi['admin']['getChatRoomForProposal']['response']['subscriptionProposals'];
};

const ProposalsHistory: FC<ProposalsHistoryProps> = function proposalsHistory({
  proposals,
}) {
  const { classes } = useStyles();
  const proposalColumns = [
    "Date d'envoi",
    'Type',
    'Envoyée Par',
    'Durée',
    'Volume',
    'Localisation',
    'Commission (%)',
    'Pourcentage de frais de dossier (%)',
    'Montant de frais Pédagogiques  (€)',
    `Date d'expiration`,
    'Achetée 🎯',
  ];

  return (
    <div className={classes.container}>
      <h2>{`Historique des propositions d'abonnement`}</h2>
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <ProposalColumns columns={proposalColumns} />
            </TableRow>
          </TableHead>
          <TableBody>
            {proposals.map((proposal, index) => (
              <ProposalRow
                key={`proposal-row-item${index}`}
                proposal={proposal}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProposalsHistory;
