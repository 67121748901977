import { AuthApi } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const apiHandleResetPasswordLink = async (
  body: AuthApi['admin']['getResetPasswordLink']['request']
): Promise<AuthApi['admin']['getResetPasswordLink']['response']> => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.post<
      AuthApi['admin']['getResetPasswordLink']['response']
    >(`${BASE_URL}/auth/admin/get-reset-password-link`, body, {
      withCredentials: true,
      validateStatus: (status) => status === 201,
    });
    const urlString = response.data;
    return urlString;
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(`Le lien n'a pas pu être récupéré pour l'id: ${body.email}`);
    return { url: '' };
  }
};
