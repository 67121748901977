import { useState } from 'react';
import { Button } from 'react-admin';
import { User } from '@les-sherpas/sherpas-toolbox';

import { apiHandleResetPasswordLink } from './apiHandleResetPasswordLink';

const handleCopyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};
type Props = {
  email: User['email'];
};
// eslint-disable-next-line import/prefer-default-export
export function ResetPasswordButton({ email }: Props) {
  const [resetPasswordLink, setResetPasswordLink] = useState<
    string | undefined
  >();

  const handleResetPasswordButtonClick = async (email: User['email']) => {
    const link = await apiHandleResetPasswordLink({
      email,
    });
    if (link) {
      setResetPasswordLink(link.url);
    }
  };
  return resetPasswordLink ? (
    <div>
      <input value={resetPasswordLink} />

      <Button
        variant="contained"
        color="primary"
        label="Copier"
        onClick={() => handleCopyToClipboard(resetPasswordLink)}
      />
    </div>
  ) : (
    <Button
      onClick={() => {
        handleResetPasswordButtonClick(email);
      }}
      label="Réinitialiser le mot de passe"
      variant="contained"
    />
  );
}
