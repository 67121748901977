import React from 'react';
import {
  BulkExportButton,
  DatagridConfigurable,
  DateField,
  EditButton,
  List,
  Pagination,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

import ListActions from '../ListActions';
import {
  CONTENT_TEMPLATES,
  DISPLAYED_BY_DEFAULT_FIELDS,
  HIDDEN_BY_DEFAULT_FIELDS,
} from './constants';

import useStyles from './styles';

function SeoList() {
  const { classes } = useStyles();
  return (
    <List
      actions={<ListActions />}
      filters={[
        <TextInput
          label="recherche par canonical url"
          source="canonical"
          alwaysOn
          key="search"
          fullWidth
          className={classes.filtre}
        />,
        <TextInput
          label="recherche par text"
          source="txt"
          key="content"
          alwaysOn
          className={classes.filtre}
        />,
        <SelectInput
          label="template"
          source="template"
          key="contentKey"
          alwaysOn
          formClassName={classes.filtre}
          choices={CONTENT_TEMPLATES.map((template) => ({
            id: template,
            name: template,
          }))}
          className={classes.filtre}
        />,
        <TextInput
          label="ContentKey"
          source="contentkey"
          key="contentKey"
          alwaysOn
          className={classes.filtre}
        />,
        <SelectInput
          label="recherche par url active"
          source="isActive"
          key="canonical"
          alwaysOn
          choices={[
            { id: 'active', name: 'Url active' },
            { id: 'notActive', name: `Url n'est pas active` },
          ]}
          className={classes.filtre}
        />,
      ]}
      pagination={
        <Pagination rowsPerPageOptions={[20, 50]} sx={{ display: 'flex' }} />
      }
    >
      <DatagridConfigurable
        bulkActionButtons={<BulkExportButton />}
        omit={HIDDEN_BY_DEFAULT_FIELDS.filter(
          (field) => !DISPLAYED_BY_DEFAULT_FIELDS.includes(field)
        )}
      >
        <TextField source="id" />
        <EditButton />
        <TextField source="template" />
        <TextField source="canonical" />
        <TextField source="h1Content" />
        <TextField source="metaTitle" />
        <TextField source="metaDescription" sortable={false} />
        <DateField source="updatedAt" locales="fr-FR" showDate showTime />
        <TextField source="contentKey" sortable={false} />
        <TextField source="seoURL" sortable={false} />
        <TextField source="h2Content10" sortable={false} />
        <TextField source="h3Content1" sortable={false} />
        <TextField source="h3Content2" sortable={false} />
        <TextField source="h3Content3" sortable={false} />
        <TextField source="h3Content4" sortable={false} />
        <TextField source="h3Content5" sortable={false} />
        <TextField source="h3Content6" sortable={false} />
        <TextField source="h3Content7" sortable={false} />
        <TextField source="h3Content8" sortable={false} />
        <TextField source="txtContent1" sortable={false} />
        <TextField source="txtContent2" sortable={false} />
        <TextField source="txtContent3" sortable={false} />
        <TextField source="txtContent4" sortable={false} />
        <TextField source="txtContent5" sortable={false} />
        <TextField source="txtContent6" sortable={false} />
        <TextField source="txtContent7" sortable={false} />
        <TextField source="txtContent8" sortable={false} />
        <TextField source="txtAccroche" sortable={false} />
        <DateField
          source="createdAt"
          locales="fr-FR"
          showDate
          showTime
          sortable={false}
        />
        <TextField source="h2Content1" sortable={false} />
        <TextField source="h2Content2" sortable={false} />
        <TextField source="h2Content3" sortable={false} />
        <TextField source="h2Content4" sortable={false} />
        <TextField source="h2Content5" sortable={false} />
        <TextField source="h2Content6" sortable={false} />
        <TextField source="h2Content7" sortable={false} />
        <TextField source="h2Content8" sortable={false} />
        <TextField source="h2Content9" sortable={false} />
        <TextField source="h2Content11" sortable={false} />
        <TextField source="h3Content9" sortable={false} />
        <TextField source="h3Content10" sortable={false} />
        <TextField source="txtContent9" sortable={false} />
        <TextField source="txtContent10" sortable={false} />
      </DatagridConfigurable>
    </List>
  );
}

export default SeoList;
