import * as React from 'react';
import { Link, LinkProps, useRecordContext } from 'react-admin';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function TeacherNameLink(props: LinkProps & any) {
  const { source } = props;
  const record = useRecordContext(props);
  const value = record[source];

  if (!value) {
    return null;
  }
  const formattedFilters = JSON.stringify({
    q: value,
  });
  return (
    <Link
      to={{
        pathname: '/teacher',
        search: `displayedFilters=${formattedFilters}&filter=${formattedFilters}`,
      }}
    >
      {value}
    </Link>
  );
}

export default TeacherNameLink;
