import React from 'react';
import {
  BooleanField,
  DatagridConfigurable,
  DeleteButton,
  EditButton,
  List,
  NumberField,
  TextField,
} from 'react-admin';

function TVCampaignList() {
  return (
    <List actions={false}>
      <DatagridConfigurable>
        <NumberField source="id" />
        <EditButton />
        <BooleanField source="isActive" label="Actif" />
        <TextField source="campaign" label="Campagne" />
        <TextField source="description" label="Texte Marketing" />
        <TextField source="buttonLabel" label="Texte CTA" />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  );
}

export default TVCampaignList;
