import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AutocompleteInput,
  Create,
  SimpleForm,
  useGetOne,
  useNotify,
} from 'react-admin';
/** already import in react-admin */
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate, useParams } from 'react-router-dom';
import {
  AnnounceAdminFromTeacher,
  IdName,
  ITeacherAutocomplete,
} from '@les-sherpas/sherpas-toolbox';
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import {
  getAnnounceFromTeacher,
  getIdFromOption,
  getOptionsFromAutocomplete,
  optionRenderer,
  swapTrialCourse,
} from './swap-chat-room.utils';

import useStyles from './styles';

function SwapChatRoomShow() {
  const { classes } = useStyles();
  const { id } = useParams();
  const notify = useNotify();
  const { data } = useGetOne('chat-room', {
    id,
  });
  const [value, setValue] = useState<string>(data?.teacherFullName);
  const [teacher, setTeacher] = useState<ITeacherAutocomplete>({
    id: data?.teacherId,
    fullName: data?.teacherFullName,
  });
  const [options, setOptions] = useState<ITeacherAutocomplete[]>([]);
  const [announces, setAnnounces] = useState<AnnounceAdminFromTeacher[]>([]);
  const [announce, setAnnounce] = useState<AnnounceAdminFromTeacher>();
  const levels = useMemo(() => announce?.levels ?? [], [announce]);
  const subjects = useMemo(() => announce?.subjects ?? [], [announce]);
  const [level, setLevel] = useState<IdName>();
  const [subject, setSubject] = useState<IdName>();
  const [isLoading, setIsLoading] = useState(false);
  const [valueAutoComplete, setValueAutoComplete] = useState<string>(value);
  const navigate = useNavigate();

  const handleAutoCompleteChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValueAutoComplete(inputValue);
  };

  const handleChoice = (
    e: SyntheticEvent<Element, Event>,
    reason:
      | 'createOption'
      | 'toggleInput'
      | 'escape'
      | 'selectOption'
      | 'removeOption'
      | 'blur'
  ) => {
    if (reason === 'selectOption') {
      const input = e.target as HTMLElement;
      const selectedValue = input.innerText;
      const opt = options.find((o) => o.id === getIdFromOption(selectedValue));

      if (opt) {
        setTeacher(opt);
        setValue(opt.fullName);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setOptions(await getOptionsFromAutocomplete(valueAutoComplete));
    })();
  }, [valueAutoComplete]);

  useEffect(() => {
    if (teacher && teacher.id) {
      (async () => {
        setAnnounces(await getAnnounceFromTeacher(teacher));
      })();
    }
  }, [teacher]);

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLevel(undefined);
    setSubject(undefined);
    const selectedAnnounce = announces.find(
      (a) => a.announceId === parseInt(e.target.value, 10)
    );
    if (selectedAnnounce) {
      setAnnounce(selectedAnnounce);
    }
  };

  const handleLevelChange = (e: SelectChangeEvent<string>) => {
    const selectedLevel = levels.find((l) => l.name === e.target.value);
    if (selectedLevel) {
      setLevel(selectedLevel);
    }
  };

  const handleSubjectChange = (e: SelectChangeEvent<string>) => {
    const selectedSubject = subjects.find((s) => s.name === e.target.value);
    if (selectedSubject) {
      setSubject(selectedSubject);
    }
  };

  const handleSwap = async () => {
    /** for type purpose */
    setIsLoading(true);
    if (!id || !announce || !level || !subject) {
      return false;
    }
    const { isSuccess, message } = await swapTrialCourse({
      chatRoomId: parseInt(id, 10),
      swapChatRoomDto: {
        teacherId: teacher.id,
        announceId: announce.announceId,
        levelId: level.id,
        subjectId: subject.id,
      },
    });
    setIsLoading(false);
    if (isSuccess) {
      notify(message, { type: 'success' });
      navigate('/chat-room');
      return true;
    }
    notify(message, { type: 'error' });
    return false;
  };

  const handleClear = () => {
    setTeacher({
      id: data?.teacherId,
      fullName: data?.teacherFullName,
    });
    setValue(data?.teacherFullName);
    setAnnounces([]);
    setAnnounce(undefined);
    setLevel(undefined);
    setSubject(undefined);
  };

  return (
    <div>
      <h1 className={classes.root}>Swap ChatRoom: {id}</h1>
      {data && (
        <div className={classes.content}>
          <div className={classes.containerTitle}>
            <Typography variant="body2" component="h1">
              Étudiant: {data.studentFullName}
            </Typography>
            <Typography variant="body2" component="h1">
              Professeurs: {data.teacherFullName}
            </Typography>

            {data.parentFullName && (
              <Typography variant="body2" component="h1">
                Parent: {data.parentFullName}
              </Typography>
            )}
          </div>
          <Create title="Swap chatroom">
            <SimpleForm>
              <AutocompleteInput
                className={classes.fullWidth}
                source="autoComplete/teacher"
                label={teacher.fullName ?? 'Choisir un professeur'}
                choices={options}
                optionText={optionRenderer}
                onInput={handleAutoCompleteChange}
                onClose={handleChoice}
                value={teacher}
                inputValue={value}
                suggestionLimit={10}
                optionValue={value}
                clearOnBlur={false}
              />
              {teacher?.fullName && (
                <Typography
                  variant="body2"
                  component="h2"
                  className={classes.containerTitle}
                >
                  Professeur: {teacher?.fullName}
                </Typography>
              )}
              {announces.length !== 0 && (
                <>
                  <FormControl>
                    <Typography variant="body1" component="h2">
                      Choisir une annonce:
                    </Typography>
                    <RadioGroup
                      aria-label="announce"
                      name="annnouce1"
                      value={announce?.announceId ?? '0'}
                      onChange={handleRadioChange}
                    >
                      <div className={classes.radioTitle}>
                        {announces.map((a, id) => (
                          <div
                            className={classes.radioContainer}
                            key={`announce_${id}`}
                          >
                            <FormControlLabel
                              value={a.announceId}
                              label={a.title}
                              control={<Radio />}
                            />
                            <div className={classes.chip}>
                              {a.subjects.map((s, id) => (
                                <Chip
                                  key={`subject_${id}`}
                                  label={s.name}
                                  color="primary"
                                />
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </RadioGroup>
                  </FormControl>
                  {announce && (
                    <div className={classes.levelSubjectContainer}>
                      <FormControl variant="outlined" className={classes.form}>
                        <InputLabel id="select-level-label">
                          Choisir le level:
                        </InputLabel>
                        <Select
                          labelId="select-level-label"
                          label="Level"
                          id="select-level"
                          value={level?.name ?? ''}
                          onChange={handleLevelChange}
                        >
                          {levels.map((l) => (
                            <MenuItem key={`level_${l.id}`} value={l.name}>
                              {l.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" className={classes.form}>
                        <InputLabel id="select-subject-label">
                          Choisir la matière:
                        </InputLabel>
                        <Select
                          labelId="select-subject-label"
                          id="select-subject"
                          label="Matière"
                          value={subject?.name ?? ''}
                          onChange={handleSubjectChange}
                        >
                          {subjects.map((s) => (
                            <MenuItem key={`subject_${s.id}`} value={s.name}>
                              {s.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}
              {level && subject && announce && teacher && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.swap}
                    onClick={handleSwap}
                    disabled={isLoading}
                  >
                    Swap
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.swap}
                    onClick={handleClear}
                    disabled={isLoading}
                  >
                    Clear
                  </Button>
                </>
              )}
            </SimpleForm>
          </Create>
        </div>
      )}
    </div>
  );
}

export default SwapChatRoomShow;
