import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  radioContainer: {
    width: '100%',
    marginLeft: '16px',
    padding: '16px',
    border: '1px solid #ccc',
    borderRadius: '8px',
  },
  radioTitle: {
    display: 'flex',
    flexFlow: 'column',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '16px',
  },
  chip: {
    marginTop: '8px',
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
  },
}));
