import { FC } from 'react';
import { SelectInput, TextInput } from 'react-admin';
import { Tooltip } from '@mui/material';

import { RESOURCES_MEDIA_ANIMATION } from '../constants';

type props = {
  currentImgLink?: string;
};

const EditPromoBanner: FC<props> = function editPromoBanner({
  currentImgLink,
}) {
  return (
    <>
      <TextInput fullWidth variant="outlined" source="title" />
      <Tooltip
        title={
          <img
            srcSet={currentImgLink || ''}
            src={currentImgLink || ''}
            alt="Dans le cas si vous ne voyez pas un carré, c'est que le lien n'est pas correct"
            loading="lazy"
            width={400}
            height={400}
          />
        }
      >
        <TextInput fullWidth multiline variant="outlined" source="imageUrl" />
      </Tooltip>
      <TextInput fullWidth multiline variant="outlined" source="description" />

      <Tooltip title={"Ce champs permet de controler l'animation"}>
        <SelectInput
          source="mediaType"
          variant="outlined"
          isRequired
          emptyText="Le type de l'image permet de controler l'animation dans la page ressource"
          choices={RESOURCES_MEDIA_ANIMATION}
          fullWidth
        />
      </Tooltip>
      <TextInput
        multiline
        fullWidth
        variant="outlined"
        source="redirectionUrl"
      />
      <TextInput fullWidth multiline variant="outlined" source="buttonLabel" />
    </>
  );
};

export default EditPromoBanner;
