/* eslint-disable react/no-danger */
import { FC } from 'react';
import { SelectInput, TextInput } from 'react-admin';
import { Alert } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { RESOURCES_ICONS_LIST, RESOURCES_MEDIA_ANIMATION } from '../constants';

type props = {
  currentImgLink?: string;
  currentIconTag?: string;
};

const EditTrend: FC<props> = function editTrend({
  currentImgLink,
  currentIconTag,
}) {
  return (
    <>
      <TextInput fullWidth variant="outlined" source="title" />
      <TextInput
        multiline
        fullWidth
        variant="outlined"
        source="redirectionUrl"
      />
      <Tooltip
        title={
          <img
            srcSet={currentImgLink || ''}
            src={currentImgLink || ''}
            alt="Dans le cas si vous ne voyez pas un carré, c'est que le lien n'est pas correct"
            loading="lazy"
            width={400}
            height={400}
          />
        }
      >
        <TextInput fullWidth multiline variant="outlined" source="imageUrl" />
      </Tooltip>
      <Tooltip title={"Le type de l'image permet de controler l'animation"}>
        <SelectInput
          source="mediaType"
          variant="outlined"
          isRequired
          emptyText="Le type du media permet de controler l'animation de l'image dans la page ressource"
          choices={RESOURCES_MEDIA_ANIMATION}
          fullWidth
        />
      </Tooltip>

      <SelectInput
        source="tagIcon"
        variant="outlined"
        choices={RESOURCES_ICONS_LIST}
        fullWidth
      />
      {currentIconTag ? (
        <TextInput fullWidth variant="outlined" source="tagLabel" />
      ) : (
        <Alert style={{ width: '100%' }} color="error">
          Choisir une icone pour pouvoir avoir ajouter une description
        </Alert>
      )}
    </>
  );
};

export default EditTrend;
