import {
  AnnounceAdminFromTeacher,
  ChatRoomApi,
  ITeacherAutocomplete,
} from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_URL ?? '';

type SwapTrialCourseResponse = {
  isSuccess: boolean;
  message: string;
};

export const optionRenderer = (choice: ITeacherAutocomplete) =>
  `${choice.fullName}, id:${choice.id}`;

export const getIdFromOption = (option: string): number => {
  const id = option.split('id:')[1];
  return +id;
};

export const swapTrialCourse = async ({
  swapChatRoomDto,
  chatRoomId,
}: {
  swapChatRoomDto: ChatRoomApi['admin']['swapTrialCourse']['request'];
  chatRoomId: number;
}): Promise<SwapTrialCourseResponse> => {
  try {
    await axios.patch(
      `${BASE_URL}/chat-room/admin/swap-trial-course/${chatRoomId}`,
      swapChatRoomDto,
      {
        withCredentials: true,
        validateStatus: (status) => status >= 200 && status < 300,
      }
    );

    return {
      isSuccess: true,
      message: 'Chatroom swapped successfully',
    };
  } catch (error) {
    let errorMessage = 'An unknown error occurred. Please try again later.';

    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorMessage =
          error.response.data.message || 'Failed to swap the trial course.';
      } else if (error.request) {
        errorMessage =
          'No response received from the server. Please check your network connection.';
      } else {
        errorMessage = error.message;
      }
    }

    return {
      isSuccess: false,
      message: errorMessage,
    };
  }
};

export const getOptionsFromAutocomplete = async (
  value: string
): Promise<ITeacherAutocomplete[]> => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/teacher/admin/autocomplete?fullName=${value}`,
      {
        withCredentials: true,
        validateStatus: (status) => status === 200,
      }
    );
    return data;
  } catch (error) {
    return [];
  }
};

export const getAnnounceFromTeacher = async (
  teacher: ITeacherAutocomplete
): Promise<AnnounceAdminFromTeacher[]> => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/announce/admin/teacher/${teacher.id}`,
      {
        withCredentials: true,
        validateStatus: (status) => status === 200,
      }
    );
    return data;
  } catch (error) {
    return [];
  }
};
