import { useRef, useState } from 'react';
import axios from 'axios';
import { makeStyles } from 'tss-react/mui';

import CustomSelect from './CustomSelect';

type Props = {
  institution: string;
  setInstitution: Function;
};

const useStyles = makeStyles()(() => ({
  form: {
    position: 'relative',
    width: '100%',
  },
  inputLabel: {
    fontSize: '32px',
    fontWeight: 600,
  },
  input: {
    fontSize: 16,
    padding: '8px',
    borderRadius: 4,
    border: `1px solid rgba(24, 24, 27, 0.5)`,
    minWidth: 450,
    width: '100%',
  },
}));

function InstitutionAutoComplete({ institution, setInstitution }: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [textInput, setTextInput] = useState(institution ?? '');
  const [choices, setChoices] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = async (e: any) => {
    const institution = e.target.value;
    setTextInput(institution);
    const { data } = await axios.get<string[]>(
      `${process.env.REACT_APP_API_URL}/institution/autocomplete?q=${institution}`,
      {
        validateStatus: (status) => status === 200,
      }
    );
    setChoices(data);
  };

  const handleOnSelect = (id: string) => {
    setTextInput(id);
    setInstitution(id);
    setChoices([]);
  };

  const handleOnClose = () => {
    setOpen(false);
    setChoices([]);
  };

  return (
    <div className={classes.form}>
      <h1 className={classes.inputLabel}>Institution</h1>
      <input
        value={textInput}
        onChange={handleOnChange}
        type="text"
        onFocus={() => setOpen(true)}
        ref={ref}
        className={classes.input}
      />
      {open && choices.length > 0 && (
        <CustomSelect
          anchorRef={ref}
          choices={choices.map((choice, i) => ({
            id: i.toString(),
            name: choice,
          }))}
          onSelect={handleOnSelect}
          onClose={() => handleOnClose()}
        />
      )}
    </div>
  );
}

export default InstitutionAutoComplete;
