import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  activeRow: {
    backgroundColor: '#ffffff',
  },
  inactiveRow: {
    backgroundColor: '#f0f0f0',
    color: '#b0b0b0',
  },
  createdByCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));
