import { SentimentVerySatisfied } from '@mui/icons-material';

import StudentEdit from './edit/student-edit';
import StudentList from './list/students-list';

export default {
  list: StudentList,
  edit: StudentEdit,
  icon: SentimentVerySatisfied,
  options: { label: 'Students' },
};
