import NewspaperIcon from '@mui/icons-material/Newspaper';

import ResourcesContentEdit from './edit/resources-content-edit';
import ResourcesList from './list/resources-list';

export default {
  list: ResourcesList,
  edit: ResourcesContentEdit,
  icon: NewspaperIcon,
  options: { label: 'Méthodologie' },
};
