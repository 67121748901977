import React from 'react';
import {
  ChatRoomStatusType,
  CourseStatus,
  UserType,
} from '@les-sherpas/sherpas-toolbox';
import { Chip, Divider, ListItem, ListItemText } from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

import AvatarMessage from '../avatar/avatar';
import ChatDate from '../chat-date/chat-date';

const useStyles = makeStyles()((theme) => ({
  lightBlue: {
    color: theme.palette.getContrastText(lightBlue[500]),
    backgroundColor: lightBlue[500],
    marginRight: '8px',
  },
}));

function ChatMessageDmr({
  createdByUser,
  date,
  message,
  status,
}: {
  createdByUser: {
    id: number;
    userType: UserType;
    firstName: string;
    lastName: string;
    photoUrl: string | null;
  };
  date: Date;
  message: string;
  status: ChatRoomStatusType;
}) {
  const { classes } = useStyles();
  return (
    <>
      <ChatDate date={new Date(date)} />
      <ListItem>
        <AvatarMessage
          userId={createdByUser.id}
          userType={createdByUser.userType}
          firstName={createdByUser.firstName}
          lastName={createdByUser.lastName}
          photoUrl={createdByUser.photoUrl}
        />
        <Chip className={classes.lightBlue} label="DMR" />
        <ListItemText>{message}</ListItemText>
        <Chip
          label={status}
          color={status === CourseStatus.ACCEPTED ? 'primary' : 'default'}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}

export default ChatMessageDmr;
