import React from 'react';
import { ProductType } from '@les-sherpas/sherpas-toolbox';
import { Grid, TextField } from '@mui/material';
import hoursToMinutes from 'date-fns/hoursToMinutes';
import minutesToHours from 'date-fns/minutesToHours';

import { SubscriptionOptionsFormProps } from '../utils';

const getMinimalDurationByProductType = (productType: ProductType) => {
  switch (productType) {
    case ProductType.RecurringTraining:
      return 2;
    case ProductType.IntensiveTraining:
    default:
      return 1;
  }
};

function SubscriptionProposalVolumeAndDurations({
  setDuration,
  duration,
  volume,
  setVolume,
  isFixDuration,
  productType,
}: Pick<
  SubscriptionOptionsFormProps,
  | 'setDuration'
  | 'duration'
  | 'setVolume'
  | 'volume'
  | 'isFixDuration'
  | 'productType'
>) {
  return (
    <>
      <Grid item xs={6}>
        <TextField
          id="numberInput-duration"
          name="numberInputDuration"
          type="number"
          onChange={(e) => {
            setDuration(parseInt(e.target.value, 10));
          }}
          InputProps={{
            inputProps: {
              min: getMinimalDurationByProductType(productType),
              max: 100,
              isReadOnly: productType === ProductType.IntensiveTraining,
            },
          }}
          value={duration}
          label="Durée (Nombre de mois)"
          variant="outlined"
          size="small"
          fullWidth
          disabled={isFixDuration}
          error={duration < getMinimalDurationByProductType(productType)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="numberInput-volume"
          type="number"
          onChange={(e) => {
            setVolume(hoursToMinutes(parseInt(e.target.value, 10)));
          }}
          value={minutesToHours(volume)}
          label="Volume (Nombre d'heures)"
          variant="outlined"
          size="small"
          InputProps={{
            inputProps: {
              max: 100,
              min: 1,
            },
          }}
          fullWidth
          error={volume < 1}
        />
      </Grid>
    </>
  );
}

export default SubscriptionProposalVolumeAndDurations;
