import React from 'react';
import {
  Create,
  FileField,
  FileInput,
  FormOwnProps,
  SimpleForm,
} from 'react-admin';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ?? '';

interface LoadFileParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  uri: 'load-templates' | 'load-template-overloads';
}

async function loadFile({ data, uri }: LoadFileParams) {
  const file = data.attachments;
  const formData = new FormData();
  if (file.rawFile) formData.append('file', file.rawFile);
  if (file.title) formData.append('title', file.title);
  if (file.content) formData.append('content', file.content);
  await axios.post(`${BASE_URL}/seo-student-pages/admin/${uri}`, formData, {
    withCredentials: true,
    validateStatus: (status) => [200, 201].includes(status),
  });
}

function UploadTemplateAndOverloads() {
  const handleLoadTemplates: FormOwnProps['onSubmit'] = async (data) => {
    await loadFile({ data, uri: 'load-templates' });
  };
  const handleLoadTemplateOverloads: FormOwnProps['onSubmit'] = async (
    data
  ) => {
    await loadFile({ data, uri: 'load-template-overloads' });
  };
  return (
    <>
      <h1>Seo Student Pages</h1>
      <Create>
        <SimpleForm id="templates" onSubmit={handleLoadTemplates}>
          <h2>Templates</h2>
          <FileInput source="attachments">
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Create>
      <Create>
        <SimpleForm
          id="template-overloads"
          onSubmit={handleLoadTemplateOverloads}
        >
          <h2>Surcharges</h2>
          <FileInput source="attachments">
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Create>
    </>
  );
}

export default UploadTemplateAndOverloads;
