import * as React from 'react';
import { Link } from 'react-admin';
import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const MAX_DISPLAY_NODE = 3;

const useStyles = makeStyles()(() => ({
  chip: {
    cursor: 'pointer',
    margin: '2px',
    '&:hover': {
      backgroundColor: 'lightGrey',
    },
  },
  button: {
    border: 0,
    textDecoration: 'underline',
    color: '#2196f3',
    padding: 0,
    backgroundColor: 'transparent',
  },
}));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SubjectAnnounceLink(props: any) {
  const {
    record: { subjects, firstName, lastName },
  } = props;
  const { classes } = useStyles();

  const [hide, setHide] = React.useState(false);

  if (!subjects || !firstName || !lastName) {
    return null;
  }

  const fullName = `${firstName} ${lastName}`;

  const displayedSubjects = hide
    ? subjects
    : subjects.slice(0, MAX_DISPLAY_NODE);

  return (
    <>
      {displayedSubjects.map((subject: { name: string }, i: number) => {
        const filters = JSON.stringify({
          q: fullName,
          subject: subject.name,
        });
        return (
          <Link
            key={i}
            to={{
              pathname: '/announce',
              search: `displayedFilters=${filters}&filter=${filters}`,
            }}
          >
            <Chip className={classes.chip} label={subject.name} />
          </Link>
        );
      })}
      {subjects.length > MAX_DISPLAY_NODE && (
        <button
          type="button"
          onClick={() => setHide((prev) => !prev)}
          className={classes.button}
        >
          {!hide ? 'Voir plus' : 'Cacher'}
        </button>
      )}
    </>
  );
}

export default SubjectAnnounceLink;
