import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ?? '';

const cancelAndRefundProrata = async (
  currentSubscriptionId: string,
  refundedMonthlyPriceWithDiscount: number
): Promise<string> => {
  try {
    const result = await axios.post(
      `${BASE_URL}/subscription/admin/cancel-refund`,
      {
        currentSubscriptionId,
        refundedMonthlyPriceWithDiscount,
      },
      {
        withCredentials: true,
        validateStatus: (status) => status === 201,
      }
    );

    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    }
  }
  return 'error';
};

export default cancelAndRefundProrata;
