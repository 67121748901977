import { FC } from 'react';
import { Resource } from '@les-sherpas/sherpas-toolbox';

import GenericResourcesContent from '../common/generic-resources-content/generic-resources-content';

type Props = {
  title: string;
  data: Resource[];
};

const ResourcesTrends: FC<Props> = function resourcesTrends({ data, title }) {
  return (
    <GenericResourcesContent
      data={data}
      title={title}
      fields={[
        {
          type: 'number',
          source: 'id',
          label: 'Ordre',
        },
        {
          type: 'text',
          source: 'title',
          label: 'Titre',
        },
        {
          type: 'image',
          source: 'imageUrl',
          label: 'Image',
        },
        {
          type: 'tag',
          source: 'tagLabel',
          label: 'Label du tag',
        },
      ]}
    />
  );
};

export default ResourcesTrends;
