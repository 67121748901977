import CampaignIcon from '@mui/icons-material/Campaign';

import MarketingMessageCreate from './marketingMessageCreate';
import MarketingMessageEdit from './marketingMessageEdit';
import MarketingMessageList from './marketingMessageList';

export default {
  list: MarketingMessageList,
  edit: MarketingMessageEdit,
  create: MarketingMessageCreate,
  icon: CampaignIcon,
  options: { label: 'Message marketing' },
};
