/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/boolean-prop-naming */
import React, { FC } from 'react';
import { FunctionField } from 'react-admin';
import { Tooltip } from '@mui/material';

import useStyles from './styles';

export type CalculetteFieldProps = {
  source: string;
  label: string;
  formatter: (_: any) => string | number;
  className?: string;
  sortable?: boolean;
  suffix?: string;
};

const CalculetteField: FC<CalculetteFieldProps> = function calculetteComponent({
  source,
  label,
  className,
  formatter,
  sortable = false,
  suffix = '€',
}) {
  const { classes } = useStyles();
  return (
    <FunctionField
      source={source}
      sortable={sortable}
      label={label}
      className={classes.calculetteField}
      cellClassName={className}
      render={(record: any) => (
        <Tooltip title={source}>
          <span>
            {formatter(record[source])} {suffix}
          </span>
        </Tooltip>
      )}
    />
  );
};

export default CalculetteField;
