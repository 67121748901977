import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ?? '';

const expireCourses = async (): Promise<string> => {
  try {
    const result = await axios.get(`${BASE_URL}/course/admin/expire-courses`, {
      withCredentials: true,
      validateStatus: (status) => status === 201,
    });

    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    }
  }
  return 'error';
};

export default expireCourses;
