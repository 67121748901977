import React from 'react';
import {
  BooleanField,
  DatagridConfigurable,
  DeleteButton,
  EditButton,
  FunctionField,
  List,
  NumberField,
  TextField,
} from 'react-admin';
import { MarketingMessage } from '@les-sherpas/sherpas-toolbox';

import marketingMessageTargetTransco from './constants';

function MarketingMessageList() {
  return (
    <List actions={false}>
      <DatagridConfigurable>
        <NumberField source="id" />
        <EditButton />
        <BooleanField source="isActive" label="Actif" />
        <TextField source="campaign" label="Campagne" />
        <TextField source="emoji" label="Emoji" />
        <TextField source="description" label="Description" />
        <TextField source="actionLabel" label="Texte du bouton" />
        <TextField source="redirectUrl" label="Lien de redirection" />
        <FunctionField
          source="target"
          label="Ouvert dans"
          render={(record: MarketingMessage) =>
            marketingMessageTargetTransco[record.target]
          }
        />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  );
}

export default MarketingMessageList;
