import { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import { format, intervalToDuration, isPast } from 'date-fns';

type Props = {
  expirationDate: Date;
};

const ExpirationCell: FC<Props> = function expirationCell({ expirationDate }) {
  const formatExpirationDate = ({
    now,
    expirationDate,
  }: {
    now: Date;
    expirationDate: Date;
  }) => {
    if (isPast(expirationDate)) {
      return 'Expirée';
    }
    const duration = intervalToDuration({ start: now, end: expirationDate });
    if ((duration.days ?? 0) > 0) {
      return `${format(expirationDate, 'yyyy-MM-dd HH:mm')} : Dans (${
        duration.days
      } Jours ${duration.hours} h ${duration.minutes} min)`;
    }
    if ((duration.hours ?? 0) > 0) {
      return `${format(expirationDate, 'yyyy-MM-dd HH:mm')} : Dans (${
        duration.hours
      } h ${duration.minutes} min)`;
    }
    return `${format(expirationDate, 'yyyy-MM-dd HH:mm')} : Dans (${
      duration.minutes
    } min)`;
  };

  return (
    <TableCell>
      {formatExpirationDate({
        now: new Date(),
        expirationDate,
      })}
    </TableCell>
  );
};

export default ExpirationCell;
