import { Create } from 'react-admin';

import CreateEditForm from './CreateEditForm/CreateEditForm';

function MarketingMessageCreate() {
  return (
    <Create redirect="list">
      <CreateEditForm />
    </Create>
  );
}

export default MarketingMessageCreate;
