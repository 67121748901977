import { RefObject, useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  popUp: {
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: `0px 4px 8px rgba(24, 24, 27, 0.5)`,
    width: 'fit-content',
    minWidth: 300,
    maxHeight: 400,
    overflow: 'hidden scroll',
    zIndex: 2,
    borderRadius: 4,
    padding: `4px`,
    display: 'flex',
    flexDirection: 'column',
    gap: `8px`,
    left: 0,
    top: 100,
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'white',
    fontSize: '16px',
    textAlign: 'left',
    padding: '8px',
    '&:hover': {
      color: 'white',
      backgroundColor: '#2196f3',
      borderRadius: '4px',
    },
  },
}));

type Props = {
  anchorRef: RefObject<HTMLInputElement>;
  choices: { id: string; name: string }[];
  onSelect: Function;
  onClose: Function;
};

function CustomSelect({ anchorRef, choices, onSelect, onClose }: Props) {
  const { classes } = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = anchorRef.current;

    if (ref.current) {
      document.addEventListener('click', (e) => {
        if (e.target !== element) {
          onClose();
        }
      });
    }

    return () => {
      document.onclick = null;
    };
  }, []);

  const handleSelect = (i: string) => {
    onSelect(i);
    onClose();
  };

  return (
    <div className={classes.popUp} ref={ref}>
      {choices.map(({ id, name }) => (
        <button
          type="button"
          key={id}
          onClick={() => handleSelect(name)}
          className={classes.button}
        >
          {name}
        </button>
      ))}
    </div>
  );
}

export default CustomSelect;
