import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ spacing }) => ({
  selectorContainers: {
    display: 'flex',
    flexDirection: 'column',
    padding: 32,
    border: `1px solid #E4E4E4`,
    borderRadius: 16,
    maxWidth: 1250,
    margin: 16,
    gap: 16,
    flex: 2,
  },
  panelContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    justifyContent: 'space-around',
  },
  toggleGroup: {
    width: '100%',
  },
  input: {
    marginLeft: spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  linkGenerator: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1200,
    margin: '8px auto',
  },
}));
