import React from 'react';
import { ProductType } from '@les-sherpas/sherpas-toolbox';
import { Grid, TextField } from '@mui/material';

import { SubscriptionOptionsFormProps } from '../utils';

function SubscriptionProposalCommissionRateAndManagementCost({
  managementCostRate,
  setManagementCostRate,
  commissionRate,
  setCommissionRate,
  educationalCostRate,
  setEducationalCostRate,
  productType,
}: Pick<
  SubscriptionOptionsFormProps,
  | 'managementCostRate'
  | 'setManagementCostRate'
  | 'commissionRate'
  | 'setCommissionRate'
  | 'educationalCostRate'
  | 'setEducationalCostRate'
  | 'productType'
>) {
  return (
    <>
      <Grid item xs={6}>
        <TextField
          label="Pourcentage de commission Sherpas"
          value={commissionRate}
          onChange={(e) => {
            setCommissionRate(parseInt(e.target.value, 10));
          }}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            min: 0,
            max: 100,
          }}
          InputLabelProps={{ shrink: true }}
          type="number"
          variant="outlined"
          fullWidth
          error={commissionRate < 0}
        />
      </Grid>
      {productType === ProductType.RecurringTraining && (
        <Grid item xs={6}>
          <TextField
            label="Pourcentage de Frais Pédagogiques"
            value={educationalCostRate}
            onChange={(e) => {
              setEducationalCostRate(parseInt(e.target.value, 10));
            }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              min: 0,
              max: 100,
            }}
            InputLabelProps={{ shrink: true }}
            type="number"
            variant="outlined"
            fullWidth
            error={educationalCostRate < 0}
          />
        </Grid>
      )}
      {productType === ProductType.IntensiveTraining && (
        <Grid item xs={6}>
          <TextField
            label="Pourcentage de frais de dossier"
            value={managementCostRate}
            onChange={(e) => {
              setManagementCostRate(parseInt(e.target.value, 10));
            }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              min: 0,
              max: 100,
            }}
            InputLabelProps={{ shrink: true }}
            type="number"
            variant="outlined"
            fullWidth
            error={managementCostRate < 0}
          />
        </Grid>
      )}
    </>
  );
}

export default SubscriptionProposalCommissionRateAndManagementCost;
