import { FC } from 'react';
import { ChatRoomApi, ProductType } from '@les-sherpas/sherpas-toolbox';
import TableCell from '@mui/material/TableCell';

type Props = {
  productType: ChatRoomApi['admin']['getChatRoomForProposal']['response']['subscriptionProposals'][number]['productType'];
};

const ProductTypeCell: FC<Props> = function productTypeCell({ productType }) {
  const getProductTypeLabel = () => {
    switch (productType) {
      case ProductType.RecurringTraining:
        return '⛰️ Suivi Régulier';
      case ProductType.IntensiveTraining:
        return '⚡ Stage Intensif';
      default:
        return 'Produit inconnu';
    }
  };

  return <TableCell>{getProductTypeLabel()}</TableCell>;
};

export default ProductTypeCell;
