import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { AutocompleteInput, Form, useGetOne } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AnnounceAdminFromTeacher,
  ITeacherAutocomplete,
  SubscriptionApi,
} from '@les-sherpas/sherpas-toolbox';
import { MonetizationOn } from '@mui/icons-material';
import { Button, Chip, Typography } from '@mui/material';
import { AxiosError } from 'axios';

import SummaryPanel from '../generate-subscription-sales/SummaryPanel';
import useSubscriptionOptionsForm from '../subscription-options-form/hook';
import SubscriptionProposalOptionsForm from '../subscription-options-form/SubscriptionProposalOptionsForm';
import {
  getAnnounceFromTeacher,
  getIdFromOption,
  getOptionsFromAutocomplete,
  optionRenderer,
} from '../swap-chat-room/swap-chat-room.utils';
import { swapSubscription } from './swap-subscription.utils';

import useStyles from './styles';

function SwapSubscriptionShow() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [announces, setAnnounces] = useState<AnnounceAdminFromTeacher[]>([]);

  const subscriptionOptionsForm = useSubscriptionOptionsForm({
    announces,
  });

  const {
    announce,
    setAnnounce,
    level,
    setLevel,
    subject,
    setSubject,
    hourlyRawPrice,
    hourlyRawTravelFee,
    location,
    volume,
    duration,
    commissionRate,
    managementCostRate,
    educationalCostRate,
    calculatedEducationalCostRate,
    setCalculatedEducationalCostRate,
    expirationDuration,
    productType,
  } = subscriptionOptionsForm;
  const { id } = useParams();
  const { data, isLoading, error } = useGetOne<
    SubscriptionApi['admin']['getSubscription']['response']
  >('subscription', {
    id: Number(id),
  });
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;
  const [value, setValue] = useState<string>(data?.teacher?.fullName ?? '');

  const [teacher, setTeacher] = useState<ITeacherAutocomplete>({
    id: data?.teacher.id ?? 0,
    fullName: data?.teacher.fullName ?? '',
  });
  const [options, setOptions] = useState<ITeacherAutocomplete[]>([]);

  const [swapError, setSwapError] = useState<string>('');
  const [valueAutoComplete, setValueAutoComplete] = useState<string>(value);

  const handleAutoCompleteChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValueAutoComplete(inputValue);
  };

  const handleChoice = (
    e: SyntheticEvent<Element, Event>,
    reason:
      | 'createOption'
      | 'toggleInput'
      | 'escape'
      | 'selectOption'
      | 'removeOption'
      | 'blur'
  ) => {
    if (reason === 'selectOption') {
      const input = e.target as HTMLElement;
      const selectedValue = input.innerText;
      const opt = options.find((o) => o.id === getIdFromOption(selectedValue));

      if (opt) {
        setTeacher(opt);
        setValue(opt.fullName);
      }
    }
  };

  const handleAnnounceChange = () => {
    setLevel(undefined);
    setSubject(undefined);
  };

  const handleSwapSubscription = async () => {
    /** for type purpose */
    // setIsLoading(true);
    if (!id || !announce || !level || !subject) {
      return false;
    }
    const oldChatRoomId = Number(data?.chatRoomId);
    const hasSwap = await swapSubscription({
      swapSubscriptionDto: {
        oldChatRoomId,
        duration,
        volume,
        location,
        quantityDiscountRate: 0, // NO DISCOUNT ANYMORE,
        hourlyRawPrice,
        hourlyRawTravelFee,
        announceId: announce.announceId,
        levelId: level.id,
        subjectId: subject.id,
        commissionRate,
        managementCostRate,
        educationalCostRate: calculatedEducationalCostRate,
        expirationDuration,
      },
    });

    if (hasSwap.status) {
      setSwapError('');
      navigate('/subscription');
      return true;
    }

    setSwapError(
      `Une erreur s'est produite lors du swap: ${(
        hasSwap.error as AxiosError<{ message: string }>
      )?.response?.data?.message}`
    );
    return false;
  };

  useEffect(() => {
    (async () => {
      setOptions(await getOptionsFromAutocomplete(valueAutoComplete));
    })();
  }, [valueAutoComplete]);

  useEffect(() => {
    if (teacher && teacher.id) {
      (async () => {
        setAnnounces(await getAnnounceFromTeacher(teacher));
        setAnnounce(undefined);
      })();
    }
  }, [teacher]);

  if (!data || isLoading) {
    return <p>Loading</p>;
  }

  return (
    <Form>
      <div>
        <h1 className={classes.root}>Swap Subscription: {id}</h1>
        <div className={classes.containerTitle}>
          <Typography variant="body2" component="h1">
            Étudiant: {data.student.fullName}
          </Typography>
          <Typography variant="body2" component="h1">
            Professeurs: {data.teacher.fullName}
          </Typography>
        </div>
        <div className={classes.panelContainer}>
          <SummaryPanel
            announce={announce}
            duration={duration}
            level={level?.name}
            location={location}
            hourlyRawTravelFee={hourlyRawTravelFee}
            parentFullName={
              data.student.id !== data.mainUser.id ? data.mainUser.fullName : ''
            }
            studentFullName={data.student.fullName}
            teacherFullName={data.teacher.fullName}
            subject={subject?.name}
            volume={volume}
            hourlyRawPrice={hourlyRawPrice}
            commissionRate={commissionRate}
            managementCostRate={managementCostRate}
            educationalCostRate={educationalCostRate}
            setCalculatedEducationalCostRate={setCalculatedEducationalCostRate}
            expirationDuration={expirationDuration}
            productType={productType}
          />
          <div className={classes.selectorContainers}>
            <Typography variant="h4" className={classes.recapTitle}>
              <MonetizationOn fontSize="large" color="primary" />
              Options
            </Typography>
            <AutocompleteInput
              className={classes.fullWidth}
              source="autoComplete/teacher"
              label={teacher.fullName ?? 'Choisir un professeur'}
              choices={options}
              optionText={optionRenderer}
              onInput={handleAutoCompleteChange}
              onClose={handleChoice}
              value={teacher}
              inputValue={value}
              suggestionLimit={10}
              optionValue={value}
              clearOnBlur={false}
            />
            {announces.length !== 0 && (
              <SubscriptionProposalOptionsForm
                announces={announces}
                handleAnnounceChange={handleAnnounceChange}
                {...subscriptionOptionsForm}
              />
            )}
            {level && subject && announce && teacher && duration && volume && (
              <Button
                variant="contained"
                color="primary"
                className={classes.swap}
                onClick={() => handleSwapSubscription()}
                disabled={
                  isLoading ||
                  !id ||
                  !announce ||
                  !level ||
                  !subject ||
                  !location
                }
              >
                Swap Abonnement
              </Button>
            )}
            {swapError && <Chip label={swapError} color="secondary" />}
          </div>
        </div>
      </div>
    </Form>
  );
}

export default SwapSubscriptionShow;
