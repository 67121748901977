import {
  getPriceFromCentimes,
  IntensiveTrainingDuration,
  IntensiveTrainingVolume,
  LocationOfCourse,
  priceCalculator,
  ProductType,
  RecurringTrainingDuration,
  RecurringTrainingVolume,
} from '@les-sherpas/sherpas-toolbox';
import { Info } from '@mui/icons-material';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import PriceCalculatorTableCell from './PriceCalculatorTableCell.tsx/PriceCalculatorTableCell';

import useStyles from './styles';

type Props = {
  title: string;
  productType: ProductType;
  location: LocationOfCourse;
  volume: RecurringTrainingVolume | IntensiveTrainingVolume;
  duration: RecurringTrainingDuration | IntensiveTrainingDuration;
  hourlyRawPrice: number;
  hourlyRawTravelFee: number;
  commissionRate: number;
  quantityDiscountRate: number;
  managementCostRate: number;
  educationalCostRate: number;
  newDuration: number;
  newVolume: number;
  newCommissionRate: number;
  newQuantityDiscountRate: number;
  newManagementCostRate: number;
  newEducationalCostRate: number;
};

const numberFormat = new Intl.NumberFormat('fr-FR', {
  currency: 'EUR',
  style: 'currency',
});

function PriceCalculatorPanel({
  title,
  productType,
  location,
  duration,
  volume,
  hourlyRawPrice,
  hourlyRawTravelFee,
  commissionRate,
  quantityDiscountRate,
  managementCostRate,
  educationalCostRate,
  newDuration,
  newVolume,
  newCommissionRate,
  newQuantityDiscountRate,
  newManagementCostRate,
  newEducationalCostRate,
}: Props) {
  const { classes } = useStyles();

  const isRecurringTraining = productType === ProductType.RecurringTraining;

  const debitCalculator = isRecurringTraining
    ? priceCalculator.sreDebit
    : priceCalculator.sieDebit;

  const currentPrices = debitCalculator({
    subscription: {
      location,
      hourlyRawPrice,
      hourlyRawTravelFee,
      duration,
      volume,
      commissionRate,
      quantityDiscountRate,
      managementCostRate,
      educationalCostRate,
      globalPromoCodeDiscount: 0,
    },
  });

  const debitAdminUpdateSimulationCalculator =
    productType === ProductType.RecurringTraining
      ? priceCalculator.sreDebitAdminUpdateSimulation
      : priceCalculator.sieDebitAdminUpdateSimulation;

  const newPrices = debitAdminUpdateSimulationCalculator({
    location,
    hourlyRawPrice,
    hourlyRawTravelFee,
    duration: newDuration,
    volume: newVolume,
    commissionRate: newCommissionRate,
    quantityDiscountRate: newQuantityDiscountRate,
    managementCostRate: newManagementCostRate,
    educationalCostRate: newEducationalCostRate,
  });

  return (
    <div className={classes.recap}>
      <Typography variant="h4" className={classes.recapTitle}>
        <Info fontSize="large" color="primary" />
        {title}
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Calculette">
          <TableHead>
            <TableRow>
              <TableCell padding="none" />
              <TableCell colSpan={2} align="center" padding="none">
                Premier mois
              </TableCell>
              {isRecurringTraining && (
                <>
                  <TableCell colSpan={2} align="center" padding="none">
                    Mois suivants
                  </TableCell>
                  <TableCell align="center" padding="none">
                    Sur la durée
                  </TableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell align="center" padding="none">
                (horaire)
              </TableCell>
              <TableCell align="center" padding="none">
                (mensuel)
              </TableCell>
              {isRecurringTraining && (
                <>
                  <TableCell align="center" padding="none">
                    (horaire)
                  </TableCell>
                  <TableCell align="center" padding="none">
                    (mensuel)
                  </TableCell>
                  <TableCell align="center" padding="none">
                    (global)
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="student-prices">
              <TableCell component="th" scope="row">
                Payé par l&apos;étudiant
              </TableCell>
              <PriceCalculatorTableCell
                key="firstMonthHourlyPriceWithDiscount"
                title="firstMonthHourlyPriceWithDiscount"
                className={classes.priceWithDiscount}
                newValue={numberFormat.format(
                  getPriceFromCentimes(
                    newPrices.firstMonthHourlyPriceWithDiscount
                  )
                )}
                referenceValue={numberFormat.format(
                  getPriceFromCentimes(
                    currentPrices.firstMonthHourlyPriceWithDiscount
                  )
                )}
              />
              <PriceCalculatorTableCell
                key="firstMonthMonthlyPriceWithDiscount"
                title="firstMonthMonthlyPriceWithDiscount"
                className={classes.priceWithDiscount}
                newValue={numberFormat.format(
                  getPriceFromCentimes(
                    newPrices.firstMonthMonthlyPriceWithDiscount
                  )
                )}
                referenceValue={numberFormat.format(
                  getPriceFromCentimes(
                    currentPrices.firstMonthMonthlyPriceWithDiscount
                  )
                )}
              />
              {isRecurringTraining && (
                <>
                  <PriceCalculatorTableCell
                    key="hourlyPriceWithDiscount"
                    title="hourlyPriceWithDiscount"
                    className={classes.priceWithDiscount}
                    newValue={numberFormat.format(
                      getPriceFromCentimes(newPrices.hourlyPriceWithDiscount)
                    )}
                    referenceValue={numberFormat.format(
                      getPriceFromCentimes(
                        currentPrices.hourlyPriceWithDiscount
                      )
                    )}
                  />
                  <PriceCalculatorTableCell
                    key="monthlyPriceWithDiscount"
                    title="monthlyPriceWithDiscount"
                    className={classes.priceWithDiscount}
                    newValue={numberFormat.format(
                      getPriceFromCentimes(newPrices.monthlyPriceWithDiscount)
                    )}
                    referenceValue={numberFormat.format(
                      getPriceFromCentimes(
                        currentPrices.monthlyPriceWithDiscount
                      )
                    )}
                  />
                  <PriceCalculatorTableCell
                    key="globalPriceWithDiscount"
                    title="globalPriceWithDiscount"
                    className={classes.priceWithDiscount}
                    newValue={numberFormat.format(
                      getPriceFromCentimes(newPrices.globalPriceWithDiscount)
                    )}
                    referenceValue={numberFormat.format(
                      getPriceFromCentimes(
                        currentPrices.globalPriceWithDiscount
                      )
                    )}
                  />
                </>
              )}
            </TableRow>
            <TableRow key="sherpas-prices">
              <TableCell component="th" scope="row">
                Taux de marge Sherpas
              </TableCell>
              <PriceCalculatorTableCell
                key="firstMonthHourlyOverallCommissionRate"
                title="firstMonthHourlyOverallCommissionRate"
                className={classes.commission}
                newValue={`${newPrices.firstMonthHourlyOverallCommissionRate} %`}
                referenceValue={`${currentPrices.firstMonthHourlyOverallCommissionRate} %`}
              />
              <PriceCalculatorTableCell
                key="firstMonthMonthlyOverallCommissionRate"
                title="firstMonthMonthlyOverallCommissionRate"
                className={classes.commission}
                newValue={`${newPrices.firstMonthMonthlyOverallCommissionRate} %`}
                referenceValue={`${currentPrices.firstMonthMonthlyOverallCommissionRate} %`}
              />
              {isRecurringTraining && (
                <>
                  <PriceCalculatorTableCell
                    key="hourlyOverallCommissionRate"
                    title="hourlyOverallCommissionRate"
                    className={classes.commission}
                    newValue={`${newPrices.hourlyOverallCommissionRate} %`}
                    referenceValue={`${currentPrices.hourlyOverallCommissionRate} %`}
                  />
                  <PriceCalculatorTableCell
                    key="monthlyOverallCommissionRate"
                    title="monthlyOverallCommissionRate"
                    className={classes.commission}
                    newValue={`${newPrices.monthlyOverallCommissionRate} %`}
                    referenceValue={`${currentPrices.monthlyOverallCommissionRate} %`}
                  />
                  <PriceCalculatorTableCell
                    key="globalOverallCommissionRate"
                    title="globalOverallCommissionRate"
                    className={classes.commission}
                    newValue={`${newPrices.globalOverallCommissionRate} %`}
                    referenceValue={`${currentPrices.globalOverallCommissionRate} %`}
                  />
                </>
              )}
            </TableRow>
            <TableRow key="sherpas-margin-prices">
              <TableCell component="th" scope="row">
                Marge Sherpas
              </TableCell>
              <PriceCalculatorTableCell
                key="firstMonthHourlyOverallCommission"
                title="firstMonthHourlyOverallCommission"
                className={classes.commission}
                newValue={numberFormat.format(
                  getPriceFromCentimes(
                    newPrices.firstMonthHourlyOverallCommission
                  )
                )}
                referenceValue={numberFormat.format(
                  getPriceFromCentimes(
                    currentPrices.firstMonthHourlyOverallCommission
                  )
                )}
              />
              <PriceCalculatorTableCell
                key="firstMonthMonthlyOverallCommission"
                title="firstMonthMonthlyOverallCommission"
                className={classes.commission}
                newValue={numberFormat.format(
                  getPriceFromCentimes(
                    newPrices.firstMonthMonthlyOverallCommission
                  )
                )}
                referenceValue={numberFormat.format(
                  getPriceFromCentimes(
                    currentPrices.firstMonthMonthlyOverallCommission
                  )
                )}
              />
              {isRecurringTraining && (
                <>
                  <PriceCalculatorTableCell
                    key="hourlyOverallCommission"
                    title="hourlyOverallCommission"
                    className={classes.commission}
                    newValue={numberFormat.format(
                      getPriceFromCentimes(newPrices.hourlyOverallCommission)
                    )}
                    referenceValue={numberFormat.format(
                      getPriceFromCentimes(
                        currentPrices.hourlyOverallCommission
                      )
                    )}
                  />
                  <PriceCalculatorTableCell
                    key="monthlyOverallCommission"
                    title="monthlyOverallCommission"
                    className={classes.commission}
                    newValue={numberFormat.format(
                      getPriceFromCentimes(newPrices.monthlyOverallCommission)
                    )}
                    referenceValue={numberFormat.format(
                      getPriceFromCentimes(
                        currentPrices.monthlyOverallCommission
                      )
                    )}
                  />
                  <PriceCalculatorTableCell
                    key="globalOverallCommission"
                    title="globalOverallCommission"
                    className={classes.commission}
                    newValue={numberFormat.format(
                      getPriceFromCentimes(newPrices.globalOverallCommission)
                    )}
                    referenceValue={numberFormat.format(
                      getPriceFromCentimes(
                        currentPrices.globalOverallCommission
                      )
                    )}
                  />
                </>
              )}
            </TableRow>
            {productType === ProductType.RecurringTraining && (
              <TableRow key="educational-prices">
                <TableCell component="th" scope="row">
                  Frais pédagogiques
                </TableCell>
                <PriceCalculatorTableCell
                  key="firstMonthHourlyEducationalCost"
                  title="firstMonthHourlyEducationalCost"
                  className={classes.educationalCost}
                  newValue={numberFormat.format(
                    getPriceFromCentimes(
                      newPrices.firstMonthHourlyEducationalCost
                    )
                  )}
                  referenceValue={numberFormat.format(
                    getPriceFromCentimes(
                      currentPrices.firstMonthHourlyEducationalCost
                    )
                  )}
                />
                <PriceCalculatorTableCell
                  key="firstMonthMonthlyEducationalCost"
                  title="firstMonthMonthlyEducationalCost"
                  className={classes.educationalCost}
                  newValue={numberFormat.format(
                    getPriceFromCentimes(
                      newPrices.firstMonthMonthlyEducationalCost
                    )
                  )}
                  referenceValue={numberFormat.format(
                    getPriceFromCentimes(
                      currentPrices.firstMonthMonthlyEducationalCost
                    )
                  )}
                />
                {isRecurringTraining && (
                  <>
                    <PriceCalculatorTableCell
                      key="hourlyEducationalCost"
                      title="hourlyEducationalCost"
                      className={classes.educationalCost}
                      newValue={numberFormat.format(
                        getPriceFromCentimes(newPrices.hourlyEducationalCost)
                      )}
                      referenceValue={numberFormat.format(
                        getPriceFromCentimes(
                          currentPrices.hourlyEducationalCost
                        )
                      )}
                    />
                    <PriceCalculatorTableCell
                      key="monthlyEducationalCost"
                      title="monthlyEducationalCost"
                      className={classes.educationalCost}
                      newValue={numberFormat.format(
                        getPriceFromCentimes(newPrices.monthlyEducationalCost)
                      )}
                      referenceValue={numberFormat.format(
                        getPriceFromCentimes(
                          currentPrices.monthlyEducationalCost
                        )
                      )}
                    />
                    <PriceCalculatorTableCell
                      key="globalEducationalCost"
                      title="globalEducationalCost"
                      className={classes.educationalCost}
                      newValue={numberFormat.format(
                        getPriceFromCentimes(newPrices.globalEducationalCost)
                      )}
                      referenceValue={numberFormat.format(
                        getPriceFromCentimes(
                          currentPrices.globalEducationalCost
                        )
                      )}
                    />
                  </>
                )}
              </TableRow>
            )}
            <TableRow key="teacher-prices">
              <TableCell component="th" scope="row">
                Payé au professeur
              </TableCell>
              <PriceCalculatorTableCell
                key="firstMonthHourlyRawFullPrice"
                title="firstMonthHourlyRawFullPrice"
                className={classes.rawFullPrice}
                newValue={numberFormat.format(
                  getPriceFromCentimes(newPrices.firstMonthHourlyRawFullPrice)
                )}
                referenceValue={numberFormat.format(
                  getPriceFromCentimes(
                    currentPrices.firstMonthHourlyRawFullPrice
                  )
                )}
              />
              <PriceCalculatorTableCell
                key="firstMonthMonthlyRawFullPrice"
                title="firstMonthMonthlyRawFullPrice"
                className={classes.rawFullPrice}
                newValue={numberFormat.format(
                  getPriceFromCentimes(newPrices.firstMonthMonthlyRawFullPrice)
                )}
                referenceValue={numberFormat.format(
                  getPriceFromCentimes(
                    currentPrices.firstMonthMonthlyRawFullPrice
                  )
                )}
              />
              {isRecurringTraining && (
                <>
                  <PriceCalculatorTableCell
                    key="hourlyRawFullPrice"
                    title="hourlyRawFullPrice"
                    className={classes.rawFullPrice}
                    newValue={numberFormat.format(
                      getPriceFromCentimes(newPrices.hourlyRawFullPrice)
                    )}
                    referenceValue={numberFormat.format(
                      getPriceFromCentimes(currentPrices.hourlyRawFullPrice)
                    )}
                  />
                  <PriceCalculatorTableCell
                    key="monthlyRawFullPrice"
                    title="monthlyRawFullPrice"
                    className={classes.rawFullPrice}
                    newValue={numberFormat.format(
                      getPriceFromCentimes(newPrices.monthlyRawFullPrice)
                    )}
                    referenceValue={numberFormat.format(
                      getPriceFromCentimes(currentPrices.monthlyRawFullPrice)
                    )}
                  />
                  <PriceCalculatorTableCell
                    key="globalRawFullPrice"
                    title="globalRawFullPrice"
                    className={classes.rawFullPrice}
                    newValue={numberFormat.format(
                      getPriceFromCentimes(newPrices.globalRawFullPrice)
                    )}
                    referenceValue={numberFormat.format(
                      getPriceFromCentimes(currentPrices.globalRawFullPrice)
                    )}
                  />
                </>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default PriceCalculatorPanel;
