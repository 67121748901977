import React from 'react';
import {
  BooleanField,
  ChipField,
  DatagridConfigurable,
  DateField,
  EditButton,
  FunctionField,
  List,
  Pagination,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
} from 'react-admin';
import { ChatRoomApi, ChatRoomStatus } from '@les-sherpas/sherpas-toolbox';
import {
  ErrorOutlineRounded,
  Lock,
  MonetizationOn,
  SwapHorizontalCircleRounded,
} from '@mui/icons-material';

import useGlobalStyles from '../global-styles';
import ListActions from '../ListActions';
import SeoUrlField from '../SeoUrlField';
import StudentNameLink from '../StudentNameLink';
import SubscriptionsByStudentNameLink from '../SubscriptionsByStudentNameLink';
import TeacherNameLink from '../TeacherNameLink';

function ChatRoom() {
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <List
      sort={{ field: 'firstCourseDate', order: 'DESC' }}
      actions={<ListActions />}
      filters={[
        <TextInput
          label="Search"
          source="q"
          alwaysOn
          key="search"
          className={globalClasses.search}
        />,
        <SelectInput
          source="status"
          key="status"
          choices={[
            { id: ChatRoomStatus.ACCEPTED, name: ChatRoomStatus.ACCEPTED },
            { id: ChatRoomStatus.PENDING, name: ChatRoomStatus.PENDING },
            { id: ChatRoomStatus.EXPIRED, name: ChatRoomStatus.EXPIRED },
            { id: ChatRoomStatus.CANCELED, name: ChatRoomStatus.CANCELED },
          ]}
        />,
      ]}
      pagination={<Pagination sx={{ display: 'flex' }} />}
    >
      <DatagridConfigurable>
        <TextField source="id" />
        <TeacherNameLink
          label="Teacher"
          source="teacherFullName"
          sortable={false}
        />
        <StudentNameLink
          label="Student"
          source="studentFullName"
          sortable={false}
        />
        <StudentNameLink
          label="Parent"
          source="parentFullName"
          sortable={false}
        />
        <ChipField
          source="subjectName"
          label="Cours thématiques"
          sortable={false}
        />
        <SeoUrlField label="Annonce" source="seoId" target="_blank" />
        <DateField source="firstCourseDate" />
        <ChipField source="status" />
        <TextField
          label="DCE remisée"
          source="dceDiscountedAmount"
          sortable={false}
        />
        <BooleanField
          sortable={false}
          source="hasIncidentNotResolved"
          label="🔒"
          // @ts-ignore
          TrueIcon={Lock}
        />
        <ShowButton label="Go To chat" resource="chat" />
        <ShowButton
          label="Swap"
          resource="swap-chat-room"
          icon={<SwapHorizontalCircleRounded />}
        />
        <FunctionField
          source="canSuggestSubscription"
          label="Abonnement"
          render={(
            record: ChatRoomApi['admin']['getList']['response']['data'][number]
          ) => {
            const {
              disallowArchiveChatroom,
              canSuggestSubscription,
              archivedDate,
            } = record;

            const isChatroomArchived = archivedDate !== null;
            const hasSubscription =
              disallowArchiveChatroom && !isChatroomArchived;
            const canProposeSubscription =
              canSuggestSubscription && !isChatroomArchived;

            if (hasSubscription) {
              return (
                <SubscriptionsByStudentNameLink
                  redirectValue={record.studentFullName}
                  label="Voir"
                />
              );
            }

            if (canProposeSubscription) {
              return (
                <ShowButton
                  label="Proposer"
                  resource="generate-subscription-sales"
                  icon={<MonetizationOn />}
                />
              );
            }

            return (
              <ShowButton
                label="Indisponible"
                resource="generate-subscription-sales"
                icon={<ErrorOutlineRounded />}
                disabled
              />
            );
          }}
          sortable={false}
        />
        <TextField
          label="Archived by"
          source="archivedByFullName"
          sortable={false}
        />
        <DateField source="archivedDate" />
        <EditButton label="Edit" resource="chat-room" />
      </DatagridConfigurable>
    </List>
  );
}

export default ChatRoom;
