import { FC, useState } from 'react';
import { minValue, NumberInput, required } from 'react-admin';
import { formatInParis } from '@les-sherpas/sherpas-toolbox';
import { Alert, Typography } from '@mui/material';
import { addDays } from 'date-fns';

type props = {
  defaultExtensionDays: number;
  cancelationDate: Date | null;
};

const SubscriptionExtensionDays: FC<props> =
  function subscriptionExtensionDays({
    defaultExtensionDays,
    cancelationDate,
  }) {
    const [extensionDays, setExtensionDays] =
      useState<number>(defaultExtensionDays);

    return (
      <>
        <NumberInput
          label="Nombre de jours d'extension"
          source="extensionDays"
          validate={[
            required(),
            minValue(0, "Minimum 0 jours d'extension possible"),
          ]}
          min={0}
          defaultValue={0}
          onChange={(event) => setExtensionDays(event.target.value)}
          variant="outlined"
          fullWidth
        />
        {cancelationDate ? (
          <Typography variant="overline">
            Date de fin de la période d&apos;extension :
            <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
              {formatInParis(
                addDays(new Date(cancelationDate), extensionDays),
                '⌛ EEEE dd MMMM yyyy HH:mm'
              )}
            </span>
          </Typography>
        ) : (
          <Alert severity="info" style={{ width: '100%' }}>
            L&apos;abonnement n&apos;est pas encore dans sa période
            d&apos;extension
          </Alert>
        )}
      </>
    );
  };

export default SubscriptionExtensionDays;
