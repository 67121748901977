import { FC } from 'react';
import { Link } from 'react-admin';
import Hourglass from '@mui/icons-material/HourglassFullTwoTone';

type Props = {
  label: string;
  redirectValue: string;
};

const SubscriptionsByStudentNameLink: FC<Props> =
  function subscriptionsByStudentNameLink({ label, redirectValue }) {
    const formattedFilters = JSON.stringify({
      q: redirectValue,
    });
    return (
      <Link
        to={{
          pathname: '/subscription',
          search: `displayedFilters=${formattedFilters}&filter=${formattedFilters}`,
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Hourglass titleAccess={label} />
        {label}
      </Link>
    );
  };
export default SubscriptionsByStudentNameLink;
