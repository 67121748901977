import { FC } from 'react';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import FaceIcon from '@mui/icons-material/Face';
import { Chip } from '@mui/material';

type Props = {
  isParent: boolean;
  isChild: boolean;
};

const StudentChip: FC<Props> = function studentChip({ isParent, isChild }) {
  const getOptions = () => {
    if (isParent || isChild) {
      return {
        isVisible: true,
        label: isChild ? 'Enfant' : 'Parent',
        icon: isChild ? <ChildCareIcon /> : <FaceIcon />,
      };
    }
    return {
      isVisible: false,
      label: 'Main account',
    };
  };
  const { isVisible, label, icon } = getOptions();

  return isVisible ? <Chip label={label} icon={icon} /> : null;
};

export default StudentChip;
